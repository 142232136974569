import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./components/Home";
import "./assets/stylesheets/App.css";
import Routes from "./components/routing/Routes";
import Header from "./components/Header";
import Footer from "./components/Footer";
import LinksCarousel from "./components/LinksCarousel";

import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";

const App = () => {
  return (
    <Router>
      {/* <Example/> */}
      <Header />

      <Switch>
        <Route exact path="/" component={Home} />

        <Routes component={Routes}></Routes>
      </Switch>
      <NotificationContainer />
      <LinksCarousel />
      <Footer />
    </Router>
  );
};

export default App;
