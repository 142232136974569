import React from "react";
import "../.../../../assets/stylesheets/academics/fee.css";
import AcademicCalendar from "../../pdfs/AcademicCalendar_EvenSemester2023-24_3rd4thYear.pdf";
import Highlight from "../Highlight";
import { contentContainer, headingContainer } from "../../elements";

const AcamedicCalendar = () => {
  return (
    <>
      <Highlight />
      {headingContainer({ heading: "Academic Calendar" })}
      {contentContainer({
        content: (
          <>
            <div className="academic_cal">
              <a href={AcademicCalendar} target="_blank" rel="noreferrer">
                Click here to download the Calendar
              </a>
            </div>
          </>
        ),
      })}
    </>
  );
};
export default AcamedicCalendar;
