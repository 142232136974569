import React from "react";
// import Carousel from "react-multi-carousel";
import AKT from "../images/SachinS.jpg";
// import Shipsy from '../images/Shipsy.jpg'
// import Krishna from '../images/Krishna.jpg'
// import Shubham from '../images/Shubham.jpg'
import Arunabh from "../images/Arunab.jpeg";
import Amrita from "../images/Amrita.jpg";
import Vaishnavi from "../images/Vaishnavi.jpg";
import Vanshika from "../images/Vanshika.jpg";
import Shweta from "../images/Shweta.jpg";
import Anushka from "../images/testimonials/Anushka_Jaiswal.jpg";
import Shubham from "../images/testimonials/Shubham_Shukla.jpg";
import Shraddha from "../images/testimonials/Shraddha_Singh.jpg";
import Ayush from "../images/testimonials/Ayush_Srivastava.jpg";
import Shreya from "../images/testimonials/Shreya_Singh.jpg";
import Prajjwal from "../images/testimonials/Prajjwal_Mishra.jpg";

import "../assets/stylesheets/HomePage/Testinomial.css";
import Carousel from "react-elastic-carousel";
import { headingContainer } from "../elements";

const breakPoints = [
  { width: 1, itemsToShow: 1, itemsToScroll: 1 },
  { width: 550, itemsToShow: 2, itemsToScroll: 1 },
  { width: 768, itemsToShow: 3, itemsToScroll: 1 },
  { width: 1198, itemsToShow: 3, itemsToScroll: 1 },
  { width: 1220, itemsToShow: 3, itemsToScroll: 1 },
];

const Testinomial = () => {
  const testi_data = [
    {
      src: Shraddha,
      name: "Shraddha Singh",
      branch_year: "CSE-2023",
      msg: "Experienced favourable atmosphere for intellectual and self development with serene environment , excellent resources and expanding infrastructure. It helped me to pave my path for a bright future ahead.",
    },
    {
      src: Ayush,
      name: "Ayush Srivastava",
      branch_year: "EL-2023",
      msg: "RECS holds a special place in my heart. The college offered a vibrant and supportive community, fostering a passion for learning and innovation. The memories of collaborating with brilliant minds and dedicated faculty continue to inspire me even after graduation.",
    },
    {
      src: Prajjwal,
      name: "Prajjwal Mishra",
      branch_year: "EL-2023",
      msg: "REC Sonbhadra gave me an opportunity with its peaceful natural environment  to learn technology through its best faculty members, laboratory and library.",
    },
    {
      src: Anushka,
      name: "Anushka Jaiswal",
      branch_year: "CSE-2022",
      msg: "I had a remarkable experience at REC Sonbhadra. I am thankful to the wonderful teachers who always encouraged and supported throughout the journey. Their guidance shaped me into the person I am today. I will forever cherish the memories and lessons shared by them.",
    },
    {
      src: Shreya,
      name: "Shreya Singh",
      branch_year: "EL-2022",
      msg: "REC sonbhadra gave wings to our thoughts and encouraged us to aspire and accomplish. Always thankful to the wonderful faculty and management.",
    },
    {
      src: Shubham,
      name: "Shubham Shukla",
      branch_year: "CSE-2022",
      msg: "I had an excellent learning experience. Faculty members drive students to bring out their best which in return serves as an excellent ignition in their career launch.",
    },
    {
      src: Vaishnavi,
      name: "Vaishnavi Sinha",
      branch_year: "EE-2021",
      msg: "It was great being at a place where  everyone was so  encouraging and motivating . The journey here was full of growth and learning.",
    },
    {
      src: Vanshika,
      name: "Vanshika Sonkar",
      branch_year: "CSE-2021",
      msg: "I proudly feel that I cherished every moments of my college. I got infinite love & lessons from my teachers. They made me do “Work Hard” for the way to success",
    },
    {
      src: Amrita,
      name: "Amrita Awasthi",
      branch_year: "CSE-2021",
      msg: "My graduation at RECS has been a very interesting and awesome journey. The college focuses on enriching young talents in students so they can excel in different fields.",
    },
    {
      src: AKT,
      name: "Sachin Kumar Singh",
      branch_year: "CSE-2020",
      msg: "I have had a fantastic time studying at REC Sonbhadra. The comfortable study environment, peer learning, and excellent resources help me equip myself with knowledge and skills.",
    },
    {
      src: Arunabh,
      name: "Arunabh Kishore",
      branch_year: "EE-2020",
      msg: "I had a great opportunity to learn and grow in this institution. Thanks to the learned and dedicated faculty members",
    },
    {
      src: Shweta,
      name: "Shweta Sharma",
      branch_year: "CSE-2019",
      msg: "A productive and innovative learning environment of this college has helped me build a career in advanced technologies like Artificial Intelligence and Machine Learning",
    },
  ];

  const TestiCard = ({ data }) => {
    return (
      <div
        style={{
          backgroundColor: "#1B2945",
          color: "#fafafa",
          border: "1px solid #ddd",
          borderRadius: "10px",
          padding: "15px 20px",
          margin: "10px",
          minHeight: "270px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "5px",
          }}
        >
          <div
            style={{
              width: "90px",
              height: "90px",
              overflow: "hidden",
              borderRadius: "50%",
            }}
          >
            <img
              alt='profile'
              src={data.src}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </div>
          <div
            style={{
              marginLeft: "15px",
              textAlign: "right",
              fontSize: "1.35rem",
            }}
          >
            <h6 style={{ margin: "5px 0" }}>{data.name}</h6>{" "}
            <h6 style={{ margin: "5px 0" }}>{data.branch_year}</h6>{" "}
          </div>
        </div>
        <p style={{ marginTop: "10px" }}>{data.msg}</p>
      </div>
    );
  };

  return (
    <div className='testi_wrap'>
      {headingContainer({ heading: "What Our Students Say" })}
      <div className='carousel-wrapper'>
        <Carousel breakPoints={breakPoints}>
          {testi_data.map((ele, idx) => {
            return <TestiCard data={ele} key={idx} />;
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default Testinomial;
