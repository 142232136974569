import React from "react";
import "../assets/stylesheets/Footer.css";
import Collegelogo from "../images/Collegelogo.png";
import { Link } from "react-router-dom";
import Facebook from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
// import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from "@material-ui/icons/YouTube";
import CopyrightIcon from "@material-ui/icons/Copyright";
import InsertLinkIcon from "@material-ui/icons/InsertLink";
const Footer = () => {
  return (
    <div className="footer_wrapper_div">
      <div className=" grid_container_footer">
        <div className="grid_item_footer" style={{ textAlign: "center" }}>
          <img src={Collegelogo} className="footer_logo" alt="error" />
          <div>
            Rajkiya Engineering College Churk, Sonbhadra 231206 <br></br>Tel:
            05444-252002
          </div>
        </div>

        <div className="grid_item_footer special_one">
          <div className="sd1">
            <span style={{ textDecoration: "underline" }}>
              <strong>Get in Touch</strong>
            </span>
            <ul>
              <Link to="#">
                <li style={{ display: "flex", alignItems: "center" }}>
                  {" "}
                  <InsertLinkIcon />
                  <span> Visiting REC-S</span>
                </li>
              </Link>
              <Link to="#">
                <li style={{ display: "flex", alignItems: "center" }}>
                  {" "}
                  <InsertLinkIcon />
                  <span> Careers</span>
                </li>
              </Link>
              <Link to="#">
                <li style={{ display: "flex", alignItems: "center" }}>
                  {" "}
                  <InsertLinkIcon />
                  <span> Research</span>
                </li>
              </Link>
              <Link to="#">
                <li style={{ display: "flex", alignItems: "center" }}>
                  {" "}
                  <InsertLinkIcon />
                  <span> Contact Us</span>
                </li>
              </Link>
            </ul>
          </div>
          <div className="sd2">
            <span style={{ textDecoration: "underline" }}>
              <strong>Quick Links</strong>
            </span>
            <ul>
              <Link to={{ pathname: "https://aktu.ac.in/" }} target="_blank">
                <li style={{ display: "flex", alignItems: "center" }}>
                  {" "}
                  <InsertLinkIcon />
                  <span> AKTU</span>
                </li>
              </Link>
              <Link
                to={{ pathname: "https://www.aicte-india.org/" }}
                target="_blank"
              >
                <li style={{ display: "flex", alignItems: "center" }}>
                  {" "}
                  <InsertLinkIcon />
                  <span> AICTE</span>
                </li>
              </Link>
              <Link to={{ pathname: "https://nptel.ac.in/" }} target="_blank">
                <li style={{ display: "flex", alignItems: "center" }}>
                  {" "}
                  <InsertLinkIcon />
                  <span> NPTEL</span>
                </li>
              </Link>
              <Link to={{ pathname: "https://prutor.ai/" }} target="_blank">
                <li style={{ display: "flex", alignItems: "center" }}>
                  {" "}
                  <InsertLinkIcon />
                  <span> PRUTOR</span>
                </li>
              </Link>
              <Link to={{ pathname: "https://swayam.gov.in/" }} target="_blank">
                <li style={{ display: "flex", alignItems: "center" }}>
                  {" "}
                  <InsertLinkIcon />
                  <span> SWAYAM</span>
                </li>
              </Link>
            </ul>
          </div>
        </div>

        <div
          className="grid_item_footer  final_contact"
          style={{ textAlign: "center" }}
        >
          <div style={{ textDecoration: "underline" }}>
            <strong>Connect With Us</strong>
          </div>
          <ul className="connect_with_us_list">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/recsonbhadra"
            >
              <li>
                <Facebook />
              </li>
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://twitter.com/recsonbhadra"
            >
              <li>
                <InstagramIcon />
              </li>
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://twitter.com/recsonbhadra"
            >
              <li>
                <TwitterIcon />
              </li>
            </a>
            {/* <a   target="_blank" rel="noreferrer" href="#"><li>< aedInIcon/></li></a> */}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.youtube.com/channel/UC-vPt2jQHRmqM4OwKGvN3FA"
            >
              <li>
                <YouTubeIcon />
              </li>
            </a>
          </ul>
          <div
            className="arya123"
            style={{ display: "flex", alignItems: "flex-start" }}
          >
            <CopyrightIcon className="cpr" />{" "}
            <span>Rajkiya Engineering College Sonbhadra</span>
          </div>
        </div>
      </div>
      <div
        className="proud"
        style={{
          color: "orange",
          fontSize: "1rem",
          textAlign: "center",
          paddingRight: "5%",
        }}
      >
        Proudly Created by CodeCube Team
      </div>
    </div>
  );
};

export default Footer;
