import { useParams } from "react-router-dom";
import DepartmentSideMenu from "./DepartmentSideMenu";
import AboutLayout from "../../layouts/AboutLayout";
import AchievementsLayout from "../../layouts/AchievementsLayout";
import ActivitiesLayout from "../../layouts/ActivitiesLayout";
import LabLayout from "../../layouts/LabLayout";
import PeopleLayout from "../../layouts/PeopleLayout";
import departmentData from "./departmentData";

import "../../assets/stylesheets/Departments.css";

export default function DepartmentPage() {
  const { deptCode } = useParams();
  const department = departmentData[deptCode];

  return (
    <DepartmentSideMenu
      about={<AboutLayout data={department.about} />}
      people={<PeopleLayout data={department.people} />}
      lab={<LabLayout data={department.lab} />}
      achievements={<AchievementsLayout data={department.achievements} />}
      activities={<ActivitiesLayout data={department.activities} />}
      deptCode={deptCode}
    />
  );
}
