import React from "react";
import "../.../../../assets/stylesheets/academics/syllabus.css";
// import AdminBuilding from '../../images/AdminBuilding.jpg'
import { Link } from "react-router-dom";
import Highlight from "../Highlight";
import { headingContainer } from "../../elements";

const Syllabus = () => {
  return (
    <>
      <Highlight />
      {headingContainer({ heading: "Discipline Wise Syllabus" })}
      <div id='syllabus_container' className='center'>
        <table className='syllabus_table'>
          <tbody>
            <tr>
              <th colSpan='4'>COMPUTER SCIENCE AND ENGINEERING</th>
            </tr>
            <tr>
              <td colSpan='2'>
                {/* <Link to="https://aktu.ac.in/pdf/syllabus/syllabus2021/B.Tech.%20Ist%20Year%20Common%20AICTE%20Model%20Curriculum%202020-21%2025082021.pdf ">1ST YEAR</Link> */}
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://aktu.ac.in/pdf/syllabus/syllabus2021/B.Tech.%20Ist%20Year%20Common%20AICTE%20Model%20Curriculum%202020-21%2025082021.pdf '
                >
                  1ST YEAR
                </a>
              </td>
              <td colSpan='2'>
                {/* <Link to="https://aktu.ac.in/pdf/syllabus/syllabus1920/B.Tech%202nd%20Year%20CSE%20&%20CSIT%20AICTE%20Model%20Curriculum%202019-20.pdf">2ND YEAR</Link> */}
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://aktu.ac.in/pdf/syllabus/syllabus1920/B.Tech%202nd%20Year%20CSE%20&%20CSIT%20AICTE%20Model%20Curriculum%202019-20.pdf'
                >
                  2ND YEAR
                </a>
              </td>
            </tr>
            <tr>
              <td colSpan='2'>
                {/* <Link to="https://aktu.ac.in/pdf/syllabus/syllabus2021/B.Tech_CSE%20and%20CS%20Syllabus%20of%203rd%20Year%209%20March%202021.pdf">3RD YEAR</Link> */}
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://aktu.ac.in/pdf/syllabus/syllabus2021/B.Tech_CSE%20and%20CS%20Syllabus%20of%203rd%20Year%209%20March%202021.pdf'
                >
                  3RD YEAR
                </a>
              </td>
              <td colSpan='2'>
                <Link to='#'>4TH YEAR</Link>
              </td>
            </tr>
          </tbody>
        </table>

        {/* ELECTRONICS */}
        <table className='syllabus_table'>
          <tbody>
            <tr>
              <th colSpan='4'>ELECTRONICS ENGINEERING</th>
            </tr>
            <tr>
              <td colSpan='2'>
                {/* <Link to={{pathname:"https://aktu.ac.in/pdf/syllabus/syllabus2021/B.Tech.%20Ist%20Year%20Common%20AICTE%20Model%20Curriculum%202020-21%2025082021.pdf "}}>1ST YEAR</Link> */}
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://aktu.ac.in/pdf/syllabus/syllabus2021/B.Tech.%20Ist%20Year%20Common%20AICTE%20Model%20Curriculum%202020-21%2025082021.pdf'
                >
                  1ST YEAR
                </a>
              </td>
              <td colSpan='2'>
                {/* <Link to="https://aktu.ac.in/pdf/syllabus/syllabus1920/B.Tech.%202nd%20Year%20EC&EI%20AICTE%20Model%20Curriculum%202019-20.pdf">2ND YEAR</Link> */}
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://aktu.ac.in/pdf/syllabus/syllabus1920/B.Tech.%202nd%20Year%20EC&EI%20AICTE%20Model%20Curriculum%202019-20.pdf'
                >
                  2ND YEAR
                </a>
              </td>
            </tr>
            <tr>
              <td colSpan='2'>
                {/* <Link to="https://aktu.ac.in/pdf/syllabus/syllabus2021/B.Tech%20EC%20Syllabus%204%20Aril%202021.pdf">3RD YEAR</Link> */}
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://aktu.ac.in/pdf/syllabus/syllabus2021/B.Tech%20EC%20Syllabus%204%20Aril%202021.pdf'
                >
                  3RD YEAR
                </a>
              </td>
              <td colSpan='2'>
                <Link to='#'>4TH YEAR</Link>
              </td>
            </tr>
          </tbody>
        </table>

        {/* ELECTRICAL */}
        <table className='syllabus_table'>
          <tbody>
            <tr>
              <th colSpan='4'>ELECTRICAL ENGINEERING</th>
            </tr>
            <tr>
              <td colSpan='2'>
                {/* <Link to="">1ST YEAR</Link> */}
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://aktu.ac.in/pdf/syllabus/syllabus2021/B.Tech.%20Ist%20Year%20Common%20AICTE%20Model%20Curriculum%202020-21%2025082021.pdf '
                >
                  1ST YEAR
                </a>
              </td>
              <td colSpan='2'>
                {/* <Link to="">2ND YEAR</Link> */}
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://aktu.ac.in/pdf/syllabus/syllabus1920/B.Tech.%202nd%20Year%20Electrical%20Engg%20AICTE%20Model%20Curriculum%202019-20.pdf'
                >
                  2ND YEAR
                </a>
              </td>
            </tr>
            <tr>
              <td colSpan='2'>
                {/* <Link to="">3RD YEAR</Link> */}
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://aktu.ac.in/pdf/syllabus/syllabus2021/25th%20August%20Revised%20Final%20B.Tech.%203rd%20Year%20Electrical%20Engineering%20AICTE%20Model%20Curriculum%202020-21.pdf'
                >
                  3RD YEAR
                </a>
              </td>
              <td colSpan='2'>
                <Link to='#'>4TH YEAR</Link>
                {/* <a target="_blank" rel="noreferrer" href="#">4TH YEAR</a> */}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};
export default Syllabus;
