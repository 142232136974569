import * as React from "react";
import { useState, useEffect } from "react";
import axios from "axios";

import authHeader from "../../../services/auth-header";

import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";

import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import CircularProgress from "@mui/material/CircularProgress";

import { API_URL } from "../../../config";

function srcset(image, width, height, rows = 1, cols = 1) {
  const myArray = image.split("image/upload");
  return {
    src: `${myArray[0]}image/upload/c_fit,h_${height * rows},w_${width * cols}${
      myArray[1]
    }`,
  };
}

export default function ManagePoster(props) {
  const [itemData, setItemData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    axios
      .get(`${API_URL}posters/get`, { headers: authHeader() })
      .then((res) => {
        setItemData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const Tags = ({ featured, pop, year }) => (
    <Stack direction="row" spacing={1}>
      {year && <Chip label={year} color="primary" size="small" />}
      {featured && <Chip label="Achievers" color="primary" size="small" />}
      {pop && <Chip label="PopUp" color="primary" size="small" />}
    </Stack>
  );
  const handlePosterDel = (id) => {
    setLoading(true);
    axios
      .delete(`${API_URL}posters/${id}`, { headers: authHeader() })
      .then((res) => {
        console.log(res.data);
        setLoading(false);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  return (
    <>
      <Dialog open={props.open} onClose={props.handleClose}>
        <DialogTitle>Gallery</DialogTitle>
        <DialogContent style={{ padding: "25px" }}>
          {itemData.length > 0 ? (
            <ImageList
              sx={{ width: 500, height: 450 }}
              cols={3}
              rowHeight={210}
            >
              {itemData.map((item) => (
                <ImageListItem key={item.poster.file_url}>
                  <img
                    {...srcset(item.poster.file_url, 250, 200)}
                    alt={item.year}
                    loading="lazy"
                  />
                  <ImageListItemBar
                    sx={{
                      background:
                        "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
                        "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
                    }}
                    subtitle={
                      <Tooltip
                        title={
                          <Tags
                            featured={item.featured}
                            pop={item.isPopUp}
                            year={item.year}
                          />
                        }
                      >
                        <IconButton sx={{ color: "white" }}>
                          <InfoOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    }
                    position="top"
                    actionIcon={
                      <>
                        <IconButton
                          sx={{ color: "white" }}
                          aria-label={`star ${item.year}`}
                          onClick={() => handlePosterDel(item._id)}
                          disabled={loading}
                        >
                          {loading ? (
                            <CircularProgress />
                          ) : (
                            <DeleteOutlinedIcon />
                          )}
                        </IconButton>
                      </>
                    }
                    actionPosition="left"
                  />
                </ImageListItem>
              ))}
            </ImageList>
          ) : (
            <div>No Posters Uploaded</div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
