import React from "react";
import "../../../assets/stylesheets/Thecollege/BoardOfGoverner/BOG.css";
import "../../../assets/stylesheets/Career.css";
import Sidemenuforabout from "../../../components/Sidemenuforabout.js";
import Highlight from "../../Highlight";
import BOGTable from "./BOGTable";

const TableData = {
  Member: [
    "Principal Secretary/Secretary Technical Education Department, Govt. of Uttar Pradesh",
    "The Director of the College",
    "Principal Secretary/Secretary, Finance Department, Govt. of Uttar Pradesh or his nominee, not below the rank of Joint Secretary",
    "Two persons to be nominated by the Chairman Board of Governors",
    "Finance/Accounts Officer of the College",
    "Registrar",
  ],
  Role: [
    "Chairman",
    "Member",
    "Member",
    "Member",
    "Member-Secretary",
    "Member-Secretary",
  ],
};

const FinanceCommittee = () => {
  return (
    <>
      <Highlight />
      <div>
        <div className="career_container blow563pxToResp resprespresp601to700px resPonsive-at-610-699px">
          <Sidemenuforabout />
          <div className="mginTop_bogs mgintop_bogs2 againmarginTop1234">
            <h2>Finance Committee</h2>
            <hr />
            <div>
              <p>
                The Finance Committee of the college consists of the following
                members:
              </p>
              <div
                style={{ overflow: "auto" }}
                className="AdMiNiStRaTiOn_TaBlE"
              >
                <BOGTable header1="Governing Body Member" header2="Position/Role" data={TableData} size={TableData.Member.length} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FinanceCommittee;
