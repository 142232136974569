import React from "react";
import Carousel from "react-elastic-carousel";

import "../../assets/stylesheets/Placements2021Carousel.css";
// import Highlight from "../Highlight";

const Placements2021Carousel = ({ items, posters }) => {
  const PlacementEntryCard = ({ imgSource }) => (
    <div className="placementCard">
      <img className="placementCardImage" src={imgSource} alt="image" />
    </div>
  );

  const breakPoints = [
    { width: 1, itemsToShow: 1, itemsToScroll: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 1 },
    { width: 768, itemsToShow: 3, itemsToScroll: 1 },
    { width: 1198, itemsToShow: items, itemsToScroll: 1 },
    { width: 1220, itemsToShow: items, itemsToScroll: 1 },
  ];

  return (
    <>
      {/* <Highlight/> */}
      <Carousel breakPoints={breakPoints}>
        {posters.map((student, idx) => (
          <PlacementEntryCard imgSource={student.poster.file_url} key={idx} />
        ))}
      </Carousel>
    </>
  );
};

export default Placements2021Carousel;
