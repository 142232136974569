import { headingContainer, downloadTab } from "../elements";
import Sidemenuforabout from "../components/Sidemenuforabout";
import Highlight from "../components/Highlight";

export default function DownloadsLayout({ data }) {
  const { heading, downloads } = data;
  return (
    <>
      <Highlight />
      <div className='downloadsGridContainer'>
        <Sidemenuforabout />
        <div className='downloadsContainer'>
          {headingContainer({ heading: heading, margin: true })}
          {downloads.map(({ title, link, file_url }) =>
            downloadTab({ title, link: link ? link : file_url })
          )}
        </div>
      </div>
    </>
  );
}
