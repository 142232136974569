import React from "react";
import "../../assets/stylesheets/facilities/Library.css";
import LibBanner from "../../images/LibBanner.JPG";
import Libicon from "../../images/Libicon.png";
import Highlight from "../Highlight";
// import Stats from "../../images/Stats.png"
// import Libnotice from "../../images/Libnotice.JPG";
export default function Library() {
  return (
    <>
      <Highlight />
      <div className="Contents">
        <div className="libraryBanner">
          <div>
            <div>
              <img className="lib-icon" src={Libicon} alt="" />
              <h2>Library</h2>
            </div>
            <div className="line"></div>
          </div>
          <img src={LibBanner} alt="" />
        </div>
        <div className="libraryMain">
          <div className="lib-part2">
            <div className="line"></div>
            <h3>Library</h3>
            <p>
              The library of institute is a space built for all user's needs,
              weather they are academic, social, recreational, personal, and
              relaxation. Two floors are wide open and and intended for group
              work, relaxation, and general studying. The noise level is usually
              at a dull roar.
              <br />
              <br />
              The idea of the library ascended at the time of Ashurbanipal and
              still we keep up the track along with. Library at our college REC
              Sonbhadra is no different. It has variety of books by reputed
              authors and they cover a vast number of topics like Embedded
              circuits, IOT Devices, Electrical Stuff and Computing Devices atc.
              It has enough facilities for convenient and attentive reading.
              There are over 1798 title of books and the total amount of books
              available sums up to 6993. Students can get books assigned for
              later reading as well. It's the best, the quietest site in the
              college and an enormous source of knowledge for them. The library
              staff fully supports complete access to books to Students and
              creates healthy disciplined atmosphere in library area.
            </p>
            <h4>Digital library:</h4>
            <p>
              For E-library access- Subscription to AKTU Nalanda-E-Consortium
              with 10258 E-book and 15151 E-journals among 9 publishers.
            </p>
          </div>
        </div>
        <div></div>
      </div>
    </>
  );
}
