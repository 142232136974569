import React, { useEffect } from "react";
import { connect } from "react-redux";
import getNewsandCircular from "../store/actions/getNoticeandNews";
import DownloadsLayout from "../layouts/DownloadsLayout";

import "../assets/stylesheets/Downloads.css";

function Noticeviewall(props) {
  useEffect(() => {
    props.getNewsandCircular();
  }, []);

  return (
    <DownloadsLayout
      data={{ heading: "News & Events", downloads: props.news }}
    />
  );
}
const mapStateToProps = (state) => ({
  news: state.Newsreducer.news_data,
});
const mapDispatchToProps = (dispatch) => {
  return {
    getNewsandCircular: () => dispatch(getNewsandCircular()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Noticeviewall);
