import React from "react";
import Highlight from "../Highlight";

import CellsBaseLayout from "../../layouts/CellsBaseLayout";

const WomenGrievance = () => {
  const data = [
    {
      tag: "main-heading",
      heading: "Women's Grievance Redressal Cell",
      index: 1,
    },
    {
      tag: "content",
      content:
        "Women's Grievance Redressal Cell of REC Sonbhadra has been constituted to ensure congenial and healthy working environment on the campus for girls and women employees where they can work, study and explore their potential to the fullest. The objective is to handle various grievances of the female students/ employees. The cell will take cognizance of any activity that contains a potential threat to the safety, security and dignity of women and recommend measures to overcome the threat.",
      index: 2,
    },
    {
      tag: "content",
      content: (
        <>
          <table className="trainingPlacementTable" style={{ minWidth: "50%" }}>
            <tr>
              <td colspan="2" className="studentGrievanceTableHeader">
                <b>Women's Grievance Redressal Cell</b>
              </td>
            </tr>
            <tr className="rowborder">
              <td>Chairperson</td>
              <td className="colborder">Dr. Bhavna Arora</td>
            </tr>
            <tr className="rowborder">
              <td>Member</td>
              <td className="colborder">Mrs. Kalpana Singh</td>
            </tr>
            <tr className="rowborder">
              <td>Member</td>
              <td className="colborder">Mrs. Anubha Gupta</td>
            </tr>
          </table>
        </>
      ),
      index: 3,
    },
    {
      tag: "sub-heading",
      heading: "Objectives",
      index: 4,
    },
    {
      tag: "content",
      content: {
        tag: "unordered-list",
        list: [
          "To uphold the dignity of women.",
          "To resolve issues pertaining to girls’/women’s sexual harassment.",
          "To heighten awareness and sensitivity to this important issue amongst all concerned.",
        ],
      },
      index: 5,
    },
    {
      tag: "sub-heading",
      heading: "Complaint of Sexual Harassment",
      index: 6,
    },
    {
      tag: "content",
      content:
        "Any aggrieved female student or employee may make, in writing a complaint of sexual harassment at workplace to the Internal Complaint Committee.",
      index: 7,
    },
  ];
  return (
    <>
      <Highlight />
      <CellsBaseLayout data={data} />
    </>
  );
};

export default WomenGrievance;
