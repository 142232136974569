import React from "react";
import "../../../assets/stylesheets/Thecollege/BoardOfGoverner/BOG.css";
import "../../../assets/stylesheets/Career.css";
import Sidemenuforabout from "../../../components/Sidemenuforabout.js";
import Highlight from "../../Highlight";
import BOGTable from "./BOGTable";

const TableData = {
  Member: [
    "The Director of the College",
    "Two senior faculty members to be nominated by the Board of Governors by rotation for one year",
    "Head of the indenting Department",
    "One member to be nominated by the Government",
    "Accounts Officer",
    "Registrar",
  ],
  Role: [
    "Chairman",
    "Member",
    "Member",
    "Member",
    "Member",
    "Member-Secretary",
  ],
};

const CentralPurchaseCommittee = () => {
  return (
    <>
      <Highlight />
      <div>
        <div className="career_container blow563pxToResp resprespresp601to700px resPonsive-at-610-699px">
          <Sidemenuforabout />
          <div className="mginTop_bogs mgintop_bogs2 againmarginTop1234">
            <h2>Central Purchase Committee</h2>
            <hr />
            <div>
              <p>
                The Central Purchase Committee of the college consists of the
                following members:
              </p>
              <div
                style={{ overflow: "auto" }}
                className="AdMiNiStRaTiOn_TaBlE"
              >
                <BOGTable header1="Governing Body Member" header2="Position/Role" data={TableData} size={TableData.Member.length} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CentralPurchaseCommittee;
