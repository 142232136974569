import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CallIcon from "@material-ui/icons/Call";
import "../.../../../assets/stylesheets/academics/admission.css";
import downArrow from "../../images/DownwardArrow.png";
import Highlight from "../Highlight";

import HostelAndMess from "../../pdfs/mirzapur/HostelAllotment&MessFeeDetails_RECMirzapurDirectorLetter.pdf";
import PhysicalReporting from "../../pdfs/mirzapur/Physical Reporting&RegistrationGuidelines_NewlyAdmitted Students_RECMirzapur.pdf";
import director_sir from "../../images/mirzapur/director_image.png";
import CV from "../../pdfs/mirzapur/KSV_BIODA Dec 21.pdf";
import {
  headingContainer,
  contentContainer,
  unorderedList,
} from "../../elements";
import { Fragment } from "react";

const admissionProcessFlowchart = () => {
  return (
    <div className="flowchart">
      <div className="flowchartElement">Appear in JEE (Main) / CUET</div>
      <img src={downArrow} style={{ width: "2.5vw" }} />
      <div className="flowchartElement">Obtain Valid Score</div>
      <img src={downArrow} style={{ width: "2.5vw" }} />
      <div className="flowchartElement">Participate in UPTAC Counselling</div>
      <img src={downArrow} style={{ width: "2.5vw" }} />
      <div className="flowchartElement">College Allotment</div>
      <img src={downArrow} style={{ width: "2.5vw" }} />
      <div className="flowchartElement">PI Reporting & Registration</div>
    </div>
  );
};

const AdmissionRECMirzapur = () => {
  const [windowDimension, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);

    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimension]);

  return (
    <>
      <Highlight />
      {headingContainer({ heading: "REC Mirzapur Admission Details 2023-24" })}
      <br></br>
      {contentContainer({
        content:
          "REC Mirzapur is a newly established Govt. Engineering College of Uttar Pradesh. Presently, the college is operating from the campus of Rajkiya Engineering College, Churk, Sonbhadra. ",
      })}

      {headingContainer({ heading: "Director's Message" })}
      {contentContainer({
        content: (
          <>
            <div>
              <h1>Director's Desk</h1>
              <hr />
              <img
                src={director_sir}
                alt="Directors's Image"
                className="back_img_director_profile"
              />
              <div className="department_quote department_quote_director">
                <div style={{ color: "black" }}>
                  <h3 style={{ marginBottom: "0" }}>Prof. K.S. Verma</h3>
                  <h3 style={{ marginBottom: "0" }}>
                    Director, Rajkiya Engineering College Mirzapur
                  </h3>
                </div>
              </div>
              <p>
                Dear Students,
                <br />
                With profound happiness I welcome you all to{" "}
                <strong>“Rajkiya Engineering College Mirzapur”.</strong>
                <br></br>
                “If you want to walk ‘fast’ walk alone, but if you want to walk
                ‘far’ walk together.”
                <br />
                The stage is now set for you all to take off!! All you need is
                to have a clear focus and specific goals. The institute is
                always with you. Remember, it’s never too late. The time is
                right for you to start drawing the roadmap of your life and
                career.
                <br /> “You can’t go back and change the beginning but you can
                start where you are and change the ending”.
                <br />
                My dear students, no matter what you do, where you go from here,
                I am sure you will always make this Institute proud. You must do
                all in your power to earn goodwill and good name for your alma
                mater.
                <br />
                Together we can make this initiative, a new endeavour in the
                field of technical education, a great success story for others
                to follow.
                <br />
                On behalf of the management, faculty and staff, I wish all my
                students all the best for all their present and future
                endeavours.
              </p>
              <p>Prof. K.S. Verma</p>
              <p>Director, REC Mirzapur</p>
              <p>Contact No: 9415610987</p>
              <p>Email: director@recmirza.ac.in ,ksv211@rediffmail.com</p>
            </div>

            {contentContainer({
              content: (
                <>
                  <div className="admissionDiv">
                    <div className="downloadsElement">
                      <div className="downloadsElementTitle">
                        Directors's CV
                      </div>
                      <div className="downloadsElementGet">
                        <a href={CV} target="_blank">
                          Download
                        </a>
                      </div>
                    </div>
                  </div>
                </>
              ),
            })}
          </>
        ),
      })}
      {headingContainer({ heading: "Admission Procedure" })}
      {contentContainer({
        content: unorderedList([
          <>
            Students are admitted to the <b>Undergraduate B.Tech. Programme</b>{" "}
            in the disciplines of <b>Computer Science and Engineering</b>,{" "}
            <b>Information Technology</b>, <b>Electronics Engineering</b> and{" "}
            <b>Electrical Engineering</b> through a valid score obtained in{" "}
            <b>Joint Entrance Examination, JEE (Mains)</b> conducted by National
            Testing Agency.
          </>,
          <>
            <h4 style={{ textAlign: "center" }}>
              Undergraduate B.Tech. Programme (4 years/8 semesters):{" "}
            </h4>
            <br />
            <table className="academic_table">
              <tbody>
                <tr className="th">
                  <td className="td">S.No.</td>

                  <td className="td">Discipline</td>

                  <td className="td">Intake</td>
                </tr>

                <tr>
                  <td className="td">1.</td>
                  <td className="td">Computer Science and Engineering</td>
                  <td className="td">60</td>
                </tr>
                <tr>
                  <td className="td">2.</td>
                  <td className="td">Information Technology</td>
                  <td className="td">60</td>
                </tr>
                <tr>
                  <td className="td">3.</td>
                  <td className="td">Electronics Engineering</td>
                  <td className="td">60</td>
                </tr>
                <tr>
                  <td className="td">4.</td>
                  <td className="td">Electrical Engineering</td>
                  <td className="td">60</td>
                </tr>
              </tbody>
            </table>
          </>,
          <>
            <br></br>
            <b>Uttar Pradesh Technical Admission Counselling (UPTAC)</b> for
            college allotment is organized by Dr. A.P.J. Abdul Kalam Technical
            University, Lucknow. (For details visit the official website of
            UPTAC of AKTU, Lucknow)
          </>,
        ]),
      })}
      {admissionProcessFlowchart()}
      <div className="admissionDiv">
        <div className="downloadsElement">
          <div className="downloadsElementTitle">
            Guidelines for Physical Reporting & Registration of Newly Admitted
            Students of REC Mirzapur at REC Sonbhadra campus
          </div>
          <div className="downloadsElementGet">
            <a href={PhysicalReporting} target="_blank">
              Download
            </a>
          </div>
        </div>
        <div className="downloadsElement">
          <div className="downloadsElementTitle">
            Hostel Allotment & Mess Fee Details for REC Mirzapur Students
          </div>
          <div className="downloadsElementGet">
            <a href={HostelAndMess} target="_blank">
              Download
            </a>
          </div>
        </div>
      </div>

      <div>
        {headingContainer({ heading: "Document Formats" })}
        <br></br>
        {contentContainer({
          content: unorderedList([
            <>
              Affidavit for Anti-Ragging by Student (Annexure I) -{" "}
              <a
                href="https://drive.google.com/file/d/1vqua00sIHpasUxL1gLfO3EzauSHW7F86/view?usp=drive_link"
                target="_blank"
                style={{ color: "blue" }}
              >
                Download
              </a>
            </>,
            <>
              Affidavit for Anti-Ragging by Parent/Guardian (Annexure II) –{" "}
              <a
                href="https://drive.google.com/file/d/12xV_I4J2uChB_S9WpqWh7i6LoCUft3BO/view?usp=drive_link"
                target="_blank"
                style={{ color: "blue" }}
              >
                Download
              </a>
            </>,
            <>
              Medical Certificate Format -{" "}
              <a
                href="https://drive.google.com/file/d/1ZiaFcFVnOz6xRX-ZiRZC2vWmeNiDzAVo/view?usp=sharing"
                target="_blank"
                style={{ color: "blue" }}
              >
                Download
              </a>
            </>,
          ]),
        })}
      </div>

      {headingContainer({ heading: "Admission Helpline Numbers" })}
      {contentContainer({
        content: (
          <p>
            <CallIcon /> 9889897041, 9554840555, 7906474265
          </p>
        ),
      })}
    </>
  );
};
export default AdmissionRECMirzapur;
