import React from "react";
import pic1 from "./../../images/galleryimages/pic1.JPG";
import "./../../assets/stylesheets/life@rec/studentactivitycenter.css";
import Highlight from "../Highlight";
import { headingContainer } from "../../elements";
const Studentactivitycenter = () => {
  return (
    <>
      <Highlight />
      {headingContainer({ heading: "Student Activity Center" })}

      <div className='SACBox'>
        <div className='sacImage'>
          <img src={pic1} style={{ width: "100%" }} alt='sky' />
        </div>
        <div className='sacTextSec'>
          <p>
            At Rajkiya Engineering College Sonbhadra we believe in overall
            development of our students. There are vast opportunities for
            pursuing personal hobbies like music, photography, painting etc and
            students are encouraged as well as supported to participate in
            events organized in this by various institutions. Under the umbrella
            of SGCA(Sports, Games & Cultural Activities), there are various
            councils and clubs in the college such as: Cultural council,
            Literary council, Sports Council, Robotics Club and SFAC(Shutterbugs
            and Fine Arts Club)..
          </p>
        </div>
      </div>
    </>
  );
};

export default Studentactivitycenter;
