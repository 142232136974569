import axios from "axios";
import authHeader from "./auth-header";

import { API_URL } from "../config";

class UserService {
  getAdminBoard() {
    return axios.get(API_URL + "users/test/admin", { headers: authHeader() });
  }
}

export default new UserService();
