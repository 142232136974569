import React from "react";
import "../assets/stylesheets/Header.css";
import logo from "../images/Collegelogo.png";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import placment from "../pdfs/PLACEMENT RECORD BATCH 2024.pdf";
import { useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import pdf from "../pdfs/clgbylaws.pdf";
import MenuIcon from "@material-ui/icons/Menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AccountBalanceTwoTone,
  Apartment,
  EmojiPeople,
  EmojiSymbols,
  Home,
  ImportContacts,
  Phone,
  School,
  SupervisorAccount,
} from "@material-ui/icons";
import { HistoryEdu } from "@mui/icons-material";

import AdmissionEnquiryButton from "./AdmissionEnquiryButton";

const rightOffsetMargin = { marginRight: "5px" };
const navbarData = [
  [
    {
      tab: "Home",
      icon: <Home style={rightOffsetMargin} />,
      path: "/",
    },
  ],
  [
    {
      tab: "The College",
      icon: <Apartment style={rightOffsetMargin} />,
      // path: "",
    },
    [
      {
        tab: "About Us",
        path: "/college/aboutus",
      },
      {
        tab: "Vision & Mission",
        path: "/college/vision",
      },
      {
        tab: "Director",
        path: "/college/directorprofile",
      },
      {
        tab: "Administration",
        submenu: [
          {
            tab: "Board of Governors",
            path: "/college/administration/BoardOfGovernersPage",
          },
          {
            tab: "Finance Committee",
            path: "/college/administration/FinanceCommittee",
          },
          {
            tab: "Building & Works Committee",
            path: "/college/administration/BuildingAndWorksCommittee",
          },
          {
            tab: "Administrative Committee",
            path: "/college/administration/AdministrativeCommittee",
          },
          {
            tab: "Central Purchase Committee",
            path: "/college/administration/CentralPurchaseCommittee",
          },
          {
            tab: "Academic Committee",
            path: "/college/administration/academic_committee",
          },
          {
            tab: "Minutes of Meetings",
            path: "/college/administration/MinutesOfMeetings",
          },
          {
            tab: "College By-laws",
            downloadable: pdf,
          },
        ],
      },
    ],
  ],
  [
    {
      tab: "Academics",
      icon: <School style={rightOffsetMargin} />,
      // path: "",
    },
    [
      {
        tab: "Academic Programs",
        path: "/academics/academic-program",
      },
      {
        tab: "Syllabi",
        path: "/academics/syllabi",
      },
      {
        tab: "Fee Structure",
        path: "/academics/fee-structure",
      },
      {
        tab: "Results",
        path: "/academics/results",
      },
      {
        tab: "Academic Calendar",
        path: "/academics/academic-calendar",
      },
    ],
  ],
  [
    {
      tab: "Departments",
      icon: <AccountBalanceTwoTone style={rightOffsetMargin} />,
      // path: "",
    },
    [
      {
        tab: "Applied Sciences & Humanities",
        path: "/departments/AS",
      },
      {
        tab: "Computer Science & Engineering",
        path: "/departments/CSE",
      },
      {
        tab: "Electrical Engineering",
        path: "/departments/EE",
      },
      {
        tab: "Electronics Engineering",
        path: "/departments/EL",
      },
      {
        tab: "Mining Engineering",
        path: "/departments/ME",
      },
    ],
  ],
  [
    {
      tab: "Admission",
      icon: <HistoryEdu style={rightOffsetMargin} />,
      path: "/academics/admission",
    },
  ],
  [
    {
      tab: "Cells @ RECs",
      icon: <ImportContacts style={rightOffsetMargin} />,
      // path: "",
    },
    [
      {
        tab: "Student Grievance Cell",
        path: "/important_cells/StudentGrievanceCell",
      },
      {
        tab: "Anti Ragging Cell",
        path: "/important_cells/AntiRaggingCell",
      },
      {
        tab: "Women Grievance & Redressal Cell",
        path: "/important_cells/WomenGrievance",
      },
      {
        tab: "Student Counselling & Career Guidance Cell",
        path: "/important_cells/StudentCounsellingCell",
      },
    ],
  ],
  [
    {
      tab: "Incubation & Innovation Center",
      icon: <SupervisorAccount style={rightOffsetMargin} />,
      path: "/important_cells/IncubationAndInnovationCenter",
    },
  ],
  [
    {
      tab: "Life @ RECs",
      icon: <EmojiSymbols style={rightOffsetMargin} />,
      // path: "",
    },
    [
      {
        tab: "College Gallery",
        path: "/life@rec/collegegallery",
      },
      {
        tab: "Student Activity Center",
        path: "/life@rec/studentactivitycenter",
      },
      {
        tab: "Extra-Curricular Activites",
        path: "/life@rec/Activities",
      },
      {
        tab: "Chapters, Clubs & Societies",
        submenu: [
          {
            tab: "NPTEL Local Chapter",
            path: "/",
          },
          {
            tab: "CodeChef RECS Chapter",
            path: "/",
          },
          {
            tab: "Electrical Engineer's Forum",
            path: "/",
          },
          {
            tab: "Society of Exploration and Innovation in Electronics",
            path: "/",
          },
        ],
      },
      {
        tab: "Useful Links",
        path: "/life@rec/usefulLinks",
      },
    ],
  ],
  [
    {
      tab: "Facilities",
      icon: <EmojiPeople style={rightOffsetMargin} />,
      // path: "",
    },
    [
      {
        tab: "Auditorium",
        path: "/facilities/Auditorium",
      },
      {
        tab: "Computer Centre",
        path: "/facilities/ComputerCentre",
      },
      {
        tab: "Library",
        path: "/facilities/library",
      },
      {
        tab: "Hostels",
        path: "/facility/hostel",
      },
      {
        tab: "Canteen & Mess",
        path: "/facility/Canteen",
      },
      {
        tab: "24 X 7 Internet",
        path: "/facilities/LANinternet",
      },
      {
        tab: "Workshop",
        path: "/facilities/Workshop",
      },
    ],
  ],
  [
    {
      tab: "Contact",
      icon: <Phone style={rightOffsetMargin} />,
      path: "/contactus",
    },
  ],
  [
    {
      tab: "Faculty Login",
      icon: <SupervisorAccount style={rightOffsetMargin} />,
      path: "/facultylogin",
    },
  ],
];

function TemporaryDrawer() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  /*?  rotation logic */
  let RotationONClass_Offset = {
    transform: "rotateZ(90deg)",
  };
  let RotationOFFClass_Offset = {
    transform: "rotateZ(0deg)",
  };

  const [Rotation, setRotation] = useState(false);
  let VaRStYlEnAmE = Rotation
    ? RotationONClass_Offset
    : RotationOFFClass_Offset;
  const OnStateOffsetmenu = () => setRotation(true);
  const OFFStateOffsetmenu = () => setRotation(false);

  return (
    <div className="drawer_offset">
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button
            onClick={toggleDrawer(anchor, true)}
            style={{ right: "-80%" }}
          >
            <MenuIcon />
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {/* <center></center> */}

            <div className="offcanvas_container">
              <center>
                <hr />
                <Link to="/">
                  {" "}
                  <img
                    className="logo"
                    src={logo}
                    style={{ width: "30%", margin: "10%" }}
                    alt="logo"
                  />
                </Link>
                <hr />{" "}
              </center>
              {navbarData.map((navbarItem, index) =>
                navbarItem.length === 1 ? (
                  <Link
                    style={{ color: "black" }}
                    to={navbarItem[0].path}
                    key={`${index}+${navbarItem[0].tab}`}
                  >
                    <li className="navbar_items_offcanvas">
                      <span>
                        {navbarItem[0].icon}
                        <span>{navbarItem[0].tab}</span>
                      </span>
                    </li>
                  </Link>
                ) : (
                  <li
                    className="navbar_items_offcanvas"
                    key={`${index}+${navbarItem[0].tab}`}
                  >
                    <span>
                      {navbarItem[0].icon}
                      <span>{navbarItem[0].tab}</span>
                    </span>

                    <ul className="dropdown_menu_offcanvas">
                      {navbarItem[1].map((navbarSubItem, subindex) =>
                        navbarSubItem.path ? (
                          <Link
                            to={navbarSubItem.path}
                            key={`${subindex}+${navbarSubItem.tab}`}
                          >
                            <li className="dropdownitem">
                              {navbarSubItem.tab}
                            </li>
                          </Link>
                        ) : (
                          <li
                            className="dropdownitem dropdownitem_submenu"
                            onClick={OnStateOffsetmenu}
                            onMouseHover={OnStateOffsetmenu}
                            onMouseLeave={OFFStateOffsetmenu}
                            key={`${subindex}+${navbarSubItem.tab}`}
                          >
                            {navbarSubItem.tab}{" "}
                            <span>
                              <FontAwesomeIcon
                                icon={faCaretRight}
                                style={VaRStYlEnAmE}
                              />
                            </span>
                            <ul id="dropdown_sub_menu_offcanvas">
                              {navbarSubItem.submenu.map(
                                (navbarSubSubItem, subsubindex) =>
                                  navbarSubSubItem.downloadable ? (
                                    <a
                                      href={navbarSubSubItem.downloadable}
                                      target="_blank"
                                      rel="noreferrer"
                                      key={`${subsubindex}+${navbarSubSubItem.tab}`}
                                    >
                                      <li className="dropdownitem">
                                        {navbarSubSubItem.tab}
                                      </li>
                                    </a>
                                  ) : (
                                    <Link
                                      to={navbarSubSubItem.path}
                                      key={`${subsubindex}+${navbarSubSubItem.tab}`}
                                    >
                                      <li className="dropdownitem">
                                        {navbarSubSubItem.tab}
                                      </li>
                                    </Link>
                                  )
                              )}
                            </ul>
                          </li>
                        )
                      )}
                    </ul>
                  </li>
                )
              )}
            </div>
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}

const NavBar = () => {
  return (
    <div className=" navbar_div ">
      {navbarData.map((navbarItem, index) =>
        navbarItem.length === 1 ? (
          <li className="navbar_items" key={`${index}+${navbarItem[0].tab}`}>
            <Link style={{ color: "black" }} to={navbarItem[0].path}>
              <span>
                {navbarItem[0].icon}
                <span>{navbarItem[0].tab}</span>
              </span>
            </Link>
          </li>
        ) : (
          <li className="navbar_items" key={`${index}+${navbarItem[0].tab}`}>
            <span>
              {navbarItem[0].icon}
              <span>{navbarItem[0].tab}</span>
            </span>

            <ul className="dropdown_menu">
              {navbarItem[1].map((navbarSubItem, subindex) =>
                navbarSubItem.path ? (
                  <Link
                    to={navbarSubItem.path}
                    key={`${subindex}+${navbarSubItem.tab}`}
                  >
                    <li className="dropdownitem">{navbarSubItem.tab}</li>
                  </Link>
                ) : (
                  <li
                    className="dropdownitem dropdownitem_submenu"
                    key={`${subindex}+${navbarSubItem.tab}`}
                  >
                    {navbarSubItem.tab}
                    <span>
                      <FontAwesomeIcon icon={faCaretRight} />
                    </span>
                    <ul id="dropdown_sub_menu">
                      {navbarSubItem.submenu.map(
                        (navbarSubSubItem, subsubindex) =>
                          navbarSubSubItem.downloadable ? (
                            <a
                              href={navbarSubSubItem.downloadable}
                              target="_blank"
                              rel="noreferrer"
                              key={`${subsubindex}+${navbarSubSubItem.tab}`}
                            >
                              <li className="dropdownitem">
                                {navbarSubSubItem.tab}
                              </li>
                            </a>
                          ) : (
                            <Link
                              to={navbarSubSubItem.path}
                              key={`${subsubindex}+${navbarSubSubItem.tab}`}
                            >
                              <li className="dropdownitem">
                                {navbarSubSubItem.tab}
                              </li>
                            </Link>
                          )
                      )}
                    </ul>
                  </li>
                )
              )}
            </ul>
          </li>
        )
      )}
    </div>
  );
};

const Header = () => {
  return (
    <>
      <TemporaryDrawer />
      <div className="header_wrapper_div" id="headerWrapper">
        <div className="headerWrapperFlexContainer">
          <div className=" logo_plus_name_flex">
            <Link to="/" className="logo_size">
              <img className="logo" src={logo} alt="sry" />
            </Link>
            <div className="college_name" style={{ marginLeft: "1%" }}>
              <span style={{ display: "block" }}>
                राजकीय इंजीनियरिंग कॉलेज सोनभद्र
              </span>
              <span> Rajkiya Engineering College Sonbhadra</span>
              {/* <br/> */}
              <div className="Aicte">
                An AICTE approved Government Engineering College affiliated to
                AKTU Lucknow (College Code 841)
              </div>
            </div>
          </div>
          <div className="glow">
            <a href={placment} target="_blank">
              Placement Record 2023-2024
            </a>
            <br />
            <a
              href="http://formbuilder.ccavenue.com/live/rec-sonbhadra"
              target="_blank"
            >
              Online Fee Submission
            </a>
            <br />

            <AdmissionEnquiryButton />
          </div>
          {/* <Link to='/pdfdownloads/PlacementRecordBatch2020-21.pdf' target="_blank" download>Download</Link> */}
        </div>
        <NavBar />
      </div>
    </>
  );
};

export default Header;
