import React from "react";
import { Link } from "@material-ui/core";
// import hightlights_link from "../pdfs/Startup Showcase Meet 2024 (SSM-2024).pdf";
import reportingGuideline from "../pdfs/PhysicalReporting&RegistrationGuidelinesNewlyAdmittedStudents_2024-25.pdf";
const hightlights_link = "https://uptac.admissions.nic.in";

const Highlight = () => {
  return (
    <div className="highlight_wrapper">
      <div className="highlight_text">Highlights</div>
      {/* Changed marquee to div */}
      <div className="marquee_container">
        {/* added extra div */}
        <div className="marquee marquee_content">
          <a href={hightlights_link} target="_blank">
            Uttar Pradesh Technical Admission Counselling 2024
          </a>
          <span style={{ padding: "0 1rem", color: "blue" }}>||</span>
          <a href={reportingGuideline} target="_blank" rel="noreferrer">
            Physical Reporting & Registration Guidelines for Newly Admitted
            Students (Academic Session 2024-25)
          </a>
        </div>
      </div>
    </div>
  );
};

export default Highlight;
