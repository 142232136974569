import {createStore,applyMiddleware} from 'redux'
import React from 'react'
import { Store } from '@material-ui/icons'
import thunk from 'redux-thunk'
import rootReducer from './reducers'


export function configureStore(){
    const store=createStore(rootReducer,applyMiddleware(thunk))
    return store;
}