import React from "react";
import Carousel from "react-elastic-carousel";
import "../assets/stylesheets/LinksCarousel.css";
import AKTULogo from "../images/LinksCarosuel/AKTU.png";
import AICTELogo from "../images/LinksCarosuel/AICTE.jpg";
import NPTELLogo from "../images/LinksCarosuel/NPTEL.png";
import PRUTORLogo from "../images/LinksCarosuel/PRUTOR.jpg";
import SWAYAMLogo from "../images/LinksCarosuel/SWAYAM.png";

const breakPoints = [
  { width: 1, itemsToShow: 1, itemsToScroll: 1 },
  { width: 450, itemsToShow: 2, itemsToScroll: 1 },
  { width: 600, itemsToShow: 3, itemsToScroll: 1 },
  { width: 800, itemsToShow: 4, itemsToScroll: 1 },
  { width: 1300, itemsToShow: 5, itemsToScroll: 0 },
];
// breakpoints to limit no of items  displayed at a time depending upon the screen size

const Links = [
  {
    link: "https://aktu.ac.in/",
    src: AKTULogo,
    alt: "AKTU",
  },
  {
    link: "https://www.aicte-india.org/",
    src: AICTELogo,
    alt: "AICTE",
  },
  {
    link: "https://nptel.ac.in/",
    src: NPTELLogo,
    alt: "NPTEL",
  },
  {
    link: "https://prutor.ai/college/aktu841/",
    src: PRUTORLogo,
    alt: "PRUTOR",
  },
  {
    link: "https://swayam.gov.in/",
    src: SWAYAMLogo,
    alt: "SWAYAM",
  },
];

const LinkCard = ({ link, src, alt }) => (
  <div key={alt}>
    <a href={link} target="_blank">
      <img src={src} alt={alt} className="LinkImg" />
    </a>
  </div>
);

const CarouselImages = Links.map((Links) => LinkCard(Links));

const LinksCarousel = () => {
  return (
    <div className="link-carousel">
      <Carousel
        breakPoints={breakPoints}
        // enableAutoPlay
        // autoPlaySpeed={3000}
        // showArrows={true}
      >
        {CarouselImages}
      </Carousel>
    </div>
  );
};

export default LinksCarousel;
