import { headingContainer, contentContainer, unorderedList } from "../elements";

export default function LabLayout({ data }) {
  const { labImage, beginingText, labList } = data;
  return (
    <>
      {headingContainer({ heading: "Laboratories", margin: true })}
      {contentContainer({
        content: labImage ? (
          <img src={labImage} alt='' className='labImage' />
        ) : (
          <></>
        ),
      })}
      {contentContainer({ content: beginingText })}
      {contentContainer({
        content: labList ? unorderedList(labList) : <>Coming Soon....</>,
      })}
    </>
  );
}
