import DownloadsLayout from "../layouts/DownloadsLayout";

import "../assets/stylesheets/Downloads.css";

export default function Downloads() {
  const downloads = [
    {
      title: "AICTE Extension of Approval 2024-2025",
      link: "/pdfdownloads/EOA Report 2024-25.PDF",
    },
    {
      title: "AICTE Extension of Approval 2023-2024",
      link: "/pdfdownloads/EOA Report 2023-24.PDF",
    },
    {
      title: "Mess Caution Money Refund Form",
      link: "/pdfdownloads/MessCautionMoneyRefundForm_24July2023.pdf",
    },
    {
      title: "College Fees Cash Challan",
      link: "/pdfdownloads/Fee_challans/CollegeFeesChallan_RECSonbhadra.pdf",
    },
    {
      title: "Boys Hostel-1 Mess Fee Cash Challan",
      link: "/pdfdownloads/Fee_challans/MessFeeChallan_BoysHostel-1.pdf",
    },
    {
      title: "Boys Hostel-2 Mess Fee Cash Challan",
      link: "/pdfdownloads/Fee_challans/MessFeeChallan_BoysHostel-2.pdf",
    },
    {
      title: "Boys Hostel-3 Mess Fee Cash Challan",
      link: "/pdfdownloads/Fee_challans/MessFeeChallan_BoysHostel-3.pdf",
    },
    {
      title: "Girls Hostel Mess Fee Cash Challan",
      link: "/pdfdownloads/Fee_challans/MessFeeChallan_GirlsHostel.pdf",
    },
    {
      title:
        "Other Fees (Facility & Maintenance Charges, Mess Security) Cash Challan",
      link: "/pdfdownloads/Fee_challans/OtherFeesChallan(Facility&MaintenanceFee,MessSecurity)_DSWRECSonbhadra.pdf",
    },
    {
      title: "AICTE Extension of Approval 2022-2023",
      link: "/pdfdownloads/EOA-Report 22-23.pdf",
    },
    {
      title: "AICTE Extension of Approval 2020-2021",
      link: "/pdfdownloads/ExtensionOfApproval_AICTE.pdf",
    },
    {
      title: "AICTE Letter of Approval 2019-20",
      link: "/pdfdownloads/AICTE_LetterOfApproval.pdf",
    },
    {
      title:
        "Call for Applications: Startups at REC Sonbhadra Incubation Centre",
      link: "/pdfdownloads/CallforApplications_StartupsAtRECSonbhadraIncubationCentre.pdf",
    },
    {
      title: "No Dues Form for Student",
      link: "/pdfdownloads/03115download_03192021.pdf",
    },
    {
      title: "Caution Money Request Form",
      link: "/pdfdownloads/30413download_03192021.pdf",
    },
    {
      title: "INSPIRING MINDS DRIVING PASSION (Placement Brochure 2019-20)",
      link: "/pdfdownloads/55103download_04222020.pdf",
    },
    {
      title: "No Dues Form for Faculty & Staff",
      link: "/pdfdownloads/52424download_07052019.pdf",
    },
    {
      title: "Employee Leave Application Format",
      link: "/pdfdownloads/44245download_02122019.pdf",
    },
    {
      title: "Hostel Leave Form",
      link: "/pdfdownloads/50330download_02122019.pdf",
    },
    {
      title: "TA Format",
      link: "/pdfdownloads/33554download_12092018.pdf",
    },
    {
      title: "Claim Form for Remuneration/Honorarium",
      link: "/pdfdownloads/RemunerationForm.pdf",
    },
  ];

  return <DownloadsLayout data={{ heading: "Downloads", downloads }} />;
}
