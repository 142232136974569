import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CallIcon from "@material-ui/icons/Call";
import "../.../../../assets/stylesheets/academics/admission.css";
import downArrow from "../../images/DownwardArrow.png";
import Highlight from "../Highlight";
import Rank from "../../images/categorywise-rank.jpg";

import PhysicalReportingAndAdmissionGuildelines from "../../pdfs/PhysicalReporting&RegistrationGuidelinesNewlyAdmittedStudents_2024-25.pdf";
import {
  headingContainer,
  contentContainer,
  unorderedList,
} from "../../elements";
import { Fragment } from "react";
import { Category } from "@material-ui/icons";

const ComputerScienceCuttoffData = [
  {
    Name: "Computer Science and Engineering",
  },
  // {
  //   Year: "2019-2020 (UPSEE)",
  //   Intake: [{ Regular: 67 }, { Lateral: 6 }],
  //   Admission: [{ Regular: 67 }, { Lateral: 6 }, { Total: 73 }],
  //   Rank: [
  //     {
  //       Regular: {
  //         Open: 5094,
  //         Close: 35304,
  //       },
  //     },
  //     {
  //       Lateral: {
  //         Open: 264,
  //         Close: 2072,
  //       },
  //     },
  //   ],
  // },
  // {
  //   Year: "2020-2021 (UPSEE)",
  //   Intake: [{ Regular: 67 }, { Lateral: 6 }],
  //   Admission: [{ Regular: 67 }, { Lateral: 6 }, { Total: 73 }],
  //   Rank: [
  //     {
  //       Regular: {
  //         Open: 5776,
  //         Close: 62291,
  //       },
  //     },
  //     {
  //       Lateral: {
  //         Open: 274,
  //         Close: 1626,
  //       },
  //     },
  //   ],
  // },
  // {
  //   Year: "2021-2022 (JEE & CUET)",
  //   Intake: [{ Regular: 67 }, { Lateral: 6 }],
  //   Admission: [{ Regular: 67 }, { Lateral: 6 }, { Total: 73 }],
  //   Rank: [
  //     {
  //       Regular: {
  //         Open: 72042,
  //         Close: 102342,
  //       },
  //     },
  //     {
  //       Lateral: {
  //         Open: 972,
  //         Close: 2023,
  //       },
  //     },
  //   ],
  // },
  {
    Year: "2022-2023 (JEE & CUET)",
    Intake: [{ Regular: 67 }, { Lateral: 6 }],
    Admission: [{ Regular: 67 }, { Lateral: 6 }, { Total: 73 }],
    Rank: [
      {
        Regular: {
          Open: 59140,
          Close: 405366,
        },
      },
      {
        Lateral: {
          Open: 137,
          Close: 5446,
        },
      },
    ],
  },
  {
    Year: "2023-2024 (JEE & CUET)",
    Intake: [{ Regular: 67 }, { Lateral: 6 }],
    Admission: [{ Regular: 67 }, { Lateral: 6 }, { Total: 73 }],
    Rank: [
      {
        Regular: {
          Open: 72379,
          Close: 627585,
        },
      },
      {
        Lateral: {
          Open: "-",
          Close: "-",
        },
      },
    ],
  },
];

const ElectricalCuttoffData = [
  {
    Name: "Electrical Engineering",
  },
  // {
  //   Year: "2019-2020 (UPSEE)",
  //   Intake: [{ Regular: 67 }, { Lateral: 6 }],
  //   Admission: [{ Regular: 61 }, { Lateral: 6 }, { Total: 67 }],
  //   Rank: [
  //     {
  //       Regular: {
  //         Open: 6611,
  //         Close: 80970,
  //       },
  //     },
  //     {
  //       Lateral: {
  //         Open: 48,
  //         Close: 3007,
  //       },
  //     },
  //   ],
  // },
  // {
  //   Year: "2020-2021 (UPSEE)",
  //   Intake: [{ Regular: 67 }, { Lateral: 6 }],
  //   Admission: [{ Regular: 63 }, { Lateral: 6 }, { Total: 69 }],
  //   Rank: [
  //     {
  //       Regular: {
  //         Open: 6000,
  //         Close: 80904,
  //       },
  //     },
  //     {
  //       Lateral: {
  //         Open: 133,
  //         Close: 445,
  //       },
  //     },
  //   ],
  // },
  // {
  //   Year: "2021-2022 (JEE & CUET)",
  //   Intake: [{ Regular: 67 }, { Lateral: 6 }],
  //   Admission: [{ Regular: 56 }, { Lateral: 4 }, { Total: 60 }],
  //   Rank: [
  //     {
  //       Regular: {
  //         Open: 54488,
  //         Close: 262610,
  //       },
  //     },
  //     {
  //       Lateral: {
  //         Open: 332,
  //         Close: 2123,
  //       },
  //     },
  //   ],
  // },
  {
    Year: "2022-2023 (JEE & CUET)",
    Intake: [{ Regular: 67 }, { Lateral: 6 }],
    Admission: [{ Regular: 48 }, { Lateral: 6 }, { Total: 54 }],
    Rank: [
      {
        Regular: {
          Open: 100613,
          Close: 761811,
        },
      },
      {
        Lateral: {
          Open: 317,
          Close: 8790,
        },
      },
    ],
  },
  {
    Year: "2023-2024 (JEE & CUET)",
    Intake: [{ Regular: 67 }, { Lateral: 6 }],
    Admission: [{ Regular: 48 }, { Lateral: 6 }, { Total: 54 }],
    Rank: [
      {
        Regular: {
          Open: 248560,
          Close: 792003,
        },
      },
      {
        Lateral: {
          Open: "-",
          Close: "-",
        },
      },
    ],
  },
];

const ElectronicsCuttoffData = [
  {
    Name: "Electronics Engineering",
  },
  // {
  //   Year: "2019-2020 (UPSEE)",
  //   Intake: [{ Regular: 67 }, { Lateral: 6 }],
  //   Admission: [{ Regular: 45 }, { Lateral: 6 }, { Total: 51 }],
  //   Rank: [
  //     {
  //       Regular: {
  //         Open: 7677,
  //         Close: 75607,
  //       },
  //     },
  //     {
  //       Lateral: {
  //         Open: 408,
  //         Close: 1889,
  //       },
  //     },
  //   ],
  // },
  // {
  //   Year: "2020-2021 (UPSEE)",
  //   Intake: [{ Regular: 67 }, { Lateral: 6 }],
  //   Admission: [{ Regular: 64 }, { Lateral: 6 }, { Total: 70 }],
  //   Rank: [
  //     {
  //       Regular: {
  //         Open: 5344,
  //         Close: 64377,
  //       },
  //     },
  //     {
  //       Lateral: {
  //         Open: 279,
  //         Close: 434,
  //       },
  //     },
  //   ],
  // },
  // {
  //   Year: "2021-2022 (JEE & CUET)",
  //   Intake: [{ Regular: 67 }, { Lateral: 6 }],
  //   Admission: [{ Regular: 44 }, { Lateral: 3 }, { Total: 47 }],
  //   Rank: [
  //     {
  //       Regular: {
  //         Open: 134247,
  //         Close: 221944,
  //       },
  //     },
  //     {
  //       Lateral: {
  //         Open: 556,
  //         Close: 1718,
  //       },
  //     },
  //   ],
  // },
  {
    Year: "2022-2023 (JEE & CUET)",
    Intake: [{ Regular: 67 }, { Lateral: 6 }],
    Admission: [{ Regular: 64 }, { Lateral: 4 }, { Total: 68 }],
    Rank: [
      {
        Regular: {
          Open: 126519,
          Close: 888202,
        },
      },
      {
        Lateral: {
          Open: 240,
          Close: 908,
        },
      },
    ],
  },
  {
    Year: "2023-2024 (JEE & CUET)",
    Intake: [{ Regular: 67 }, { Lateral: 6 }],
    Admission: [{ Regular: 64 }, { Lateral: 4 }, { Total: 68 }],
    Rank: [
      {
        Regular: {
          Open: 168190,
          Close: 1064967,
        },
      },
      {
        Lateral: {
          Open: "-",
          Close: "-",
        },
      },
    ],
  },
];

const MininingCuttoffData = [
  {
    Name: "Mining Engineering",
  },
  {
    Year: "2022-2023 (JEE & CUET)",
    Intake: [{ Regular: "NA" }, { Lateral: "NA" }],
    Admission: [{ Regular: "NA" }, { Lateral: "NA" }, { Total: "NA" }],
    Rank: [
      {
        Regular: {
          Open: "NA",
          Close: "NA",
        },
      },
      {
        Lateral: {
          Open: "NA",
          Close: "NA",
        },
      },
    ],
  },
  {
    Year: "2023-2024 (JEE & CUET)",
    Intake: [{ Regular: 30 }, { Lateral: 0 }],
    Admission: [{ Regular: 30 }, { Lateral: 0 }, { Total: 30 }],
    Rank: [
      {
        Regular: {
          Open: 697254,
          Close: 1099632,
        },
      },
      {
        Lateral: {
          Open: "NA",
          Close: "NA",
        },
      },
    ],
  },
];

const admissionProcessFlowchart = () => {
  return (
    <div className="flowchart">
      <div className="flowchartElement">Appear in JEE (Main) / CUET</div>
      <img src={downArrow} style={{ width: "2.5vw" }} />
      <div className="flowchartElement">Obtain Valid Score</div>
      <img src={downArrow} style={{ width: "2.5vw" }} />
      <div className="flowchartElement">Participate in UPTAC Counselling</div>
      <img src={downArrow} style={{ width: "2.5vw" }} />
      <div className="flowchartElement">College Allotment</div>
      <img src={downArrow} style={{ width: "2.5vw" }} />
      <div className="flowchartElement">PI Reporting & Registration</div>
    </div>
  );
};

const cutoffData = [
  ComputerScienceCuttoffData,
  ElectricalCuttoffData,
  ElectronicsCuttoffData,
  MininingCuttoffData,
];

const cutoffDataYears =
  Math.max(
    ComputerScienceCuttoffData.length,
    ElectricalCuttoffData.length,
    ElectronicsCuttoffData.length,
    MininingCuttoffData.length
  ) - 1;
const displayTableInRow = () => {
  return (
    <div className="RanksTable">
      {cutoffData.map((data, index) => {
        return (
          <Fragment key={`${index}+ranksTable`}>
            <div className="TableHeader">{data[0].Name}</div>
            <table>
              <tbody>
                <tr>
                  {[...Array(cutoffDataYears)].map((e, i) => (
                    <th colSpan="6" key={`${i}+year+${index}+${e}`}>
                      {data[i + 1].Year}
                    </th>
                  ))}
                </tr>
                <tr>
                  {[...Array(cutoffDataYears)].map((e, i) => (
                    <Fragment key={`${i}+headers+${index}+${e}`}>
                      <td colSpan="2">Course Intake</td>
                      <td colSpan="2">Admission</td>
                      <td colSpan="2">Rank</td>
                    </Fragment>
                  ))}
                </tr>
                <tr>
                  {[...Array(cutoffDataYears)].map((e, i) => (
                    <Fragment key={`${i}+subheaders+${index}+${e}`}>
                      <td colSpan="2"></td>
                      <td colSpan="2"></td>
                      <td colSpan="1">Opening</td>
                      <td colSpan="1">Closing</td>
                    </Fragment>
                  ))}
                </tr>
                <tr>
                  {[...Array(cutoffDataYears)].map((e, i) => (
                    <Fragment key={`${i}+row1+${index}+${e}`}>
                      <td colSpan="1">BTech Regular (JEE)</td>
                      <td colSpan="1">{data[i + 1].Intake[0].Regular}</td>
                      <td colSpan="2">{data[i + 1].Admission[0].Regular}</td>
                      <td colSpan="1">{data[i + 1].Rank[0].Regular.Open}</td>
                      <td colSpan="1">{data[i + 1].Rank[0].Regular.Close}</td>
                    </Fragment>
                  ))}
                </tr>
                <tr>
                  {[...Array(cutoffDataYears)].map((e, i) => (
                    <Fragment key={`${i}+row2+${index}+${e}`}>
                      <td colSpan="1">BTech Lateral-entry (CUET)</td>
                      <td colSpan="1">{data[i + 1].Intake[1].Lateral}</td>
                      <td colSpan="2">{data[i + 1].Admission[1].Lateral}</td>
                      <td colSpan="1">{data[i + 1].Rank[1].Lateral.Open}</td>
                      <td colSpan="1">{data[i + 1].Rank[1].Lateral.Close}</td>
                    </Fragment>
                  ))}
                </tr>
                <tr style={{ fontWeight: "bold" }}>
                  {[...Array(cutoffDataYears)].map((e, i) => (
                    <Fragment key={`${i}+row3+${index}+${e}`}>
                      <td colSpan="2"></td>
                      <td colSpan="2">{data[i + 1].Admission[2].Total}</td>
                      <td colSpan="2"></td>
                    </Fragment>
                  ))}
                </tr>
              </tbody>
            </table>
          </Fragment>
        );
      })}
      <h3>Category Wise Rank Details</h3>
      <img src={Rank} alt="Category-Wise-Cutoff" style={{ width: "100%" }} />
    </div>
  );
};

const displayTableInColumn = () => {
  return cutoffData.map((data, index) => {
    return (
      <div className="RanksTable" key={`${index}+rowss`}>
        <div className="TableHeader">{data[0].Name}</div>
        <table>
          <tbody>
            {[...Array(cutoffDataYears)].map((e, i) => {
              return (
                <Fragment key={`${i}+headerss+${i}+${e}`}>
                  <tr>
                    <th colSpan="6">{data[i + 1].Year}</th>
                  </tr>
                  <tr>
                    <td colSpan="2">Course Intake</td>
                    <td colSpan="2">Admission</td>
                    <td colSpan="2">Rank</td>
                  </tr>
                  <tr>
                    <td colSpan="2"></td>
                    <td colSpan="2"></td>
                    <td colSpan="1">Opening</td>
                    <td colSpan="1">Closing</td>
                  </tr>
                  <tr>
                    <td colSpan="1">BTech Regular (JEE)</td>
                    <td colSpan="1">{data[i + 1].Intake[0].Regular}</td>
                    <td colSpan="2">{data[i + 1].Admission[0].Regular}</td>
                    <td colSpan="1">{data[i + 1].Rank[0].Regular.Open}</td>
                    <td colSpan="1">{data[i + 1].Rank[0].Regular.Close}</td>
                  </tr>
                  <tr>
                    <td colSpan="1">BTech Lateral-entry (CUET)</td>
                    <td colSpan="1">{data[i + 1].Intake[1].Lateral}</td>
                    <td colSpan="2">{data[i + 1].Admission[1].Lateral}</td>
                    <td colSpan="1">{data[i + 1].Rank[1].Lateral.Open}</td>
                    <td colSpan="1">{data[i + 1].Rank[1].Lateral.Close}</td>
                  </tr>
                  <tr style={{ fontWeight: "bold" }}>
                    <td colSpan="2"></td>
                    <td colSpan="2">{data[i + 1].Admission[2].Total}</td>
                    <td colSpan="2"></td>
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  });
};

const displayCutoffData = (width) => {
  if (width < 1000) {
    return displayTableInColumn();
  } else {
    return displayTableInRow();
  }
};

const Admission = () => {
  const [windowDimension, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);

    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimension]);

  return (
    <>
      <Highlight />
      {headingContainer({ heading: "Admission" })}
      {contentContainer({
        content:
          "REC Sonbhadra is among the top Govt. Engineering Colleges of Uttar Pradesh providing excellent academic environment and top placements in reputed companies, both in the private and government sector. The college has an excellent infrastructure with state-of-the-art facilities and well-qualified faculty, most of them drawn from IITs, NITs and CFTIs. Recently, the students of REC Sonbhadra have proved their mettle by grabbing excellent opportunities in top-notch companies like Amazon, TCS, Infosys, Wipro, Accenture, IBM, FashNap, Thoughtworks, Shipsy, TrueBlue India, Byju's, SimpliLearn, Typhoon Berkshire Infotech, GlueLabs, UpGrad, Axis Bank, Xiaomi India and many more. The highest salary package offered till date is 45 LPA (CTC) by Amazon.",
      })}
      {headingContainer({ heading: "Admission Procedure" })}
      {contentContainer({
        content: unorderedList([
          <>
            Students are admitted to the <b>Undergraduate B.Tech. Programme</b>{" "}
            in the disciplines of <b>Computer Science and Engineering</b>,{" "}
            <b>Electrical Engineering</b>, <b>Electronics Engineering</b> and{" "}
            <b>Mining Engineering</b> through a valid score obtained in{" "}
            <b>Joint Entrance Examination, JEE (Mains)</b> conducted by National
            Testing Agency. Lateral-entry to the second year of all four
            engineering programmes (on 10% intake of total seats) is also
            provided through a valid score obtained in{" "}
            <b>Common University Entrance Test, CUET (UG)</b>.
          </>,
          <>
            <h4 style={{ textAlign: "center" }}>
              Undergraduate B.Tech. Programme (4 years/8 semesters):{" "}
            </h4>
            <br />
            <table className="academic_table">
              <tbody>
                <tr className="th">
                  <td className="td">S.No.</td>

                  <td className="td">Discipline</td>

                  <td className="td">Intake</td>
                </tr>

                <tr>
                  <td className="td">1.</td>
                  <td className="td">Computer Science and Engineering</td>
                  <td className="td">60</td>
                </tr>
                <tr>
                  <td className="td">2.</td>
                  <td className="td">Electrical Engineering</td>
                  <td className="td">60</td>
                </tr>
                <tr>
                  <td className="td">3.</td>
                  <td className="td">Electronics Engineering</td>
                  <td className="td">60</td>
                </tr>
                <tr>
                  <td className="td">4.</td>
                  <td className="td">Mining Engineering</td>
                  <td className="td">30</td>
                </tr>
              </tbody>
            </table>
          </>,
          <>
            <b>Uttar Pradesh Technical Admission Counselling (UPTAC)</b> for
            college allotment is organized by Dr. A.P.J. Abdul Kalam Technical
            University, Lucknow. For details{" "}
            <a
              href="https://uptac.admissions.nic.in/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "blue", textDecoration: "underline" }}
            >
              Click here
            </a>{" "}
            to visit the official website of UPTAC of AKTU, Lucknow.
          </>,
        ]),
      })}
      {admissionProcessFlowchart()}
      <div className="admissionDiv">
        <div className="downloadsElement">
          <div className="downloadsElementTitle">
            Physical Reporting & Registration Guidelines for Newly Admitted
            Students (Academic Session 2024-25)
          </div>
          <div className="downloadsElementGet">
            <a href={PhysicalReportingAndAdmissionGuildelines} target="_blank">
              Download
            </a>
          </div>
        </div>
      </div>

      {/* <h2
        className='h2_heading'
        style={{ width: "90vw", margin: "auto", borderRadius: "10px" }}
      >
        Opening and Closing Ranks at REC Sobhadra
      </h2> */}
      {headingContainer({
        heading: "Opening and Closing Ranks at REC Sobhadra",
      })}
      {displayCutoffData(windowDimension.winWidth)}

      <a
        href="https://docs.google.com/forms/u/0/d/16X4YN1uIURv_ZL2Y0nLWavL8YNbEvvdpt6Um6bvKXAw/viewform?edit_requested=true"
        target="_blank"
      >
        <div className="queryButton">Send Your Query</div>
      </a>

      {headingContainer({ heading: "Admission Helpline Numbers" })}
      {contentContainer({
        content: (
          <p>
            <CallIcon /> 9415164409 , 9792925104 , 9540209899, 8317025411
            ,9956956574, 9450769187,7839454220
          </p>
        ),
      })}
    </>
  );
};
export default Admission;
