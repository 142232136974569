import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../config";
import authHeader from "../services/auth-header";
import { headingContainer, contentContainer } from "../elements";

import "../assets/stylesheets/Thecollege/KnowMore.css";

// {
//   "profilePhoto": {
//       "file_url": "https://res.cloudinary.com/recsfaculty/image/upload/v1690823784/profilePhotoUploads/o3wdeqyhm6u7nu8i3rir.jpg",
//       "public_id": "profilePhotoUploads/o3wdeqyhm6u7nu8i3rir"
//   },
//   "firstName": "Dr. Mainejar",
//   "lastName": "Yadav",
//   "middleName": "",
//   "dateOfBirth": null,
//   "phoneNumber": 8874774393,
//   "email": "mkyadav@recsonbhadra.ac.in",
//   "department": "Computer Science & Engineering",
//   "post": "Assistant Professor",
//   "qualifications": "",
//   "fieldsOfSpecialization": "",
//   "teachingExperience": null,
//   "researchExperience": null,
//   "researchPapers": null,
//   "booksPublished": null,
//   "seminarsOrganised": null,
//   "seminarsAttended": null,
//   "fellowshipAwarded": "",
//   "membershipAndAssociations": "",
//   "phdSupervised": null,
//   "mtechSupervised": null,
//   "fdpsAttended": "",
//   "otherAchievements": "",
//   "personalPageLink": "",
//   "resume": "",
//   "__v": 0
// }

function KnowMore() {
  const [data, setData] = useState();

  let deptcode = window.location.pathname.split("/")[2];
  let number = Number(window.location.pathname.split("/")[3]);
  useEffect(() => {
    const getData = async () => {
      console.log("useEffect called");
      try {
        const res = await axios.get(`${API_URL}faculty?dept=${deptcode}`, {
          headers: authHeader(),
        });
        const filteredData = res.data.filter(
          (item) => item.phoneNumber === number
        );
        setData(filteredData[0]);
      } catch (e) {
        console.log(e);
      }
    };
    getData();
  }, [deptcode, number]);

  console.log(data);
  return data ? (
    <div>
      {headingContainer({ heading: "Know More" })}
      <div className="image-know-more">
        <img className="img" src={data.profilePhoto.file_url}></img>
        <p style={{ fontWeight: "bold", fontSize: "30px" }}>
          {data.firstName && `${data.firstName} `}
          {data.middleName && `${data.middleName} `}
          {data.lastName && data.lastName}
        </p>
      </div>
      {headingContainer({ heading: "About" })}
      {contentContainer({
        content: (
          <>
            <div>
              <p>Department: {data.department}</p>
              {data.post && <p>Post: {data.post}</p>}
              {data.qualification && <p>Qualification: {data.qualification}</p>}
              {data.fieldOfSpecialization && (
                <p>Field Of Specialization: {data.fieldOfSpecialization}</p>
              )}
              {data.teachingExperience && (
                <p>Teaching Experience: {data.teachingExperience}</p>
              )}
              {data.researchExperience && (
                <p>Research Experience: {data.researchExperience}</p>
              )}
              {data.researchPapers && (
                <p>Research Papers: {data.researchPapers}</p>
              )}
              {data.booksPublished && (
                <p>Books Published: {data.booksPublished}</p>
              )}
              {data.seminarsOrganised && (
                <p>Seminars Organised: {data.seminarsOrganised}</p>
              )}
              {data.seminarsAttented && (
                <p>Seminars Attended: {data.seminarsAttented}</p>
              )}
              {data.fellowshipAwarded && (
                <p>Fellowship Awarded: {data.fellowshipAwarded}</p>
              )}
              {data.membershipAndAssociations && (
                <p>
                  Membership and Associations: {data.membershipAndAssociations}
                </p>
              )}
              {data.phdSupervised && (
                <p>PHD Supervised: {data.phdSupervised}</p>
              )}
              {data.mtechSupervised && (
                <p>MTech Supervised: {data.mtechSupervised}</p>
              )}
              {data.fdpsAttended && <p>FDP Attedned: {data.fdpsAttended}</p>}

              {data.otherAchievements && (
                <p>Other Achivements: {data.otherAchievements}</p>
              )}
              {data.personalPageLink && (
                <a style={{ color: "blue" }} href={data.personalPageLink}>
                  Link to Personal Page
                </a>
              )}
              <br></br>
              {data.resume && (
                <a style={{ color: "black" }} href={data.resume}>
                  Resume
                </a>
              )}
            </div>
          </>
        ),
      })}

      {headingContainer({ heading: "Contact" })}
      {contentContainer({
        content: (
          <>
            <p>Phone Number: {data.phoneNumber}</p>
            <p>
              Email:{" "}
              <a style={{ color: "blue" }} href={data.email}>
                {data.email}
              </a>
            </p>
          </>
        ),
      })}
    </div>
  ) : (
    <p>Loading.......</p>
  );
}

export default KnowMore;
