import React, { useEffect, useState } from "react";
import AuthService from "../../services/auth-service";
import UserService from "../../services/user-service";
import NavBar from "./NavBar";
import "./admin.css";

import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";

import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Container } from "@mui/material";

import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import VerifiedIcon from "@mui/icons-material/Verified";

import { NotificationManager } from "react-notifications";

function TablePaginationActions(props) {
	const theme = useTheme();
	const { count, page, rowsPerPage, onPageChange } = props;

	const handleFirstPageButtonClick = (event) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<Box sx={{ flexShrink: 0, ml: 2.5 }}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label="first page"
			>
				{theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label="previous page"
			>
				{theme.direction === "rtl" ? (
					<KeyboardArrowRight />
				) : (
					<KeyboardArrowLeft />
				)}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === "rtl" ? (
					<KeyboardArrowLeft />
				) : (
					<KeyboardArrowRight />
				)}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				{theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</Box>
	);
}

TablePaginationActions.propTypes = {
	count: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
		border: 0,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
		border: 0,
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	["&:nth-of-type(odd)"]: {
		backgroundColor: theme.palette.action.hover,
	},
	// hide last border
	["&:last-child td, &:last-child th"]: {
		border: 0,
	},
}));

const BoardUser = (props) => {
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);
	const [loading, setLoading] = useState(false);
	var currentUser = AuthService.getCurrentUser();
	if (!currentUser || currentUser.roles[0] !== "ROLE_ADMIN") {
		props.history.push("/facultylogin");
		window.location.reload();
	}
	const [userInfo, setUserInfo] = useState([]);

	const call_me = async () => {
		UserService.getAdminBoard()
			.then((response) => {
				console.log("Response: ", response.data);
				return response.data;
			})
			.then((userInfo) => setUserInfo(userInfo))
			.catch((error) => {
				console.log(error);
				AuthService.logout();
			});
	};
	async function deleteUser(delUser) {
		setLoading(true);
		console.log(delUser);
		const msg = await AuthService.deleteuser(delUser);
		if (msg.status === 200) {
			setLoading(false);
			NotificationManager.success(msg.data.message);
			alert(msg.data.message);
			call_me();
		}
	}

	useEffect(() => {
		call_me();
	}, []);
	console.log(userInfo);

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userInfo.length) : 0;

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};
	return (
		<>
			<NavBar />
			<Container maxWidth="md" sx={{ padding: 5 }}>
				<TableContainer component={Paper} sx={{ maxWidth: 800 }}>
					<Table sx={{ minWidth: 700 }} aria-label="customized table">
						<TableHead>
							<TableRow>
								<StyledTableCell>Username</StyledTableCell>
								<StyledTableCell align="right">Email</StyledTableCell>
								<StyledTableCell align="right">Roles</StyledTableCell>
								<StyledTableCell align="right">Action</StyledTableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{(rowsPerPage > 0
								? userInfo.slice(
										page * rowsPerPage,
										page * rowsPerPage + rowsPerPage
								  )
								: userInfo
							).map((row) => (
								<StyledTableRow key={row.username}>
									<StyledTableCell
										component="th"
										scope="row"
										style={{ width: 120 }}
									>
										{row.username}
									</StyledTableCell>
									<StyledTableCell style={{ width: 160 }} align="right">
										{row.email}
									</StyledTableCell>
									<StyledTableCell style={{ width: 160 }} align="right">
										{row.roles}
									</StyledTableCell>
									<StyledTableCell style={{ width: 80 }} align="right">
										<IconButton onClick={() => deleteUser(row.email)}>
											{loading ? <CircularProgress /> : <DeleteOutlinedIcon />}
										</IconButton>
									</StyledTableCell>
								</StyledTableRow>
							))}

							{emptyRows > 0 && (
								<StyledTableRow style={{ height: 53 * emptyRows }}>
									<StyledTableCell colSpan={6} />
								</StyledTableRow>
							)}
						</TableBody>

						<TableFooter>
							<StyledTableRow>
								<TablePagination
									rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
									colSpan={4}
									count={userInfo.length}
									rowsPerPage={rowsPerPage}
									page={page}
									SelectProps={{
										inputProps: {
											"aria-label": "rows per page",
										},
										native: true,
									}}
									onPageChange={handleChangePage}
									onRowsPerPageChange={handleChangeRowsPerPage}
									ActionsComponent={TablePaginationActions}
								/>
							</StyledTableRow>
						</TableFooter>
					</Table>
				</TableContainer>
			</Container>
		</>
	);
};

export default BoardUser;
