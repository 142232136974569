// import Hom from "@material-ui/icons/HomeOutlined";
import Hom from "@mui/icons-material/HorizontalRule";
import React from "react";
// import React from 'react';
import Carousel from "react-material-ui-carousel";
import { Paper } from "@material-ui/core";
import HomePlacementsCarousel from "./HomePlacementsCarousel";

import "../assets/stylesheets/HomePage/Topslider.css";
import Homedepartment from "./Homedepartment";
import Homeabout from "./Homeabout";
import Newsevent from "./Newsevent";
import Highlight from "./Highlight";
//import Notice from "./SpNotice";
import Sidemenuforabout from "./Sidemenuforabout";

import Slider1 from "../images/HomeSliders/Slider1.jpeg";
import Slider3 from "../images/HomeSliders/Slider3.jpeg";
import image3 from "../images/HomeSliders/DSC_31.JPG";
import Slider5 from "../images/HomeSliders/Slider5.jpg";
import Slider6 from "../images/HomeSliders/Slider6.jpg";
import Slider7 from "../images/HomeSliders/Slider7.jpg";
import Slider8 from "../images/HomeSliders/Slider8.jpg";
import Slider11 from "../images/HomeSliders/Slider11.jpg";
import Slider12 from "../images/HomeSliders/Slider12.jpg";
import Slider13 from "../images/HomeSliders/Slider13.jpg";
import Slider14 from "../images/HomeSliders/Slider14.jpg";
import Slider15 from "../images/HomeSliders/Slider15.jpg";

import TnPPosterPopup from "./TnPPosterPopup";
import PopUpPoster from "./PopUpPoster";

function Example(props) {
  const images = [
    Slider1,
    Slider3,
    image3,
    Slider5,
    Slider6,
    Slider7,
    Slider8,
    Slider13,
    Slider14,
    Slider15,
  ];
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // backgroundColor: "white",
      }}
    >
      <Carousel
        IndicatorIcon={<Hom />}
        indicatorContainerProps={{
          style: {
            marginTop: "-50px", // 5,
          },
        }}
      >
        {images.map((image, index) => (
          <Paper key={index}>
            <img src={image} key={index} alt="sry" className="img_slider" />
          </Paper>
        ))}
      </Carousel>
    </div>
  );
}

const Home = () => {
  return (
    <>
      {/* <PopUpPoster /> */}
      <TnPPosterPopup />

      {/* High Priority redo styling for home image carsoul and side menu about */}
      <div className="home_slider_div">
        <Example />
        <Sidemenuforabout />
      </div>

      <Highlight />
      {/* <Notice /> */}
      <Homeabout />

      <Newsevent />

      <HomePlacementsCarousel />

      <Homedepartment />
    </>
  );
};

export default Home;
