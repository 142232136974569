import CSElabImage from "../../images/ComputerCentreIMG.jpg";
import EElabImage from "../../images/usefull_images/Language Lab/ELECTRICALLAB7.jpeg";
import ELlabImage from "../../images/usefull_images/Language Lab/ELECTRONICSLAB2.jpeg";

const departmentData = {
  AS: {
    about: {
      aboutTheDepartment: (
        <>
          The Department of Applied Science & Humanities is the
          Inter-Disciplinary department that acts as a bridge between the basic
          sciences like physics, chemistry and mathematics with engineering
          practices. The department aims to impart excellent quality education
          in the area of Applied Sciences (Mathematics, Physics, and Chemistry),
          Management, Technical Communication skills and Soft Skills for the
          future technocrats of all streams. The faculty of Applied Sciences
          takes pride in its decorated faculty members, who are highly
          experienced and dedicated to their respective specialized thrust
          areas, actively engaged in quality research and continuous upgradation
          of their pedagogical skills.
        </>
      ),
      vision: [
        "To nurture and strengthen the knowledge of budding technocrats in the field of basic sciences, humanities and management and preparing socially responsible citizens that significantly contribute towards nation’s progress.",
      ],
      mission: {
        listItems: [
          "To play a pivotal role in building strong foundation of basic sciences and mathematics in various disciplines of engineering.",
          "To groom students at par with the global technical workforce through basic courses and value-added teaching and building confidence, character and human values.",
          "To develop scientific attitude and promote innovative research in fundamental science.",
        ],
        trailingText: "",
      },
      // programmeEducationalObjectives: {
      //     beginingText: "",
      //     listItems: [""],
      // },
      // programmeSpecificOutcomes: {
      //     beginingText: "",
      //     listItems: [""],
      // }
    },
    people: {
      dept: "AS",
      hodText:
        "We take pride in the fact that our department has the strength of highly qualified and seasoned faculty to guide the students in every domain of technical education making them familiar with all areas. Our faculties’ features/ papers published in National as well as International journals of repute reflect their knowledge and level of learning.",
    },
    lab: {
      // labImage: "",
      // beginingText: "",
      labList: [
        "The department offers audacious Laboratories for the development of universal skills of new generation graduates.",
        "Modern and fully optimized labs for betterment of the student in his proffesional and academic journey.",
      ],
    },
    achievements: {
      // studentList: [""],
      // facultyList: [""],
    },
    activities: {
      activityList: [
        "Applied Sciences and Humanities Department of REC Sonbhadra organized an “International Conference on Recent Advances in Science and Engineering (RASE 2021)” on February 26 & 27 2021.",
        "Organized a One Week Online Faculty Development Programme “Emerging Pedagogy in Online Teaching (EPOTL 2020)” sponsored by Dr A.P.J. Abdul Kalam Technical University Lucknow in association with Electrical Engineering Department.",
        "Organized a One Week Online Faculty Development Programme on Internet of Things (IOT-2020) sponsored by AICTE Training & Learning (ATAL) Academy in association with Electrical Engineering Department.",
      ],
    },
  },
  CSE: {
    about: {
      aboutTheDepartment: (
        <>
          The Computer Science and Engineering department was established in the
          year 2015 and has now established itself as a pioneering department in
          terms of intellectual capital, infrastructure and facilities. Computer
          Science and Information Technology is the most demanding sector of
          recent times in terms of technology advancement, innovation and need
          for human resources. The department aims to groom the students into
          competent, creative and innovative technocrats who would be capable of
          meeting the expectations of the society and challenges in the
          corporate environment. The department aspires to play a leading role
          in the national vision of creating a futuristic knowledge economy
          through creativity and innovation in Information and Communication
          Technologies. The department offers a 4-year B.Tech. programme in
          Computer Science and Engineering with student intake of 60 seats. The
          department is equipped with state-of-the art lecture halls, smart
          classrooms and various laboratories for different subjects of Computer
          Science and Engineering.
          <br />
          <br />
          The laboratories have all the basic equipments, computer systems on
          LAN with high speed Internet connectivity and necessary software tools
          that provide the students an environment to learn, explore and build
          their practical/programming skills. Our competent and highly qualified
          faculty members are committed to guide the students in building and
          enhancing their analytical thinking ability and creativity; and in
          understanding the ethical issues, professional conduct, economics,
          societal needs and their role in the global technological sector. The
          department is also committed to foster a creative and innovative
          research environment. Our students have been selected in various
          reputed multinational companies in IT sector like Infosys, Wipro, TCS,
          Accenture, IBM, Dell, TrueBlue, Capgemini, Cognizant, Shipsi,
          GeekyAnts, Maersk, NIIT Technologies, HCL, ThoughtWorks, AtAI Labs,
          Simplilearn, Mindtree, HCL, Betaflux, Ken42, Tyfoon, Eleva,
          3Analytics, CapitalVia, MagicBricks, Byju’s, Tripock Labs and many
          more. Many of our BTech (CSE) students have also been selected for
          higher studies and research in reputed foreign universities like
          Concordia University, University of Utah, University of Amsterdam,
          University of Glasgow, University of Surrey, University of Sheffield,
          University of LiverPool, University College Dublin, Queen Mary
          University of London and also in Indian Institutions of repute like
          IITs, IIITs and NITs.
        </>
      ),
      vision: [
        "To be a centre of excellence in the field of academics by moulding and raising quality computer science professionals; and to promote quality research that can address a wide range of complex scientific, technological, and social problems.",
      ],
      mission: {
        listItems: [
          "To inculcate passion for academic excellence in faculty and students by adopting an innovation-centric teaching and learning process",
          "To nurture and produce technically sound, globally competent, morally upright and socially responsible computer science and engineering professionals having commitment to lifelong learning",
          "To impart professional and skill-oriented training to our students through an industry interface in order to develop employability skills and to make them industry-ready",
          "To equip our students with necessary resources to perform cutting edge research in the field of computer science and engineering and to develop entrepreneurial skills",
        ],
        trailingText: "",
      },
      //   programmeEducationalObjectives: {
      //     beginingText: "",
      //     listItems: [""],
      //   },
      //   programmeSpecificOutcomes: {
      //     beginingText: "",
      //     listItems: [""],
      //   },
    },
    people: {
      dept: "CSE",
      hodText:
        "Today we find that information technology has become overwhelmingly pervasive, while its parent, computing science, has become correspondingly hard to find. There are several ways to present the canonical core of computer science. Technology changes rapidly, especially in the field of computing, whereas the science, if it changes at all, does so much more gradually.",
    },
    lab: {
      labImage: CSElabImage,
      // beginingText: "",
      labList: [
        "The Computer Science and Engineering department offers a sophisticated and well distributed centre aka Computer Centre",
        "The mission of the centre is to provide high quality computers and softwares for the enhancement of accessibility and betterment of skills for computer science students.",
      ],
    },
    achievements: {
      studentList: [
        "Sachin Kumar Singh (student of BTech CSE batch 2016-20) got selected as a Research Assistant at Flux Research Group School of Computing, University of Utah, Salt Lake City, Utah, United States. He had multiple offers from Foreign Universities (ranked in top 100) like University of Amsterdam, Netherlands; University of Glasgow, Scotland and University of Surrey Guildford, England.",
        "Sakshi Singh (student of BTech CSE batch 2017-21) got mutiple offers from Foreign Universities for Masters course like University of Sheffield, England; University of Liverpool, England; University College Dublin, Ireland and Queen Mary University of London, England. She also got selected in IIT Jodhpur for her Masters Programme.",
        "Amrita Awasthi (student of BTech CSE batch 2017-21) got selected in Concordia University, Montreal, Canada for her Masters programme.",
        "Sachin Kumar Singh got his research work titled “A (k, n) Audio Secret Sharing with Share Authentication” published in the International Journal of Computing and Digital Systems (SCOPUS indexed) under the guidance of Dr. Mainejar Yadav (Assistant Professor, CSE Dept.).",
        "Sachin Kumar Singh (a student of CSE final year) got his research work titled “Audio Quality Control Method Based on ASS (Audio Sectret Sharing)” published in the proceedings of 25th International Symposium on Frontiers of Research in Speech and Music (FRSM 2020) held at NIT Silchar. The paper has been published in the AISC Book Series of Springer (SCOPUS indexed) titled “Advances in Speech and Music Technology” under the guidance of Dr. Mainejar Yadav.",
        'A student project "Smart Tree Plantation" by Mohd Adil Fazal Mubeen and Team (students of BTech CSE), mentored by Mr. Ashish Ranjan Mishra qualified for the semi-finals in IICDC-2019 organized by DST & Texas Instruments (TI) Inc., powered by AICTE mission, anchored by NSRCEL@IIMB and supported by MyGov.',
        "Anushka Agarwal (CSE 4th Year) participated in Annual Coding Contest for Women by Techgig and achieved 10th Rank in TechGig Geek Goddess 2018",
        "A team of final year students of BTech (CSE) - Vikram Singh, Rahul Rajput, Abhishek Chaudhary and Sachin Kumar Singh participated in ACM ICPC 2019 (Coding World Cup) and secured 729 rank all over India in 1st round.",
      ],
      facultyList: [
        "Dr. Amod Kumar Tiwari, Associate Professor, Computer Science and Engineering Department was invited as a member of Selection Committees for recruitment of Guest Faculty at Rajkiya Engineering College, Banda and Dr. Ambedkar Institute of Technology for Handicapped, Kanpur.",
        "Dr. Amod Kumar Tiwari was also invited as a member of Selection Committee for selection of Junior Research Fellow in a research project funded by MeitY, Govt of India at Rajkiya  Dr. Amod Kumar Tiwari was also invited as a member of Selection Committee for selection of Junior Research Fellow in a research project funded by MeitY, Govt of India at Rajkiya Engineering College, Banda.",
        "Dr. Anurag Sewak, Assistant Professor, Computer Science and Engineering Department contributed to the curriculum development and syllabi designing of B.Tech. (CS/IT), MCA and allied branches of Dr. APJ Abdul Kalam Technical University, Lucknow. He was a member of Board of Studies for CS/IT/MCA at AKTU Lucknow from July 2018 to June 2021.",
        "Mr. Ashish Ranjan Mishra, Assistant Professor, Computer Science and Engineering Department contributed to the curriculum development and syllabi designing of various programmes of Computer Science and Engineering Department at Kamla Nehru Institute of Technology, Sultanpur (An autonomous constituent college of Dr. APJ Abdul Kalam Technical University, Lucknow). He is an active member of Board of Studies for Computer Science and Engineering Department of KNIT Sultanpur.",
        "Dr. Anurag Sewak was felicitated by Director, University Program, Texas Instruments and Chairman, All India Council for Technical Education in appreciation for fostering the ecosystem bridging Government, Academia and Industry.",
        "Mr. Ashish Ranjan Mishra jointly received the “Best Paper Award” with co-authors P. Pandey, P.K. Verma and R. P. Tripathi for the research paper “Study and Implementation of Smart Water Supply Management System Model for Water Drain Region in India” at Springer International Conference on VLSI and Microwave and Wireless Technology (ICVMWT-2021), pp.1- 10, 2021, jointly organized by MMMUT Gorakhpur, JK-IAPT, Allahabad University and Manipal University, Jaipur, India.",
        "Dr. Mainejar Yadav, Assistant Professor, Computer Science and Engineering Department was selected for the presentation of his research work in ISEA Virtual Presentation Conclave (IVPC) 2020 (an initiative under ISEA Project Phase – II, MeitY, Govt of India) organized by IIT Guwahati.",
        "Dr. Anurag Sewak acted as Judge in the Grand Finale of Software Edition of prestigous “Smart India Hackathon 2020” held during 01st to 3rd August 2020.",
        "Dr. Anurag Sewak has been recognized as “NPTEL Star” and has been awarded with “NPTEL Discipline Star” and “NPTEL Believer” Certificates.",
        "Dr. Anurag Sewak initiated the idea of running a Coding Chapter at REC Sonbhadra in 2019. He has been acting as the Faculty Mentor for CodeChef RECS College Chapter for the sessions 2019-20, 2020-21 and 2021-22.",
        "Dr. Anurag Sewak has been acting as the SPOC (Single point of contact) for NPTEL SWAYAM Local Chapter since 2019. He was felicitated with a “Certificate of Appreciation” in a SPOC Felicitation Workshop on 1st February 2020 by NPTEL at IIT Kanpur Outreach Centre, Noida for his contributions.",
        "Dr. Anurag Sewak is also the SPOC (Single point of contact) for Electronics & ICT Academy (A joint inititiative of MeitY, Govt. of India and IIT Kanpur) and Prutor Platform of IIT Kanpur. He has been felicitated with “Certificates of Recognition” for his instrumental role in organizing MOOCs courses for BTech 1st Year and 2nd Year students during September 2020 – February 2021, April 2021 – August 2021.",
      ],
    },
    activities: {
      activityList: [
        'Computer Science and Engineering Department of REC Sonbhadra organized a One-week Faculty Development Programme on "Cyber Security: Challenges and Practices (CSCP 2021)" during 29th July - 2nd August 2021.',
        "Organized a coding competition “CODEON” in collaboration with Prutor@IIT Kanpur on 14th July 2021",
        "Organized a One-week Lecture Series on “Recent Trends in Computer Science and Engineering” by experts from Foreign Universities during 05th July - 09th July 2021.",
        "Organized a Webinar on Research Methodology by Prof. N.S. Chaudhary, IIT Indore on 14th June 2021.",
        "Organized a Webinar on “Big Data” in association with Softpro India, Lucknow on 22nd June 2020.",
        'Organized a One Week TEQIP-III Sponsored Faculty Development Programme on " Smart Infrastructures and Secure Computing Technologies (SISCT 2020)" during 10th - 14th February 2020.',
      ],
    },
  },
  EE: {
    about: {
      aboutTheDepartment: (
        <>
          The Department of Electrical Engineering was established in 2016 and
          has an intake of 60 seats. The department has a strength of five
          dedicated regular faculty members holding master and doctorate degrees
          from reputed institutions including IITs and NITs. The faculty members
          and students of Electrical Engineering Department are actively
          involved in research activities and have published various significant
          number of research papers in reputed International Journals, and in
          proceedings of various International and National Conferences,
          Seminars and Symposia. The department offers a 4-year Bachelor of
          Technology programme of Dr. A.P.J. Abdul Kalam Technical University,
          Lucknow with a student intake of 60.
        </>
      ),
      vision: [
        "To inculcate an ethical and social responsibility in Electrical Engineers to serve the future need and challenges of the 21st century.",
      ],
      mission: {
        listItems: [
          "Offers a well-balanced programme of instruction, practical experience, and opportunities for overall personality development to create professionalism for industry.",
          "To deliver quality academic programs based on state-of-art R&D to produce high quality R & D professionals.",
        ],
        trailingText: "",
      },
      //   programmeEducationalObjectives: {
      //     beginingText: "",
      //     listItems: [""],
      //   },
      //   programmeSpecificOutcomes: {
      //     beginingText: "",
      //     listItems: [""],
      //   },
    },
    people: {
      dept: "EE",
      hodText:
        "The Department has taken up the task of developing competent Electrical engineers of high quality, capable of facing various challenges of the power situation in the country. To produce graduates who are able to apply the technical skills which they have learnt in the department in order to serve the State and National Industries and PSU's.",
    },
    lab: {
      labImage: EElabImage,
      // beginingText: "",
      labList: [
        "A holistic laboratory for the electrical engineering students to build their industry skills",
        "State-of-the Art Place to enrich the equipmental learning",
        "Modern Equipments with equally good maintenance",
      ],
    },
    achievements: {
      studentList: [
        <>
          Following students of Electronics Engineering Department brought
          laurels to the department by qualifying the prestigious GATE-2021
          exam:
          <table className='trainingPlacementTable' style={{ width: "90%" }}>
            <tr>
              <td className='studentGrievanceTableHeader'>
                <b>Name of the Student</b>
              </td>
              <td className='studentGrievanceTableHeader'>
                <b>Year</b>
              </td>
              <td className='studentGrievanceTableHeader'>
                <b>GATE Score</b>
              </td>
              <td className='studentGrievanceTableHeader'>
                <b>AIR</b>
              </td>
            </tr>
            <tr>
              <td>Lokendra Singh Rajput</td>
              <td>IV</td>
              <td>661</td>
              <td>1329</td>
            </tr>
            <tr>
              <td>Satyam Kumar</td>
              <td>IV</td>
              <td>445</td>
              <td>2127</td>
            </tr>
            <tr>
              <td>Praveen Kumar Gupta</td>
              <td>IV</td>
              <td>528</td>
              <td>3946</td>
            </tr>
            <tr>
              <td>Ankit Yadav</td>
              <td>IV</td>
              <td>490</td>
              <td>5013</td>
            </tr>
            <tr>
              <td>Amit Mishra</td>
              <td>IV</td>
              <td>437</td>
              <td>6995</td>
            </tr>
            <tr>
              <td>Harshvardhan</td>
              <td>IV</td>
              <td>422</td>
              <td>7663</td>
            </tr>
            <tr>
              <td>Deepak Kumar</td>
              <td>IV</td>
              <td>411</td>
              <td>8224</td>
            </tr>
            <tr>
              <td>Satyam Tiwari</td>
              <td>III</td>
              <td>392</td>
              <td>9134</td>
            </tr>
            <tr>
              <td>Ritesh Chandra Shukla</td>
              <td>III</td>
              <td>377</td>
              <td>10008</td>
            </tr>
            <tr>
              <td>Umesh Kumar Chaurasiya</td>
              <td>III</td>
              <td>362</td>
              <td>10966</td>
            </tr>
            <tr>
              <td>Anuj Maurya</td>
              <td>III</td>
              <td>350</td>
              <td>11730</td>
            </tr>
            <tr>
              <td>Manasi Singh</td>
              <td>III</td>
              <td>237</td>
              <td>25449</td>
            </tr>
          </table>
        </>,
        "A group of final year students developed a project on “Smart Dustbin” and were facilitated by a cash prize of Rs. 10000/- by Dr. APJ Abdul Kalam Technical University, Lucknow.",
      ],
      facultyList: [
        "Dr Vijay Pratap Singh, Assistant Professor, Electrical Engineering Department was honoured with the “Best Teachers Award 2020” by Dr. APJ Abdul Kalam Technical University, Lucknow on the occasion of Teacher’s Day, 05th September 2020.",
        "Dr. S. K. Pandey was recognized as “NPTEL Star” and was awarded with an “NPTEL Believer’s Certificate” for July-December 2020.",
      ],
    },
    activities: {
      activityList: [
        'Electrical Engineering Department of REC Sonbhadra organized a One Week TEQIP-III sponsored Faculty Development Programme on "Power System Operation and Control (PSOC 2019)" during 06th - 10th May 2019.',
        'Organized an ATAL Academy Faculty Development Programme on "Artificial Intelligence” sponsored by AICTE, New Delhi during 21st - 25th September 2020.',
        'Organized a One Week TEQIP-III sponsored Faculty Development Programme on "Advanced Control Strategies Design and Applications (ACSDA 2021)” during 28th June - 02nd July 2021.',
        'To organize an ATAL Academy Faculty Development Programme on "Energy Management & Planning in Smart Cities for Sustainable Development (EMPSSD 2021)” sponsored by AICTE, New Delhi during 20th - 24th September 2021.',
        "Electrical Engineering Department of REC Sonbhadra organized an Expert Lecture on “Big Data Applications in Smart Grid” by Prof. Kameswara Musti from Department of Electrical and Computer Engineering, Namibia University of Science and Technology, Namibia on 26th June 2021.",
      ],
    },
  },
  EL: {
    about: {
      aboutTheDepartment: (
        <>
          Electronics Engineering has been one of the most flourishing sectors
          in the past few decades and the amount of advancement we have achieved
          in this field is really remarkable. It deals with solid-state physics,
          radio engineering, telecommunications, control systems, signal
          processing, Image processing, computer engineering, instrumentation
          engineering, machine learning, robotics, internet of things and many
          more.
        </>
      ),
      vision: [
        "To create a skilled workforce of Electronics Engineering graduates by disseminating quality of education in order to meet the industry demand and serve the growing need of society.",
      ],
      mission: {
        listItems: [
          "To fertilize the learning process by adopting modern pedagogy.",
          "To train students on cutting edge technologies for working in a future-ready industry.",
          "To develop communication skills & leadership qualities among students for a successful career.",
          "To create research infrastructure for endorsing research activities",
        ],
        trailingText:
          "Electronics students can develop a thrilling career in industries like consumer electronics manufacturing organization, telecommunication & IT industries, health care equipment manufacturing, mobile communication (2G, 3G, 4G, 5G and beyond), Internet technologies, Industrial electronics, and other industries like steel, petroleum and chemical industry etc. They have lots of opportunities in Government as well as in private companies in the areas of design, manufacture, installation, operation, and maintenance of electronics equipment and systems. With the advent of latest technological innovations, new opportunities came into existence for electronics engineers. The state-of-the-art technologies include self-driving cars, autonomous drone logistics, robotics, automation in industries, smart energy systems etc. Electronics Engineers can acquired by top recruiters (both private and government) like DMRC, Siemens, Motorola, Intel, Texas Instruments, BEL, ISRO, DRDO, Accenture, Wipro, HCL Technologies, nVIDIA, Samsung, Tech Mahindra, Infosys, TCS, Conexant, MTNL, AIR, BSNL, Indian Air force, Indian Navy, Railways, Bharat Electronics Ltd and Flextronics and Philips Electronics. The Department of Electronics Engineering is committed to impart technical education in the most efficient manner to its students. It was established in 2016. The department is equipped with workshops/labs for achieving engineering education excellence. It consists of basic instruments, software/simulators for providing the students an industrial environment, which gives an opportunity to learn and explore.",
      },
      programmeEducationalObjectives: {
        beginingText:
          "The Program Educational Objectives(PEOs) for the Electronics Engineering program describe accomplishments that graduates are expected to attain within five to seven years after graduation",
        listItems: [
          "To develop technical skills in electronics engineering graduates so that it will be helpful in pursuing higher studies.",
          "To prepare students for understanding of existing literatures, innovative problem identification & produce solutions using research oriented methodologies.",
          "To develop a strong understanding of hardware & software tools so that our graduate engineers will be employable in existing industries.",
        ],
      },
      programmeSpecificOutcomes: {
        beginingText:
          "After the successful completion of B.Tech programme in Electronic Engineering, the graduates will be able to",
        listItems: [
          "Use the electronics engineering concepts to develop applications related to internet of things and robotics.",
          "Use communication & signal processing concepts to develop new applications related to indoor and outdoor communication.",
          "Use the concepts of RF and microwave to design antennas for various applications of wireless communication.",
        ],
      },
    },
    people: {
      dept: "EL",
      hodText:
        "Having undergone such a robust academic Programme under the supervision of highest quality faculty members, I am sure that all passing out students of the department are capable of visualizing, planning and developing big projects of commercial and research interests in their respective field of expertise. The graduates of the Electronics Stream have been selected by some of the leading software and hardware companies of the country.",
    },
    lab: {
      labImage: ELlabImage,
      // beginingText: "",
      labList: [
        "A State-of-the-art lab having industry-level equipments",
        "Premium Workspace for an electronics engineer to grow their circuitry designery",
        <>
          <b>A-Class</b> Teachers to help students grow their embedded circuitry
          skills.
        </>,
      ],
    },
    achievements: {
      studentList: [
        'A student project "Smart IOT Based Water Management System" by Mr. Ankit and Team, mentored by Mr. Prashant Pandey qualified for the semi-finals in IICDC-2019 organized by DST & Texas Instruments (TI) Inc., powered by AICTE mission, anchored by NSRCEL@IIMB and supported by MyGov.',
      ],
      facultyList: [
        "Dr Dharmendra Dixit, Assistant Professor, Electronics Engineering Department was honoured with the “Best Teachers Award 2021” by Dr. APJ Abdul Kalam Technical University, Lucknow on the occasion of Teacher’s Day, 05th September 2021.",
        "Mr. Prashant Pandey (Assistant Professor) and Dr. P.K. Verma (Assistant Professor) of Electronics Engineering Department jointly received the “Best Paper Award” with co-authors A. R. Mishra and R. P. Tripathi for the research paper “Study and Implementation of Smart Water Supply Management System Model for Water Drain Region in India” at Springer International Conference on VLSI and Microwave and Wireless Technology (ICVMWT-2021), pp.1-10, 2021, jointly organized by MMMUT Gorakhpur, JK-IAPT, Allahabad University and Manipal University, Jaipur, India.",
        'Dr. Himanshu Katiyar (Associate Professor) and Dr. P.K. Verma (Assistant Professor) of Electronics Engineering Department jointly received the “Best Paper Award” with co-authors A.K. Singh and S. Dixit for the research paper "On the Outage Analysis of Multi-hop Parallel Decode and Forward Relay Network in Nakagami-m fading channel" at IEEE International Conference on Electrical and Electronics Engineering (ICE3-2020), pp. 190-194, MMMUT Gorakhpur, India, 2020 (DOI: 10.1109/ICE348803.2020.9122963)',
        "Dr. P. K. Verma, Assistant Professor, Electronics Engineering Department was honoured with the “Commendable Research Award” and “Cash prize of ₹ 50,000/-” by Delhi Technological University, New Delhi for his research work done during the year 2018.",
        'Dr. Himanshu Katiyar and Dr. P K Verma along with Arun Kumar Singh and Saurabh Dixit received TEQIP-III Sponsored one year project titled, "Cooperative MIMO Communication for Fourth Generation and beyond” (Duration: June 2019 to June 2020)',
        'Dr. Himanshu Katiyar along with Saurabh Dixit and Arun Kumar Singh received TEQIP-III Sponsored one year project titled, "Augmenting the Wireless Communication by Cognitive Machine Learning” (Duration: June 2019 to June 2020)',
      ],
    },
    activities: {
      activityList: [
        'Electronics Engineering Department of REC Sonbhadra organized a One Week TEQIP-III Sponsored Faculty Development Programme on "Smart Devices and Intelligent Systems (SDIS 2020)" during 27th - 31st January 2020.',
        'Organized a One Week TEQIP-III Sponsored Faculty Development Programme on "Recent Trends in VLSI, RF & Wireless Communication (RTVRWC 2019)" during 01st - 5th July 2019.',
        "Organized a Webinar on “Significance of Medical Robotics in Healthcare” by Dr. Sarada Prashad Dakua, Academic Research Scientist, Hamad Medical Corporation, Qatar on 11th June, 2021.",
      ],
    },
  },
  ME: {
    about: {
      aboutTheDepartment: (
        <>
          The Department of Mining Engineering has been established in the year
          2023 which offers a four-year B.Tech. programme in Mining Engineering
          with an intake of 30 seats. Students are admitted to the B.Tech.
          (Mining Engineering) programme through the prestigious JEE (Mains)
          entrance exam and UPTAC counselling of Dr. A.P.J. Abdul Kalam
          Technical University, Lucknow. It offers a comprehensive curriculum
          covering the discipline's core concepts and skill development. The
          curriculum has a broad foundation in humanities and sciences fostering
          character-building opportunities and a rich set of technical courses
          for developing domain knowledge and skills required by the mining
          industry. The program encompasses all aspects of Mining Engineering
          and will produce capable manpower as per society requirement.
        </>
      ),
      //   vision: [""],
      //   mission: {
      //     listItems: [""],
      //     trailingText: "",
      //   },
      //   programmeEducationalObjectives: {
      //     beginingText: "",
      //     listItems: [""],
      //   },
      //   programmeSpecificOutcomes: {
      //     beginingText: "",
      //     listItems: [""],
      //   },
    },
    people: {
      dept: "ME",
      hodText:
        "The Department of Mining Engineering is committed to academic excellence and skill-oriented education. The academic programme is well structured and facilitated by well-equipped labs. The B.Tech. programme is designed to address all the requirements of the present era in terms of its curriculum. I am sure that all passing out students will have all the requisite knowledge and skills, so that they can easily be accommodated with the leading industries of mining like CIMFR, NIRM, UGC, ISMU, NCL, CCL, SCCL, CIL, HZL, UCIL etc.",
    },
    lab: {
      //   labImage: "",
      beginingText:
        "The Department of Mining Engineering is equipped with state-of-the-art laboratories having all the necessary equipment and a well-designed curriculum for skill-oriented practical training of students. Following laboratories have been established in the department supported by the DMF fund",
      labList: [
        "Material Testing and Evaluation cum Concrete Technology Lab",
        "Environmental Engineering Lab",
        "Mine Surveying Lab",
        "Geotechnical Engineering Lab",
        "Mine Geology Lab",
      ],
    },
    achievements: {
      //   studentList: [""],
      //   facultyList: [""],
    },
    activities: {
      //   activityList: [""],
    },
  },
};

export default departmentData;
