import React, { useEffect, useState } from "react";

import "../assets/stylesheets/HomePage/Newsevent.css";
import Newsicon from "../images/New.png";
import Newspaper1 from "../images/RECNewsImages/Anothernewsimage.jpg";

import Calender from "../images/Calender.png";
import FiberNewIcon from "@material-ui/icons/FiberNew";
import { Link } from "react-router-dom";
import Carousel from "react-material-ui-carousel";
import { Paper } from "@material-ui/core";
import Newspaper2 from "../images/RECNewsImages/Newspaper.png";
import Newspaper3 from "../images/RECNewsImages/newsimage.jpg";

import { API_URL } from "../config";

const axios = require("axios");

function Example(props) {
  return (
    <Carousel>
      <Paper>
        <img className='news_img' alt='sry' src={Newspaper3} />
      </Paper>
      <Paper>
        <img className='news_img' alt='sry' src={Newspaper1} />
      </Paper>
      <Paper>
        <img className='news_img' alt='sry' src={Newspaper2} />
      </Paper>
    </Carousel>
  );
}
const Newsevent = () => {
  const [circular, setcircular] = useState([]);
  const [news, setnews] = useState([]);

  const call_me = async () => {
    try {
      const res = await axios.get(`${API_URL}newscirc/getallevent`);

      var count = 0;
      var temp_cir = [];
      while (count < 13 && count < res.data[0].circular.length) {
        temp_cir.push(res.data[0].circular[count]);
        count++;
      }

      setcircular(temp_cir);
      // console.log(circular);
      count = 0;
      var temp_news = [];
      while (count < 13 && count < res.data[0].news.length) {
        temp_news.push(res.data[0].news[count]);
        count++;
      }
      // if(res.data.news.length>0)
      setnews(temp_news);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    call_me();
    //    console.log(circular,"asdfh")
  }, []);

  const Sub_items = ({ item }) => {
    return (
      <li style={{ marginBottom: "5%", fontSize: "0.9rem" }}>
        <a href={item.file_url} target='_blank' rel='noreferrer'>
          {item.title}
        </a>
      </li>
    );
  };

  const h3Style = {
    fontWeight: "bold",
    fontSize: "1.55rem",
    textAlign: "initial",
  };

  return (
    <div className='wrapper'>
      <div className='news_grid_container'>
        <div style={{ position: "relative" }}>
          <div
            className='circulars_grid_item'
            style={{ maxHeight: "27rem", overflowY: "auto" }}
          >
            <h3>
              <span style={h3Style}>
                {/*start of news and etenders  */}
                <strong>News & e-tenders</strong>{" "}
              </span>{" "}
              <img src={Calender} alt='' style={{ width: "12%" }} />
            </h3>
            <hr />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flexStart",
              }}
            >
              <ul className='ul_news'>
                {news.map((item, ind) => {
                  return <Sub_items item={item} key={ind} />;
                })}
              </ul>
              <Link to='/noticesviewall'>
                <div className='view_all'>View All</div>
              </Link>
            </div>
          </div>
        </div>
        <div style={{ position: "relative" }}>
          <div
            className='circulars_grid_item'
            style={{ height: "27rem", overflowY: "auto" }}
          >
            <h3>
              <span style={h3Style}>REC Sonbhadra in News</span>
              <img src={Newsicon} alt='' style={{ width: "12%" }} />
            </h3>
            <hr />
            <Example />
            <center>
              <Link to='#'>
                <div className='view_all' style={{ display: "inline" }}>
                  View All
                </div>
              </Link>
            </center>
          </div>
        </div>
        <div style={{ position: "relative" }}>
          <div
            className='circulars_grid_item'
            style={{ height: "27rem", overflowY: "auto" }}
          >
            <h3>
              <span style={h3Style}>Circulars & Notices</span>
              <img src={Calender} alt='' style={{ width: "12%" }} />
            </h3>
            <hr />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flexStart",
              }}
            >
              <ul className='ul_news'>
                {circular.map((items, ind) => {
                  return <Sub_items item={items} key={ind} />;
                })}
              </ul>
              <Link to='/circularviewall'>
                <div className='view_all'>View All</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newsevent;
