import {NEWS_CIRCULAR_LOAD_START,
    NEWS_CIRCULAR_LOAD_SUCCESS,
    NEWS_CIRCULAR_LOAD_FAILURE
} from '../actionTypes'

const initialState={
    news_data:[],
    circular_data:[],
    loading:false,
    error:''
}

const Newsreducer=(state=initialState,action)=>{
    switch(action.type){
        case NEWS_CIRCULAR_LOAD_START:
            return{
                ...state,
                loading:true
            }
        case NEWS_CIRCULAR_LOAD_SUCCESS:
            return{
                ...state,
                loading:false,
                news_data:action.news,
                circular_data:action.circular
            }
        case NEWS_CIRCULAR_LOAD_FAILURE:
            return{
                ...state,
                loading:false,
                news_data:[],
                circular_data:[],
                error:action.error
            }
        default:
            return state;
    }

}
export default Newsreducer;