import { headingContainer, contentContainer, unorderedList } from "../elements";

export default function ActivitiesLayout({ data }) {
  const { activityList } = data;
  return (
    <>
      {headingContainer({ heading: "Activities", margin: true })}
      {contentContainer({
        content: activityList ? (
          unorderedList(activityList)
        ) : (
          <>Coming Soon....</>
        ),
      })}
    </>
  );
}
