import {
  headingContainer,
  contentContainer,
  unorderedList,
  subHeadingContainer,
} from "../elements";

export default function CellsBaseLayout({ data }) {
  return (
    <div className='trainingPlacementContainer'>
      {data.map(({ tag, heading, content, index }) => {
        if (tag === "main-heading") {
          const HeadingContainer = headingContainer;
          return <HeadingContainer key={`${index}+${tag}`} heading={heading} />;
        } else if (tag === "sub-heading") {
          const SubHeadingContainer = subHeadingContainer;
          return (
            <SubHeadingContainer key={`${index}+${tag}`} heading={heading} />
          );
        } else if (tag === "content") {
          const ContentContainer = contentContainer;

          if (content.tag === "unordered-list") {
            return (
              <ContentContainer
                key={`${index}+${tag}`}
                content={unorderedList(content.list)}
              />
            );
          } else {
            return (
              <ContentContainer key={`${index}+${tag}`} content={content} />
            );
          }
        } else {
          return null;
        }
      })}
    </div>
  );
}
