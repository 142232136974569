import React, { useState } from "react";
import Highlight from "../Highlight";
import "./../../assets/stylesheets/life@rec/usefullinks.css";
const Usefullinks = () => {
  const [urlState, setUrlState] = useState(false);
  let aktu = "https://aktu.ac.in/";
  let aicteIndia = "https://www.aicte-india.org/";
  let nptel = "http://nptel.ac.in/";
  let spoken = "http://www.spoken-tutorial.org";
  let upgov = "http://up.gov.in/";
  return (
    <>
      <Highlight />
      <div className="usefulLinkHeader">
        <span>Useful Links</span>
      </div>

      <div className="usefulLinkItems">
        <a href={aktu} target="_blank">
          https://aktu.ac.in/
        </a>
      </div>
      <div className="usefulLinkItems">
        <a href={aicteIndia} target="_blank">
          https://www.aicte-india.org/
        </a>
      </div>
      <div className="usefulLinkItems">
        <a href={nptel} target="_blank">
          http://nptel.ac.in/
        </a>
      </div>
      <div className="usefulLinkItems">
        <a href={spoken} target="_blank">
          http://www.spoken-tutorial.org
        </a>
      </div>
      <div className="usefulLinkItems">
        <a href={upgov} target="_blank">
          http://up.gov.in/
        </a>
      </div>
    </>
  );
};

export default Usefullinks;
