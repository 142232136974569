import { headingContainer, contentContainer, unorderedList } from "../elements";

export default function AboutLayout({ data }) {
  const {
    aboutTheDepartment,
    vision,
    mission,
    programmeEducationalObjectives,
    programmeSpecificOutcomes,
  } = data;
  return (
    <>
      {aboutTheDepartment ? (
        <>
          {headingContainer({ heading: "About the Department", margin: true })}
          {contentContainer({ content: <>{aboutTheDepartment}</> })}
        </>
      ) : (
        <></>
      )}
      {vision ? (
        <>
          {headingContainer({ heading: "Vision", margin: false })}
          {contentContainer({ content: <>{unorderedList(vision)}</> })}
        </>
      ) : (
        <></>
      )}
      {mission ? (
        <>
          {headingContainer({ heading: "Mission", margin: false })}
          {contentContainer({
            content: (
              <>
                {unorderedList(mission.listItems)}
                {mission.trailingText}
              </>
            ),
          })}
        </>
      ) : (
        <></>
      )}
      {programmeEducationalObjectives ? (
        <>
          {headingContainer({
            heading: "Programme Educational Objectives (PEOs)",
            margin: false,
          })}
          {contentContainer({
            content: (
              <>
                {programmeEducationalObjectives.beginingText}
                {unorderedList(programmeEducationalObjectives.listItems)}
              </>
            ),
          })}
        </>
      ) : (
        <></>
      )}
      {programmeSpecificOutcomes ? (
        <>
          {headingContainer({
            heading: "Programme Specific Outcome (PSOs)",
            margin: false,
          })}
          {contentContainer({
            content: (
              <>
                {programmeSpecificOutcomes.beginingText}
                {unorderedList(programmeSpecificOutcomes.listItems)}
              </>
            ),
          })}
        </>
      ) : (
        <></>
      )}
    </>
  );
}
