import React, { useState, useEffect } from "react";
import axios from "axios";

import { Modal, Box, Paper, Button, Fab } from "@mui/material";

import Carousel from "react-elastic-carousel";

import { API_URL } from "../config";

const TnPPosterPopup = () => {
  const [isOpen, setIsOpen] = useState(false);
  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const [poster, setPoster] = useState([]);
  useEffect(() => {
    axios.get(`${API_URL}posters/get?isPopUp=true`).then((res) => {
      let data = [];
      res.data.forEach((item) => {
        data.push(item.poster.file_url);
      });
      data.reverse();
      setPoster(data);
    });
  }, []);

  const placementButtonSX = {
    position: "fixed",
    marginTop: "65vh",
    marginBottom: "0vh",
    marginRight: "100vw",

    backgroundColor: "#ff0000",
    color: "white",
    borderRadius: "10px 10px",
    left: "1vw",

    "&:hover": {
      backgroundColor: "#ff0000",
      color: "white",
    },
  };

  return (
    <>
      <Fab variant='extended' onClick={togglePopup} sx={placementButtonSX}>
        Check Top Placements
      </Fab>
      {isOpen && (
        <Modal open={isOpen} onClose={togglePopup}>
          <Box
            sx={{
              position: "fixed",
              left: "10vw",
              top: "26vh",
              margin: "auto",
              width: "80vw",
              backgroundColor: "rgba(0,0,0,0.2)",
            }}
          >
            <Button onClick={togglePopup} color='error'>
              X
            </Button>
            <Carousel>
              {poster.map((item, ind) => (
                <Paper elevation={2}>
                  <img
                    key={ind}
                    src={item}
                    alt='poster'
                    style={{ height: "50vh", objectFit: "contain" }}
                  />
                </Paper>
              ))}
            </Carousel>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default TnPPosterPopup;
