import { useEffect } from "react";
import { connect } from "react-redux";
import getNewsandCircular from "../store/actions/getNoticeandNews";
import DownloadsLayout from "../layouts/DownloadsLayout";

import "../assets/stylesheets/Downloads.css";

function Circularviewall(props) {
  useEffect(() => {
    props.getNewsandCircular();
  }, []);

  return (
    <DownloadsLayout
      data={{ heading: "Circulars and Notices", downloads: props.circular }}
    />
  );
}
const mapStateToProps = (state) => {
  return {
    circular: state.Newsreducer.circular_data,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getNewsandCircular: () => dispatch(getNewsandCircular()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Circularviewall);
