import React from "react";
import "../../assets/stylesheets/facilities/ComputerCentre.css";
import ComputerCentreIMG from "../../images/ComputerCentreIMG.jpg";
import CC2 from "../../images/CC2.jpeg";
import Highlight from "../Highlight";
export default function ComputerCentre() {
  return (
    <>
      <Highlight />
      <div className="Contents">
        <div className="CCBanner">
          <img src={ComputerCentreIMG} alt="" />
        </div>
        <div className="ComputerCentreMain">
          <div className="cc-part2">
            <div className="line"></div>
            <h3>Computer Centre</h3>
            <img src={CC2} hspace="20" vspace="20" alt="" />
            <p className="fsize">
              The computer centre is one of the central facilities of the
              college by serving as a extravagant resource for catalysing
              adoption and implementation of state of the art/emerging
              technologies within the campus, thereby promoting academic,
              operational and research excellence for various departments in the
              college.
              <br />
              It manages and maintains various servers, computers and
              peripherals and also helps in design and development of software
              requirements of university from time to time. Since its
              commencement, it has been helping University in Computerization of
              various Sections, extending Internet Facility, procurement,
              providing email, development of assorted tools and utility
              software and maintenance of College Website, Establishment and
              Growth of College’s computer centre Training and other Various
              Activities.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
