import {
  NEWS_CIRCULAR_LOAD_START,
  NEWS_CIRCULAR_LOAD_SUCCESS,
  NEWS_CIRCULAR_LOAD_FAILURE,
} from "../actionTypes";
import axios from "axios";
import { API_URL } from "../../config";
const getNewsandCircular = () => async (dispatch) => {
  dispatch({ type: NEWS_CIRCULAR_LOAD_START });
  try {
    const res = await axios.get(`${API_URL}newscirc/getallevent`);
    var news = [];
    var circular = [];
    if (res.data[0].news.length > 0) news = res.data[0].news;
    if (res.data[0].circular.length > 0) circular = res.data[0].circular;
    dispatch({
      type: NEWS_CIRCULAR_LOAD_SUCCESS,
      news: news,
      circular: circular,
    });
  } catch (err) {
    dispatch({
      type: NEWS_CIRCULAR_LOAD_FAILURE,
      error: err.message,
    });
    console.log(err);
  }
};
export default getNewsandCircular;
