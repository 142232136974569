import React from "react";
import "../../assets/stylesheets/Thecollege/Directorprofile.css";
import Director_img from "../../images/Director.png";
import Sidemenuforabout from "../Sidemenuforabout";
import Highlight from "../Highlight";
// import '../../assets/stylesheets/Thecollege/Directorprofile.css'
const Directorprofile = () => {
  return (
    <>
      <>
        <Highlight />

        <div className="aboutus_container">
          <Sidemenuforabout />
          <div>
            <h1>Director</h1>
            <hr />
            <div>
              <img
                alt="sry"
                className="back_img_director_profile"
                src={Director_img}
              />
              <div className="director_profile_heading">
                <h3>Prof. Geetam Singh Tomar</h3>
                <h4>Director, Rajkiya Engineering College Sonbhadra</h4>
                <hr />
                <h5>Contact: 05444-252002</h5>
                <h5>Email: director@recsonbhadra.ac.in</h5>
              </div>
            </div>
            <br />

            <p>
              Prof. Geetam Singh Tomar, received UG, PG, and Ph. D. degrees in
              electronics engineering from Institute of Engineers Calcutta, REC
              Allahabad and State Technology Univ Bhopal, respectively and Post
              Doctoral Fellowship from University of Kent, UK in Computer
              Engineering. Prior to joining this institute he has served as
              Director, Birla Institute Bhimtal, THDC IHET Tehri (Govt of
              Uttarakhand Institute), Director of SRCEM, VITM and MITM Gwalior.
              Faculty in MITS and IIITM Gwalior. Faculty in University of Kent
              and University of West Indies. Apart from this served for 17 years
              in Indian Air Force before joining academics in year 2001.
              <br />
              <br />
              <strong>Awards:</strong> International Pluto award for academic
              achievements in 2009 from IBC, Cambridge UK. Top 100 Academician
              of the world for year 2009 and 2013, U.K.
              <br />
              <strong>Recognitions:</strong> IEEE Senior Member, Fellow IETE and
              IE (I) member ACM (USA), CSI and ISTE , Chairman, IETE Gwalior and
              IEEE MP Subsection.
              <br />
              <strong>Publications:</strong>
              07 Patents, 12 books, 10 book chapters, more than 200 research
              papers
              <br />
              <strong>Sponsored Projects:</strong>
              Completed 05 sponsored projects
              <br />
              <strong>Consultancy:</strong> China and Korea since 2015 and still
              in continuation
              <br />
              <strong>Visiting Faculty: </strong>for 2 universities abroad
              presently Member, Working group setup to finalise IEEE Standard;
              ISO/IEC/IEEE 21451-1-4.
              <br />
              Member of Technical committee IEEE SMC and Industrial Electronics.
              Organised more than 45 IEEE Conferences in 7 countries
              <br />
              <strong>Foreign visits:</strong> Approximately 80 Editor in chief
              – more than 5 presently
              <br />
              <strong>Guidance:</strong> 06 Ph.D. awarded, 02 Submitted, 05 in
              progress
              <br />
              <strong>Research Area:</strong> Cognitive Radio Networks, Digital
              System Design, Wireless Sensor Networks, Future Computing{" "}
            </p>
            {/* <p>
              Prof. Geetam Singh Tomar is the Director of Rajkiyay Engineering College, Sonbhadra, Uttar Pradesh. Previously He was the Director of Krishna Engineering College, Ghaziabad, Uttar Pradesh. He is a seasoned leader and a learned academician who brings proven expertise in Research, Academics and Industry. With a career spanning over 25 years, Dr. Tiwari carries a rich experience of leading in various capacities such as Director, Head of the Department, Professor, Engineer and Researcher. He has been serving in education for more than 19 years, spent initial five years in gaining industrial experience.

As the Director, Dr. Tiwari has immensely contributed to the overall growth of the colleges and has helped various projects scale new heights. He has been at eminent positions at various institutes. He joined Accurate Institute of Management and Technology, Greater Noida, as Professor and Head of Mechanical Engineering for two years however, within a short span of one year he progressed and attained the position of the Director. Prior to joining the institute, he also worked as Professor and Head of Mechanical Engineering Department at the College of Engineering and Technology, IILM-AHL. In addition to that, he had also served as Associate Professor, Reader and Assistant Professor at various pioneer Engineering Institutes.

His encyclopaedic knowledge as an academician, blended with astute industrial expertise makes him an exceptional Subject Matter Expert. He is a Ph.D. degree holder in the field of Mechanical Engineering from Technical University of Uttar Pradesh however; the research was carried out at Indian Institute of Technology (BHU), Varanasi. A Master of Engineering degree from Indian Institute of Technology, Roorkee backed by Bachelor of Engineering degree from Karnataka University, Dharwad speaks for itself and augments his extensive knowledge. Along with this, he also holds 76 publications in National and International journals and conferences under his name.

As part of his Industrial experience, Dr. Tiwari worked and gained enormous understanding as a Production Engineer with two renowned companies namely, Tribhuvan Steel Industries Limited, Lucknow and Subhash Projects and Marketing Limited, Delhi.

With this record of affluent Academic and Industry experience, time and again, he’s been conferred crucial roles of the Head Examiner and Observer by various Government Examination bodies. Owing to his virtues such as; Result-Oriented approach, Integrity, Commitment, Creativity and Innovation, his quest and desire for a persistent improvement, through in-depth teaching and perennial monitoring, make him an adored Leader amongst his team members.
                  
                  </p>   */}
          </div>
        </div>
      </>
    </>
  );
};

export default Directorprofile;
