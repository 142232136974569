import React from "react";
import "../../../assets/stylesheets/Thecollege/BoardOfGoverner/BOG.css";
import "../../../assets/stylesheets/Career.css";
import Sidemenuforabout from "../../../components/Sidemenuforabout.js";
import Highlight from "../../Highlight";
import BOGTable from "./BOGTable";

const TableData = {
  Member: [
    "Minister of Technical Education, Government of Uttar Pradesh",
    "Principal Secretary/Secretary Technical Education Department, Government of Uttar Pradesh",
    "Special Secretary, Technical Education Department, Government of Uttar Pradesh",
    "Principal Secretary/Secretary, Finance Department, Government of Uttar Pradesh or his nominee, not below the rank of Special Secretary",
    "Director, Indian Institute of Technology, Kanpur or his nominee not below the rank of Professor",
    "Director, Motilal Nehru National Institute of Technology, Allahabad or his nominee not below the rank of Professor]",
    "Director of Technical Education, Uttar Pradesh,Kanpur",
    "An educationist to be nominated by All India Council for Technical Education",
    "Vice-Chancellor of the University to which the College is affiliated",
    "Two Professor of the Institute. To be nominated by the Board for one year by rotation in the order of seniority",
    "Two eminent persons in the field of Technical Education, to be nominated by the State Government for two years",
    "One eminent person from industry to be nominated by the State Government for two years",
    "One person from SC category to be nominated by the Chairman, Governing Body from amongst reputed teachers/educationalists/Industrialists",
    "One person from OBC Category to be nominated by the Chairman, Governing Body from amongst reputed teachers/educationalists/Industrialists.",
    "Director of the College",
  ],
  Role: [
    "Chairman",
    "Vice-Chairman",
    "Member",
    "Member",
    "Member",
    "Member",
    "Member",
    "Member",
    "Member",
    "Member",
    "Member",
    "Member",
    "Member",
    "Member",
    "Member-Secretary",
  ],
};

const BoardOfGovernersPage = () => {
  return (
    <>
      <Highlight />
      <div>
        <div className="career_container blow563pxToResp resprespresp601to700px resPonsive-at-610-699px">
          <Sidemenuforabout />
          <div className="mginTop_bogs bogsFontSizeReduce mgintop_bogs2 againmarginTop1234">
            <h2>Board of Governors</h2>
            <hr />
            <div>
              <p>
                The Board of Governors form the Governing Body of the college
                society for the general superintendence, direction and control
                of the affairs of the society. The Board of Governors of REC
                Sonbhadra is constituted by the following members:
              </p>
              <div
                style={{ overflow: "auto" }}
                className="AdMiNiStRaTiOn_TaBlE table-widthcssHanDlEr width-handler-359px-455px"
              >
                <BOGTable header1="Governing Body Member" header2="Position/Role" data={TableData} size={TableData.Member.length} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BoardOfGovernersPage;
