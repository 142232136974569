import React from "react";
// import FiberNewIcon from '@material-ui/icons/FiberNew';
import "../../assets/stylesheets/Thecollege/Director.css";
import back from "../../images/Director.png";
import Sidemenuforabout from "../Sidemenuforabout";
import Highlight from "../Highlight";

const Directors = () => {
  return (
    <>
      <Highlight />

      <div className="aboutus_container">
        <Sidemenuforabout />
        <div>
          <h1>Director's Desk</h1>
          <hr />
          <img src={back} alt="sry" className="back_img_director_profile" />
          <div className="department_quote department_quote_director">
            <q style={{ color: "black", fontWeight: "600" }}>
              Education is not preparation for life, education is life itself{" "}
            </q>
            <address style={{ color: "Orange" }}>– John Dewey</address>
            <div style={{ color: "black" }}>
              <h3 style={{ marginBottom: "0" }}>Prof. Geetam Singh Tomar</h3>
            </div>
          </div>
          <p>
            It’s a great honour to be the director of Rajkiya Engineering
            College Sonbhadra, an institute that is ready to brace to capitalize
            on its strong foundations. The college aims at all-round development
            of the students from training them about technical education to
            embellishing them with ethics morality and foresightedness. We also
            strive for the integrated development of individuals, institutions,
            and society at large and are committed to creating an ambiance for
            nurturing innovation, creativity, and excellence within its
            students. The main goal of this institution is to educate the
            students in such a manner so that over a period of time the
            institute may be recognized as one of the reputed leading
            institutions in the field of engineering and technology at the
            national level. I feel eminently happy and prerogative to be a part
            of this splendid educational institution and gratefully acknowledge
            the service of our dedicated faculty and staff members.
          </p>
        </div>
      </div>
    </>
  );
};

export default Directors;
