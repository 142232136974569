import React from "react";
import "../assets/stylesheets/Career.css";
import Sidemenuforabout from "../components/Sidemenuforabout.js";
import Highlight from "./Highlight";

const Alumnicorner = () => {
  return (
    <>
      <Highlight />
      <div>
        {/* <h1 style={{marginBottom:'18%', textAlign:'center'}}>Under Construction</h1> */}

        <div className="career_container" style={{ marginBottom: "10rem" }}>
          <Sidemenuforabout />

          <div className="career_part">
            <h2>Alumni</h2>
            <hr />
            Content will be available soon.
          </div>
        </div>
      </div>
    </>
  );
};

export default Alumnicorner;
