import React from "react";
import "../../assets/stylesheets/facilities/Auditorium.css";
import OA from "../../images/OA.JPG";
import CC2 from "../../images/OpenAir.jpg";
import Highlight from "../Highlight";

export default function Auditorium() {
  return (
    <>
      <Highlight />
      <div className="Contents">
        <div className="BannerAudi">
          <div>
            <h3>
              Open Air Auditorium <br />& <br />
              Seminar Hall
            </h3>
            <div className="line"></div>
          </div>
          <img src={OA} alt="" />
        </div>
        <div className="AudiMain">
          <div className="au-part2">
            <div className="line"></div>
            <h3 className="f">Open Air Auditorium</h3>
            <img
              src={CC2}
              //  hspace="20" vspace="20"
              alt=""
            />{" "}
            <p>
              {/*
               */}
              As the educational system is faculty and student-centric, it's the
              duty of each institute to ensure appropriate standards for the
              Teaching and Learning Process. As an element of this process, the
              students are motivated to match their standards to industrial and
              societal needs. The Open Air Auditorium is that the regulatory
              body governing different student activities at REC Sonbhadra. At
              the apex of this structure is that the Professor responsible
              (Student Affairs) – he's assisted by the school and by various
              Student office bearers, answerable of various activities. Students
              at REC Sonbhadra engage themselves in a large number of
              extra-curricular activities, and a student can find a social group
              corresponding to almost any hobby. State-of-the-art facilities are
              available within the Open Air Auditorium.
              <br />
              Cultural activities are a group of performing and non-performing
              arts, which are go by a group of clubs appreciate different skills
              and interests. The colourful club culture on campus is something
              unique to REC Sonbhadra and helps to nurture and develop talent.
              <br />
            </p>
          </div>

          <div className="au-part2">
            <div className="line"></div>
            <h3 className="f">Seminar Hall</h3>
            <img
              className="seminar-img"
              src={CC2}
              //  hspace="20" vspace="20"
              alt=""
            />{" "}
            <p>
              The college has a seminar hall that has a seating capacity of
              about 100 people. The hall is spacious and properly ventilated. It
              is well equipped with radial light, a professionally installed
              music system, an LCD projector, and screens that can be used
              during presentations or for delivering lectures. Apart from this,
              it is also used for many events held at the campus level. It also
              serves as an examination centre for larger groups and is also
              designed to accommodate certain kinds of gaming activities.
              <br /> Because of its having all the facilities to enhance the
              learning process, the college seminar hall is a regular venue for
              conferences, workshops, and seminars.
              <br />
            </p>
          </div>
        </div>
        <div></div>
      </div>
    </>
  );
}
