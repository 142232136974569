import React from "react";
import { Route, Switch } from "react-router-dom";
import TrainingnPlacement from "../important_cells/TrainingnPlacement";
import WomenGrievance from "../important_cells/WomenGrievance";
import StudentGrievanceCell from "../important_cells/StudentGrievanceCell";
import AntiRaggingCell from "../important_cells/AntiRaggingCell";
import StudentCounsellingCell from "../important_cells/StudentCounsellingCell";
import IncubationAndInnovationCenter from "../important_cells/IncubationAndInnovationCenter";
import ComputerCentre from "../facilities/ComputerCentre";
import Library from "../facilities/Library";

import Facultylogin from "../faculty-dashboard/Facultylogin";
import FacultyInfo from "../faculty-dashboard/FacultyInfo";
import FacultySignup from "../faculty-dashboard/FacultySignup";
import BoardAdmin from "../faculty-dashboard/AdminDashboard";
import ChangePassword from "../faculty-dashboard/changePassword";
import ChangeAdmin from "../faculty-dashboard/changeAdmin";

import ControlPanel from "../faculty-dashboard/ControlPanel";
import Hostel from "../facilities/Hostel";
import Canteen from "../facilities/Canteen";
import Auditorium from "../facilities/Auditorium";
import Workshop from "../facilities/Workshop";
import LANinternet from "../facilities/LANinternet";
import AcademicPrograms from "../academics/AcademicPrograms";
import Admission from "../academics/Admission";
import AcademicCalendar from "../academics/AcademicCalendar";
import FeeStructure from "../academics/FeeStructure";
import Results from "../academics/Results";
import Syllabus from "../academics/Syllabus";
import Downloads from "../Downloads";
import Contact from "../Contact";
import Aboutus from "../Thecollege/Aboutus";
import Directors from "../Thecollege/Directors";
import Vision from "./../Thecollege/Vision";
import Collegegallery from "../life@rec/Collegegallery";
import Studentactivitycenter from "../life@rec/Studentactivitycenter";
import Usefullinks from "../life@rec/Usefullinks";
import Activities from "../life@rec/Activities";
import Directorprofile from "../Thecollege/Directorprofile";
import DepartmentPage from "../Departments/Department";
import Career from "../Career";
import Noticeviewall from "../Noticeviewall";
import Circularviewall from "../Circularviewall";

import AcademicCommittee from "../Thecollege/BOGs/AcademicCommittee";
import AdministrativeCommittee from "../Thecollege/BOGs/AdministrativeCommittee";
import BoardOfGovernersPage from "../Thecollege/BOGs/BoardOfGovernersPage";
import BuildingAndWorksCommittee from "../Thecollege/BOGs/BuildingAndWorksCommittee";
import CentralPurchaseCommittee from "../Thecollege/BOGs/CentralPurchaseCommittee";
import FinanceCommittee from "../Thecollege/BOGs/FinanceCommittee";
import MinutesOfMeetings from "../Thecollege/BOGs/MinutesOfMeetings";
import CollegeByLaws from "../Thecollege/BOGs/CollegeByLaws";
import Alumnicorner from "../Alumnicorner";

import AdmissionRECMirzapur from "../academics/AdmissionRECMirzapur"

import KnowMore from "../KnowMore";

// import RSRCH from "../Research/Research.js"

const Routes = () => {
  return (
    <Switch>
      <Route exact path='/career' component={Career} />
      <Route exact path='/alumni' component={Alumnicorner} />

      <Route exact path='/facultylogin' component={Facultylogin}></Route>
      <Route exact path='/facultyinfo' component={FacultyInfo}></Route>
      <Route
        exact
        path='/admin/createFaculty'
        component={FacultySignup}
      ></Route>
      <Route exact path='/admin' component={BoardAdmin}></Route>
      <Route
        exact
        path='/user/changepassword'
        component={ChangePassword}
      ></Route>
      <Route exact path='/admin/changeadmin' component={ChangeAdmin}></Route>

      <Route exact path='/admin/controlpanel' component={ControlPanel}></Route>

      {/* all the college Routes */}
      {/* <Route exact path="college/aboutus" component={Aboutus}></Route> */}
      {/* <Route exact path="college/director" component={Directors}></Route> */}
      {/* <Route exact path="college/vision" component={Vision}></Route> */}

      <Route exact path='/college/aboutus' component={Aboutus}></Route>
      <Route exact path='/college/director' component={Directors}></Route>
      <Route exact path='/college/vision' component={Vision}></Route>
      <Route
        exact
        path='/college/directorprofile'
        component={Directorprofile}
      ></Route>
      <Route
        exact
        path='/college/administration/academic_committee'
        component={AcademicCommittee}
      ></Route>
      <Route
        exact
        path='/college/administration/AdministrativeCommittee'
        component={AdministrativeCommittee}
      ></Route>
      <Route
        exact
        path='/college/administration/BoardOfGovernersPage'
        component={BoardOfGovernersPage}
      ></Route>
      <Route
        exact
        path='/college/administration/BuildingAndWorksCommittee'
        component={BuildingAndWorksCommittee}
      ></Route>
      <Route
        exact
        path='/college/administration/CentralPurchaseCommittee'
        component={CentralPurchaseCommittee}
      ></Route>
      <Route
        exact
        path='/college/administration/FinanceCommittee'
        component={FinanceCommittee}
      ></Route>
      <Route
        exact
        path='/college/administration/MinutesOfMeetings'
        component={MinutesOfMeetings}
      ></Route>
      <Route
        exact
        path='/college/administration/college_by_laws'
        component={CollegeByLaws}
      ></Route>

      <Route
        exact
        path='/departments/:deptCode'
        component={DepartmentPage}
      ></Route>

      <Route exact path='/departments/:deptCode/:number' component={KnowMore}></Route>

      <Route exact path='/noticesviewall' component={Noticeviewall}></Route>
      <Route exact path='/circularviewall' component={Circularviewall}></Route>

      {/*all facilities Routes */}
      <Route exact path='/facilities/Auditorium' component={Auditorium}></Route>
      <Route exact path='/facilities/library' component={Library}></Route>
      <Route exact path='/facilities/workshop' component={Workshop}></Route>
      <Route
        exact
        path='/facilities/LANinternet'
        component={LANinternet}
      ></Route>
      <Route exact path='/facility/hostel' component={Hostel}></Route>
      <Route exact path='/facility/canteen' component={Canteen}></Route>
      <Route
        exact
        path='/facilities/ComputerCentre'
        component={ComputerCentre}
      ></Route>

      {/* all important cell routes */}
      <Route
        exact
        path='/important_cells/TrainingnPlacement'
        component={TrainingnPlacement}
      ></Route>
      <Route
        exact
        path='/important_cells/WomenGrievance'
        component={WomenGrievance}
      ></Route>
      <Route
        exact
        path='/important_cells/StudentGrievanceCell'
        component={StudentGrievanceCell}
      ></Route>
      <Route
        exact
        path='/important_cells/AntiRaggingCell'
        component={AntiRaggingCell}
      ></Route>
      <Route
        exact
        path='/important_cells/StudentCounsellingCell'
        component={StudentCounsellingCell}
      ></Route>
      <Route
        exact
        path='/important_cells/IncubationAndInnovationCenter'
        component={IncubationAndInnovationCenter}
      ></Route>

      {/* all academics routes */}
      <Route
        exact
        path='/academics/academic-program'
        component={AcademicPrograms}
      ></Route>
      {/* Research Route */}
      {/* <Route path="/Research" component={RSRCH}></Route> */}

      {/* R.I.P Department(विभाग) */}
      {/*<Route path="/Departments" component={Departments}></Route>*/}

      <Route
        exact
        path='/academics/academic-calendar'
        component={AcademicCalendar}
      ></Route>
      <Route exact path='/academics/admission' component={Admission}></Route>
      <Route exact path='/academics/admissionmirza' component={AdmissionRECMirzapur}></Route>
      <Route exact path='/academics/syllabi' component={Syllabus}></Route>
      <Route exact path='/academics/downloads' component={Downloads}></Route>
      <Route
        exact
        path='/academics/fee-structure'
        component={FeeStructure}
      ></Route>
      <Route exact path='/academics/results' component={Results}></Route>

      {/* contact us */}
      <Route exact path='/contactus' component={Contact}></Route>
      {/*..........life@rec..............*/}
      <Route
        exact
        path='/life@rec/Collegegallery'
        component={Collegegallery}
      ></Route>
      <Route
        exact
        path='/life@rec/Studentactivitycenter'
        component={Studentactivitycenter}
      ></Route>
      <Route exact path='/life@rec/Usefullinks' component={Usefullinks}></Route>
      <Route exact path='/life@rec/Activities' component={Activities}></Route>
    </Switch>
  );
};

export default Routes;
