import React from 'react'

const BOGTable = ({header1, header2, data, size}) => {
    return (
        <>
          <table>
            <thead>
              <tr style={{ borderBottom: "1px solid black" }}>
                <th className="BOGstableHeaderwidth">Sr. No.</th>
                <th>{header1}</th>
                <th>{header2}</th>
              </tr>
            </thead>
            <tbody>
              {[...Array(size)].map((e, i) => {
                return (
                  <>
                    <tr>
                      <td>{i+1}.</td>
                      <td>{data.Member[i]}</td>
                      <td>{data.Role[i]}</td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </>
      );
}

export default BOGTable