import React from "react";
import "../../../assets/stylesheets/Career.css";
import Sidemenuforabout from "../../../components/Sidemenuforabout.js";
import Highlight from "../../Highlight";
import BOGTable from "./BOGTable";

const TableData = {
  Member: ["", "", "", ""],
  Role: ["", "", "", "", "", ""],
};

const MinutesOfMeetings = () => {
  return (
    <>
      <Highlight />
      <div>
        <div className="career_container blow563pxToResp resprespresp601to700px resPonsive-at-610-699px">
          <Sidemenuforabout />
          <div className="mginTop_bogs mgintop_bogs2 againmarginTop1234">
            <h2>Minutes of Meetings</h2>
            <hr />
            <div>
              <p>
                Following are the Minutes of Meetings for the meetings of
                various committees of the college held till date:
              </p>
              <h3>Board of Governors:</h3>
              <div
                style={{ overflow: "auto" }}
                className="AdMiNiStRaTiOn_TaBlE"
              >
                <BOGTable
                  header1="Board of Governors Meetings"
                  header2="Date"
                  data={TableData}
                  size={3}
                />
              </div>
            </div>

            <div>
              <h3>Finance Committee:</h3>
              <div
                style={{ overflow: "auto" }}
                className="AdMiNiStRaTiOn_TaBlE"
              >
                <BOGTable
                  header1="Finance Committee Meetings"
                  header2="Date"
                  data={TableData}
                  size={3}
                />
              </div>
            </div>

            <div>
              <h3>Building and Works Committee:</h3>
              <div
                style={{ overflow: "auto" }}
                className="AdMiNiStRaTiOn_TaBlE"
              >
                <BOGTable
                  header1="Building and Works Committee Meetings"
                  header2="Date"
                  data={TableData}
                  size={3}
                />
              </div>
            </div>

            <div>
              <h3>Central Purchase Committee:</h3>
              <div
                style={{ overflow: "auto" }}
                className="AdMiNiStRaTiOn_TaBlE"
              >
                <BOGTable
                  header1="Central Purchase Committee Meetings"
                  header2="Date"
                  data={TableData}
                  size={3}
                />
                </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MinutesOfMeetings;
