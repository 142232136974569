import React from "react";
import { Link } from "react-router-dom";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
import SchoolIcon from "@material-ui/icons/School";
import GetAppIcon from "@material-ui/icons/GetApp";
import BarChartIcon from "@material-ui/icons/BarChart";
import SubjectIcon from "@material-ui/icons/Subject";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";

const Sidemenuforabout = () => {
  const tabStyle = {
    display: "flex",
    alignContent: "center",
    justifyContent: "flex-start",
    padding: "3%",
  };

  const tabs = [
    {
      link: "/college/director",
      icon: <SubjectIcon className="small_icon" />,
      content: "Director's Desk",
    },
    // {
    //   link: "/pdfdownloads/RECS_PROSPECTUS.pdf",
    //   icon: <SchoolIcon className='small_icon' />,
    //   content: "College Prospectus",
    // },
    {
      link: "/career",
      icon: <PeopleAltIcon className="small_icon" />,
      content: "Career",
    },
    {
      link: "/important_cells/TrainingnPlacement",
      icon: <BarChartIcon className="small_icon" />,
      content: "Training & Placement Cell",
    },
    {
      link: "/facilities/library",
      icon: <LocalLibraryIcon className="small_icon" />,
      content: "Library",
    },
    {
      link: "/academics/downloads",
      icon: <GetAppIcon className="small_icon" />,
      content: "Download",
    },
    {
      link: "/pdfdownloads/nirf.pdf",
      icon: <GetAppIcon className="small_icon" />,
      content: "NIRF",
      props: { target: "_blank" },
    },
    {
      link: "/pdfdownloads/DMFFundUtilization.pdf",
      icon: <GetAppIcon className="small_icon" />,
      content: "DMF Fund Utilization",
      props: { target: "_blank" },
    },
  ];

  function displaySideMenu() {
    return tabs.map(({ link, icon, content, props }) => (
      <div key={link}>
        <Link to={link} style={tabStyle} {...props}>
          {icon}
          <span>{content}</span>
        </Link>
      </div>
    ));
  }

  return (
    <div
      className=" SideMenu sidemenu_for_about"
      style={{ maxHeight: "320px" }}
    >
      {displaySideMenu()}
    </div>
  );
};

export default Sidemenuforabout;
