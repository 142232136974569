import React from "react";
import "../../assets/stylesheets/ImpCellsCommon.css";
import "../../assets/stylesheets/StudentGrievanceCell.css";
import Highlight from "../Highlight";
import NotesIcon from "@material-ui/icons/Notes";

import CellsBaseLayout from "../../layouts/CellsBaseLayout";

const StudentGrievanceCell = () => {
  const data = [
    {
      tag: "main-heading",
      heading: "Student Grievance Cell",
      index: 1,
    },
    {
      tag: "content",
      content: (
        <>
          <b>Objective</b>: To provide opportunities for redress of grievances
          of students already enrolled in institute following principle of
          natural justice. It will develop a responsive and accountable attitude
          among stake holders.
        </>
      ),
      index: 2,
    },
    {
      tag: "sub-heading",
      heading: "Structure",
      index: 3,
    },
    {
      tag: "content",
      content: (
        <>
          <table className='studentGrievanceTable' style={{ minWidth: "60%" }}>
            <tr>
              <td colspan='2' className='studentGrievanceTableHeader'>
                <b>Student Grievance Cell</b>
              </td>
            </tr>
            <tr className='rowborder'>
              <td>Chairman</td>
              <td className='colborder'>Dean, Student Welfare</td>
            </tr>
            <tr className='rowborder'>
              <td>Member</td>
              <td rowspan='3' className='colborder'>
                Three faculty members nominated by Director
              </td>
            </tr>
            <tr className='rowborder'>
              <td>Member</td>
            </tr>
            <tr className='rowborder'>
              <td>Member</td>
            </tr>
            <tr className='rowborder'>
              <td>Member</td>
              <td rowspan='2' className='colborder'>
                Two students nominated by Director
              </td>
            </tr>
            <tr className='rowborder'>
              <td>Member</td>
            </tr>
            <tr className='rowborder'>
              <td>Member Secretary</td>
              <td className='colborder'>Chief Proctor</td>
            </tr>
          </table>
        </>
      ),
      index: 4,
    },
    {
      tag: "sub-heading",
      heading: "Functioning of Cell",
      index: 5,
    },
    {
      tag: "content",
      content: {
        tag: "unordered-list",
        list: [
          "Students can write grievance to chairman of cell through mail or letter. Students can also use suggestion box installed at various locations of institute.",
          "Grievance will be entertained by cell within 15 days.",
          "Cell will act on cases supported by sufficient documents and facts.",
          "Cell will ensure that grievance have been properly solved within time limit provided by cell.",
          "The quorum for the meeting including the Chairman shall be 3.",
          "Cell will send report to Director / Higher Authority about each reported and resolved cases.",
        ],
      },
      index: 6,
    },
    {
      tag: "sub-heading",
      heading: "Scope of Grievance",
      index: 7,
    },
    {
      tag: "content",
      content: {
        tag: "unordered-list",
        list: [
          "Academic Matter : All academic and exam related matters.",
          "Financial Matter : All matters related to due.",
          "Other Matter : All matter related to food, transport, sanitation etc.",
        ],
      },
      index: 8,
    },
    {
      tag: "sub-heading",
      heading: "Online Grievance Submission Form",
      index: 9,
    },
    {
      tag: "content",
      content: (
        <div
          style={{
            width: "80%",
            margin: "auto",
            fontSize: "1.2rem",
            textAlign: "justify",
          }}
        >
          <div className='grievance_input_container'>
            <h1
              style={{ fontFamily: "'Klee One', cursive", fontSize: "1.5em" }}
            >
              Submit your Grievance Here <NotesIcon />
            </h1>
            <form style={{ display: "flex", flexDirection: "column" }}>
              <label style={{ display: "Flex", flexDirection: "column" }}>
                Name:
                <input className='grievance_input' type='text' />
              </label>
              <label style={{ display: "Flex", flexDirection: "column" }}>
                Branch:
                <select className='grievance_input'>
                  <option selected value='electronics'>
                    Electronics Engineering
                  </option>
                  <option value='electrical'>Electrical Engineering</option>
                  <option value='computer'>
                    Computer Science and Engineering
                  </option>
                </select>
              </label>
              <label style={{ display: "Flex", flexDirection: "column" }}>
                Year:
                <select className='grievance_input'>
                  <option selected value='1'>
                    1
                  </option>
                  <option value='2'>2</option>
                  <option value='3'>3</option>
                  <option value='4'>4</option>
                </select>
              </label>
              <label style={{ display: "Flex", flexDirection: "column" }}>
                University Roll No.:
                <input className='grievance_input' type='number' />
              </label>
              <label style={{ display: "Flex", flexDirection: "column" }}>
                Email:
                <input className='grievance_input' type='email' />
              </label>
              <label style={{ display: "Flex", flexDirection: "column" }}>
                Type of Grievance:
                <select className='grievance_input'>
                  <option selected value='theft'>
                    Theft
                  </option>
                  <option value='horrible'>Horrible</option>
                </select>
              </label>
              <label style={{ display: "Flex", flexDirection: "column" }}>
                Grievance:
                <textarea className='grievance_textarea' rows='6' />
              </label>
              <div>
                <button className='send_grievance_btn'> Submit</button>
              </div>
            </form>
          </div>
        </div>
      ),
      index: 10,
    },
  ];
  return (
    <>
      <Highlight />
      <CellsBaseLayout data={data} />
    </>
  );
};
export default StudentGrievanceCell;
