import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import NavBar from "./NavBar";
import "../../assets/stylesheets/Facultylogin.css";

import AuthService from "../../services/auth-service";

import CircularProgress from "@mui/material/CircularProgress";

import { NotificationManager } from "react-notifications";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.onChangeOldPassword = this.onChangeOldPassword.bind(this);
    this.onChangeNewPassword = this.onChangeNewPassword.bind(this);

    this.state = {
      oldPassword: "",
      newPassword: "",
      loading: false,
      message: "",
    };

    var currentUser = AuthService.getCurrentUser();
    if (!currentUser) {
      alert("You must be logged in!");
      this.props.history.push("/facultylogin");
    }
  }

  onChangeOldPassword(e) {
    this.setState({
      oldPassword: e.target.value,
    });
  }

  onChangeNewPassword(e) {
    this.setState({
      newPassword: e.target.value,
    });
  }

  handleLogin(e) {
    e.preventDefault();

    this.setState({
      message: "",
      loading: true,
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      AuthService.changePassword(
        this.state.oldPassword,
        this.state.newPassword
      ).then(
        (data) => {
          this.setState({
            message: data.message,
            loading: false,
          });
          AuthService.logout();
          this.props.history.push("/facultylogin");
          NotificationManager.success(this.state.message);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            loading: false,
            message: resMessage,
          });
          NotificationManager.error(this.state.message);
        }
      );
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  render() {
    return (
      <>
        <div className="dashboardGrid">
          <NavBar />
          <div className="contentBox">
            <Form
              className="loginContent"
              onSubmit={this.handleLogin}
              ref={(c) => {
                this.form = c;
              }}
            >
              <div className="loginHeading">Change Password</div>
              <div class="center">
                <Input
                  type="password"
                  className="loginFields"
                  name="password"
                  placeholder="oldPassword"
                  value={this.state.oldPassword}
                  onChange={this.onChangeOldPassword}
                  validations={[required]}
                />

                <Input
                  type="password"
                  className="loginFields"
                  name="password"
                  placeholder="newPassword"
                  value={this.state.newPassword}
                  onChange={this.onChangeNewPassword}
                  validations={[required]}
                />
              </div>
              <button
                className="loginButton loginFields"
                disabled={this.state.loading}
              >
                <span>
                  {this.state.loading ? (
                    <CircularProgress />
                  ) : (
                    "Change Password"
                  )}
                </span>
              </button>

              {this.state.message && (
                <div className="form-group">
                  <div className="alert alert-danger" role="alert">
                    {this.state.message}
                  </div>
                </div>
              )}
              <CheckButton
                style={{ display: "none" }}
                ref={(c) => {
                  this.checkBtn = c;
                }}
              />
            </Form>
          </div>
        </div>
      </>
    );
  }
}
