import React from "react";
import "../assets/stylesheets/HomePage/Home_department.css";
import Computer_science from "../images/Computer_science.png";
import Electronics_icon from "../images/Electronics_icon.png";
import Electrical_icon from "../images/Electrical_icon.png";
import Applied_icon from "../images/Applied_icon.png";

import Carousel from "react-material-ui-carousel";
import { Paper } from "@material-ui/core";
import Slider1 from "../images/Lab.jpeg";
import Slider2 from "../images/ComputerCentreIMG.jpg";
import Code from "../images/Code.png";
import Solar from "../images/Solar-energy.png";
import Tech from "../images/Technology.png";
import Back from "../images/BackgroundImg.jpg";
// import Homecampuslife from './Homecampuslife'
import { useState } from "react";
import Testinomial from "./Testinomial";

function Example(props) {
  return (
    <Carousel>
      <Paper>
        <img alt="sry" src={Slider1} className="d_slide_img" />
      </Paper>
      <Paper>
        <img alt="sry" src={Slider2} className="d_slide_img" />
      </Paper>
    </Carousel>
  );
}

const Home_department = () => {
  const [isActive1, setIsActive1] = useState(true);
  const [isActive2, setIsActive2] = useState(false);
  const [isActive3, setIsActive3] = useState(false);
  const [isActive4, setIsActive4] = useState(false);
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${Back})`,
          zIndex: "-500",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="home_department_selector">
          <div className="clickable">
            <span
              style={{ alignItems: "center", display: "flex", color: "white" }}
            >
              <img src={Code} style={{ width: "7%" }} alt="" />{" "}
              <span
                className={isActive1 ? "activated clickable" : "clickable"}
                onClick={() => {
                  setIsActive1(true);
                  setIsActive2(false);
                  setIsActive3(false);
                  setIsActive4(false);
                }}
              >
                Computer Science & Engineering
              </span>
            </span>
          </div>
          <div className="clickable">
            <span
              style={{ alignItems: "center", display: "flex", color: "white" }}
            >
              <img src={Solar} style={{ width: "7%" }} alt="" />{" "}
              <span
                className={isActive2 ? "activated clickable" : "clickable"}
                onClick={() => {
                  setIsActive1(false);
                  setIsActive2(true);
                  setIsActive3(false);
                  setIsActive4(false);
                }}
              >
                {" "}
                Electronics Engineering
              </span>
            </span>
          </div>
          <div className="clickable">
            <span
              style={{ alignItems: "center", display: "flex", color: "white" }}
            >
              <img src={Tech} style={{ width: "7%" }} alt="" />{" "}
              <span
                className={isActive3 ? "activated clickable" : "clickable"}
                onClick={() => {
                  setIsActive1(false);
                  setIsActive2(false);
                  setIsActive3(true);
                  setIsActive4(false);
                }}
              >
                Electrical Engineering
              </span>
            </span>
          </div>
          <div className="clickable">
            <span
              style={{ alignItems: "center", display: "flex", color: "white" }}
            >
              <img src={Solar} style={{ width: "7%" }} alt="" />{" "}
              <span
                className={isActive4 ? "activated clickable" : "clickable"}
                onClick={() => {
                  setIsActive1(false);
                  setIsActive2(false);
                  setIsActive3(false);
                  setIsActive4(true);
                }}
              >
                Applied Science
              </span>
            </span>
          </div>
        </div>
        <div className="home_department_outer_grid">
          <div>
            <Example />
          </div>
          {isActive1 ? (
            <div className="   home_department_wrapper">
              <div className=" home_department_grid_container">
                <div className="container   ">
                  <img
                    src={Computer_science}
                    className="department_icon"
                    alt=""
                  />
                </div>
                <div className="department_quote">
                  <q style={{ color: "#FDBF00" }}>
                    Computer Science is no more about computers than astronomy
                    is about telescopes.
                  </q>
                  <address style={{ color: "white" }}>
                    — Edsger W. Dijkstra
                  </address>
                </div>
              </div>
              <hr />
              <div className="department_para" style={{ color: "white" }}>
                <p>
                  The Department of{" "}
                  <strong>Computer Science & Engineering</strong> was
                  established in the year 2015 and is now recognized as one of
                  the leading departments in terms of infrastructure and
                  facilities. The department remains committed towards its
                  mission, which is in twofold. One is to provide students with
                  the fundamental knowledge and problem-solving skills in
                  Computer Science required for a fulfilling career. The other
                  is to create and disseminate knowledge to improve education
                  and skills.
                </p>
              </div>
            </div>
          ) : null}
          {isActive2 ? (
            <div className="   home_department_wrapper">
              <div className=" home_department_grid_container">
                <div className="container   ">
                  <img
                    src={Electronics_icon}
                    className="department_icon"
                    alt=""
                  />
                </div>
                <div className="department_quote">
                  <q style={{ color: "#FDBF00" }}>
                    We, in the semiconductor industry, know that only the
                    paranoid survive.{" "}
                  </q>
                  <address style={{ color: "white" }}>— Andy Grove</address>
                </div>
              </div>
              <hr />
              <div className="department_para" style={{ color: "white" }}>
                <p>
                  The Department of <strong>Electronics Engineering</strong> is
                  committed to impart technical education in most efficient
                  manner to its students. It was established in year 2016. The
                  department is equipped with workshops/labs and syllabus for
                  achieving engineering education excellence. It consists of
                  basic instruments, software/simulators for providing the
                  students an industrial environment, which gives an opportunity
                  to learn and explore
                </p>
              </div>
            </div>
          ) : null}

          {isActive3 ? (
            <div className="   home_department_wrapper">
              <div className=" home_department_grid_container">
                <div className="container   ">
                  <img
                    src={Electrical_icon}
                    className="department_icon"
                    alt=""
                  />
                </div>
                <div className="department_quote">
                  <q style={{ color: "#FDBF00" }}>
                    It is not a dream, it is a simple feat of scientific
                    electrical engineering, only expensive — blind,
                    faint-hearted, doubting world! .
                  </q>

                  <address style={{ color: "white" }}>— Nikola Tesla</address>
                </div>
              </div>
              <hr />
              <div className="department_para" style={{ color: "white" }}>
                <p>
                  The department of <strong>Electrical Engineering</strong> was
                  established in 2016. The department`s vision is to produce
                  quality engineers capable of providing sustainable solutions
                  for complex problems and promoting cost effective, environment
                  friendly, indigenous technology for all sections of society
                  and to frame a well-balanced curriculum with emphasis on basic
                  theoretical knowledge as well the requirements of the
                  industry.
                </p>
              </div>
            </div>
          ) : null}
          {isActive4 ? (
            <div className="   home_department_wrapper">
              <div className=" home_department_grid_container">
                <div className="container   ">
                  <img src={Applied_icon} className="department_icon" alt="" />
                </div>
                <div className="department_quote">
                  <q style={{ color: "#FDBF00" }}>
                    There is no such things as applied sciences, only
                    applications of science
                  </q>
                  <address style={{ color: "white" }}>— Louis Pasteur</address>
                </div>
              </div>
              <hr />
              <div className="department_para" style={{ color: "white" }}>
                {/* <center> <h3>Applied Science</h3></center> */}
                <p>
                  The Department of <strong>Applied Science </strong>was
                  established with the mission of generating human resource,
                  expanding human knowledge and benefit society through
                  education on fundamental and Applied aspects of Science,
                  Engineering and Technology in a singularly collegial and
                  interdisciplinary atmosphere. The Department of Applied
                  Sciences comprise multi-disciplinary faculty members at
                  present mainly related to Physics, Chemistry, Mathematics,
                  Humanities, Ecology and Management background to cater the
                  need of multi-disciplinary students at Rajkiya Engineering
                  College,Sonbhadra.
                </p>
              </div>
            </div>
          ) : null}
        </div>
        {/* we will add it later */}
        {/* <Homecampuslife/> */}
      </div>
      <Testinomial />
    </>
  );
};

export default Home_department;
