import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import NavBar from "./NavBar";

import AuthService from "../../services/auth-service";

import CircularProgress from "@mui/material/CircularProgress";

import { NotificationManager } from "react-notifications";

const required = (value) => {
	if (!value) {
		return (
			<div className="alert alert-danger" role="alert">
				This field is required!
			</div>
		);
	}
};

const email = (value) => {
	if (!isEmail(value)) {
		return (
			<div className="alert alert-danger" role="alert">
				This is not a valid email.
			</div>
		);
	}
};

const vusername = (value) => {
	if (value.length < 3 || value.length > 20) {
		return (
			<div className="alert alert-danger" role="alert">
				The username must be between 3 and 20 characters.
			</div>
		);
	}
};

const vpassword = (value) => {
	if (value.length < 6 || value.length > 40) {
		return (
			<div className="alert alert-danger" role="alert">
				The password must be between 6 and 40 characters.
			</div>
		);
	}
};

export default class Register extends Component {
	constructor(props) {
		super(props);
		this.handleRegister = this.handleRegister.bind(this);
		this.onChangeUsername = this.onChangeUsername.bind(this);
		this.onChangeEmail = this.onChangeEmail.bind(this);
		this.onChangePassword = this.onChangePassword.bind(this);

		this.state = {
			username: "",
			email: "",
			password: "",
			successful: false,
			loading: false,
			message: "",
		};
		var currentUser = AuthService.getCurrentUser();
		if (!currentUser || currentUser.roles[0] !== "ROLE_ADMIN") {
			alert("You must be logged in as Admin!");
			this.props.history.push("/facultylogin");
			window.location.reload();
		}
	}

	onChangeUsername(e) {
		this.setState({
			username: e.target.value,
		});
	}

	onChangeEmail(e) {
		this.setState({
			email: e.target.value,
		});
	}

	onChangePassword(e) {
		this.setState({
			password: e.target.value,
		});
	}

	handleRegister(e) {
		e.preventDefault();

		this.setState({
			message: "",
			successful: false,
			loading: true,
		});

		this.form.validateAll();

		if (this.checkBtn.context._errors.length === 0) {
			AuthService.register(
				this.state.username,
				this.state.email,
				this.state.password
			).then(
				(response) => {
					this.setState({
						message: response.data.message,
						successful: true,
						loading: false,
					});
					this.props.history.push("/admin");
					NotificationManager.success(this.state.message);
				},
				(error) => {
					const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();

					this.setState({
						successful: false,
						loading: false,
						message: resMessage,
					});
					NotificationManager.error(this.state.message);
				}
			);
		}
	}

	render() {
		var currentUser = AuthService.getCurrentUser();
		return (
			<>
				<div className="dashboardGrid">
					<NavBar />
					<div className="contentBox">
						<Form
							className="loginContent"
							onSubmit={this.handleRegister}
							ref={(c) => {
								this.form = c;
							}}
						>
							<div className="loginHeading">Sign Up</div>
							{!this.state.successful && (
								<div class="center">
									<Input
										type="text"
										className="loginFields"
										name="username"
										placeholder="Username"
										value={this.state.username}
										onChange={this.onChangeUsername}
										validations={[required, vusername]}
									/>

									<Input
										type="text"
										className="loginFields"
										name="email"
										placeholder="E-mail"
										value={this.state.email}
										onChange={this.onChangeEmail}
										validations={[required, email]}
									/>

									<Input
										type="password"
										className="loginFields"
										name="password"
										placeholder="Password"
										value={this.state.password}
										onChange={this.onChangePassword}
										validations={[required, vpassword]}
									/>

									<button
										className="loginButton loginFields"
										disabled={this.state.loading}
									>
										{this.state.loading ? <CircularProgress /> : "Sign Up"}
									</button>
								</div>
							)}

							{this.state.message && (
								<div className="form-group">
									<div
										className={
											this.state.successful
												? "alert alert-success"
												: "alert alert-danger"
										}
										role="alert"
									>
										{this.state.message}
									</div>
								</div>
							)}
							<CheckButton
								style={{ display: "none" }}
								ref={(c) => {
									this.checkBtn = c;
								}}
							/>
						</Form>
					</div>
				</div>
			</>
		);
	}
}
