import React from "react";
import { useEffect } from "react";
import "../../../assets/stylesheets/Career.css";
import "../../../assets/stylesheets/Thecollege/BoardOfGoverner/BOG.css";
import Sidemenuforabout from "../../../components/Sidemenuforabout.js";
import Highlight from "../../Highlight";
import { useHistory, useLocation } from "react-router-dom";
import BOGTable from "./BOGTable";

const TableData = {
  Member: [
    "The Director of the College",
    "All the Heads of the teaching Departments",
    "Dean (Academics) or in his/her absence, the Registrar",
  ],
  Role: ["Chairman", "Members", "Member-Secretary"],
};

const AcademicCommittee = () => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === "POP") {
        if (
          location.pathname === "/college/administration/academic_committee"
        ) {
          history.push("/");
          window.alert("?");
        }
      }
    });
  }, [history]);

  return (
    <>
      <Highlight />
      <div>
        <div className="career_container blow563pxToResp resprespresp601to700px resPonsive-at-610-699px">
          <Sidemenuforabout />
          <div className="mginTop_bogs mgintop_bogs2 againmarginTop1234">
            <h2>Academics Committee</h2>
            <hr />
            <div>
              <p>
                The Academic Committee of the college consists of the following
                members:
              </p>
              <div
                style={{ overflow: "auto" }}
                className="AdMiNiStRaTiOn_TaBlE"
              >
                <BOGTable header1="Governing Body Member" header2="Position/Role" data={TableData} size={TableData.Member.length} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AcademicCommittee;
