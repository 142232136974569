import React from "react";
import pic1 from "./../../images/galleryimages/pic1.JPG";
import "./../../assets/stylesheets/life@rec/activities.css";
import Highlight from "../Highlight";
import { headingContainer } from "../../elements";
const Activities = () => {
  return (
    <>
      <Highlight />
      {headingContainer({ heading: "Extra-Curricular Activites" })}

      <div className='activityBox'>
        <div className='activityImage'>
          <img src={pic1} style={{ width: "100%" }} alt='sky' />
        </div>
        <div className='activityTextSec'>
          <p>
            The accent of student life and activities on the campus is to
            provide an invigorating and creative environment which promotes
            independent thinking and introspection and leads the young students
            to become more aware of the consequence of their own actions. This
            allows them to weave a pattern of life which equips them to stand up
            to the many pressures of community living, to train them in the
            making of inferences in everyday situations, to help them derive
            more insight into their personal relationships and to arouse in them
            a sensibility of aesthetic experience.Student Activity Centre (SAC)
            is an open auditorium, where most of the extracurricular activities
            are organized. The Students Activity Centre is the nerve centre of
            all student activities on the campus.
          </p>
        </div>
      </div>
    </>
  );
};

export default Activities;
