import React from "react";
import "../.../../../assets/stylesheets/academics/fee.css";
import AcademicFeeStructure from "../../pdfs/FeeStructure2024-25.pdf";
// import MessFeeStructure from "../../pd's/MessFeeDetails_2023-24.pdf";
import MessFeeStructure from "../../pdfs/MessFeeDetails_2024-25.pdf";
import Highlight from "../Highlight";
import Sidemenuforabout from "../Sidemenuforabout";
import { contentContainer, headingContainer } from "../../elements";

const FeeStructure = () => {
  return (
    <>
      <Highlight />
      <div className="aboutus_container">
        <Sidemenuforabout />
        <div>
          {headingContainer({ heading: "Fee Structure" })}
          {contentContainer({
            content: (
              <>
                <div className="fee">
                  <a
                    href={AcademicFeeStructure}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Download Academic Fee Structure
                  </a>
                </div>
                <div className="fee1">
                  <a href={MessFeeStructure} target="_blank" rel="noreferrer">
                    Download Mess fee Structure
                  </a>
                </div>
              </>
            ),
          })}
        </div>
      </div>
    </>
  );
};
export default FeeStructure;
