import React from "react";
import "../../assets/stylesheets/Thecollege/About.css";
import Highlight from "../Highlight";
import Sidemenuforabout from "../Sidemenuforabout";

const Aboutus = () => {
  return (
    <>
      <Highlight />

      <div className="aboutus_container">
        <Sidemenuforabout />
        <div>
          <h1>Rajkiya Engineering College , Sonbhadra</h1>
          <hr />
          <iframe
            title="unique"
            className="back_img"
            src="https://www.youtube.com/embed/yv_ctqaGUkE"
          ></iframe>

          <p style={{ textAlign: "justify" }}>
            Rajkiya Engineering College, Sonbhadra was established by the
            Government of Uttar Pradesh in the year 2015. The college offered
            its first course in Computer Science Engineering programme with an
            annual intake of sixty students. Later in 2016, two new branches,
            Electrical Engineering and Electronics Engineering came up with an
            intake of sixty students each. The academic activities of REC
            Sonbhadra were initiated at KNIT Sultanpur in year 2015. The college
            started functioning on its present campus at Churk, Roberstganj,
            Sonbhadra from the year 2017-18. It is a safe and secure campus.
            Students will find the beautiful and peaceful campus a great place
            to live. The College has a total campus area of 30 acres. This
            includes Administrative block, Academic block, Open Auditorium
            (Student activity centre), hostels, ATM, Canteen. The hallmark of
            the campus is the good facilities which cater to the academic and
            extracurricular interests of the students. The campus is equipped
            with facilities like laboratories, library, workshop, High-Speed
            Internet connection, and other seminar and conference facilities.
          </p>
        </div>
      </div>
    </>
  );
};

export default Aboutus;
