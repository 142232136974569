import * as React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";

import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import CircularProgress from "@mui/material/CircularProgress";

import authHeader from "../../../services/auth-header";
import { API_URL } from "../../../config";

import { NotificationManager } from "react-notifications";

const columns = [
  { id: "serial", label: "S. No.", minWidth: 20 },
  { id: "title", label: "Title", minWidth: 170 },
  { id: "view", label: "View", minWidth: 20, align: "right" },
  { id: "delete", label: "Delete", minWidth: 20, align: "right" },
];

export default function ManagePDF({ type, ...props }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [newsData, setNewsData] = useState([]);
  const [circularData, setCircularData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    axios
      .get(`${API_URL}newscirc/getnews`, { headers: authHeader() })
      .then((res) => {
        setNewsData(res.data);
        console.log(res);
      })
      .catch((err) => console.log(err));
    axios
      .get(`${API_URL}newscirc/getcircular`, {
        headers: authHeader(),
      })
      .then((res) => {
        setCircularData(res.data);
        console.log(res);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleNewsDel = (id) => {
    setLoading(true);
    axios
      .delete(`${API_URL}newscirc/news/${id}`, {
        headers: authHeader(),
      })
      .then((res) => {
        console.log(res);
        setLoading(false);
        setNewsData(res.data.news);
        NotificationManager.success("Deleted Successfully!");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        NotificationManager.error("Something went wrong!");
      });
  };

  const handleCircularDel = (id) => {
    setLoading(true);
    axios
      .delete(`${API_URL}newscirc/circular/${id}`, {
        headers: authHeader(),
      })
      .then((res) => {
        console.log(res);
        setLoading(false);
        setCircularData(res.data.circular);
        NotificationManager.success("Deleted Successfully!");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        NotificationManager.error("Something went wrong!");
      });
  };

  return (
    <>
      <Dialog open={props.open} onClose={props.handleClose}>
        {type === "news" ? (
          <DialogTitle>News & Event Form</DialogTitle>
        ) : (
          <DialogTitle>Notices & Circulars Form</DialogTitle>
        )}
        <DialogContent>
          <Paper sx={{ width: "100%" }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        sx={{
                          top: 57,
                          minWidth: column.minWidth,
                          border: "0px solid #0d1d3c",
                          borderBottom: "1px solid rgba(224, 224, 224, 1)",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {type === "news" ? (
                  <TableBody>
                    {newsData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, idx) => {
                        return (
                          <TableRow hover tabIndex={-1} key={idx}>
                            <TableCell
                              sx={{
                                border: "0px solid #0d1d3c",
                                borderBottom:
                                  "1px solid rgba(224, 224, 224, 1)",
                              }}
                            >
                              {idx + 1}
                            </TableCell>
                            <TableCell
                              sx={{
                                border: "0px solid #0d1d3c",
                                borderBottom:
                                  "1px solid rgba(224, 224, 224, 1)",
                              }}
                            >
                              {row.title}
                            </TableCell>
                            <TableCell
                              sx={{
                                border: "0px solid #0d1d3c",
                                borderBottom:
                                  "1px solid rgba(224, 224, 224, 1)",
                              }}
                            >
                              <IconButton>
                                <Link
                                  style={{ color: "grey" }}
                                  to={{ pathname: row.file_url }}
                                  target="_blank"
                                >
                                  <span>
                                    <PictureAsPdfIcon />
                                  </span>
                                </Link>
                              </IconButton>
                            </TableCell>
                            <TableCell
                              sx={{
                                border: "0px solid #0d1d3c",
                                borderBottom:
                                  "1px solid rgba(224, 224, 224, 1)",
                              }}
                            >
                              <IconButton
                                onClick={() => handleNewsDel(row._id)}
                                disabled={loading}
                              >
                                {loading ? (
                                  <CircularProgress />
                                ) : (
                                  <DeleteOutlinedIcon />
                                )}
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                ) : (
                  <TableBody>
                    {circularData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, idx) => {
                        return (
                          <TableRow hover tabIndex={-1} key={idx}>
                            <TableCell
                              sx={{
                                border: "0px solid #0d1d3c",
                                borderBottom:
                                  "1px solid rgba(224, 224, 224, 1)",
                              }}
                            >
                              {idx + 1}
                            </TableCell>
                            <TableCell
                              sx={{
                                border: "0px solid #0d1d3c",
                                borderBottom:
                                  "1px solid rgba(224, 224, 224, 1)",
                              }}
                            >
                              {row.title}
                            </TableCell>
                            <TableCell
                              sx={{
                                border: "0px solid #0d1d3c",
                                borderBottom:
                                  "1px solid rgba(224, 224, 224, 1)",
                              }}
                            >
                              <IconButton>
                                <Link
                                  style={{ color: "grey" }}
                                  to={{ pathname: row.file_url }}
                                  target="_blank"
                                >
                                  <span>
                                    <PictureAsPdfIcon />
                                  </span>
                                </Link>
                              </IconButton>
                            </TableCell>
                            <TableCell
                              sx={{
                                border: "0px solid #0d1d3c",
                                borderBottom:
                                  "1px solid rgba(224, 224, 224, 1)",
                              }}
                            >
                              <IconButton
                                onClick={() => handleCircularDel(row._id)}
                              >
                                {loading ? (
                                  <CircularProgress />
                                ) : (
                                  <DeleteOutlinedIcon />
                                )}
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              count={type === "news" ? newsData.length : circularData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
