import axios from "axios";
import authHeader from "./auth-header";

import { API_URL } from "../config";

class AuthService {
  login(email, password) {
    return axios
      .post(`${API_URL}users/login`, {
        email,
        password,
      })
      .then((response) => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }
  changePassword(oldPassword, newPassword) {
    return axios
      .post(
        `${API_URL}users/changePassword`,
        {
          oldPassword,
          newPassword,
        },
        { headers: authHeader() }
      )
      .then((response) => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }
  changeAdmin(newAdmin, password) {
    return axios
      .post(
        `${API_URL}users/adminPass`,
        {
          newAdmin,
          password,
        },
        { headers: authHeader() }
      )
      .then((response) => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }
  logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("newUser");
  }
  deleteuser(delUser) {
    return axios.post(
      `${API_URL}users/deleteUser`,
      { delUser },
      { headers: authHeader() }
    );
  }
  register(username, email, password) {
    return axios.post(
      `${API_URL}signup`,
      {
        username,
        email,
        password,
      },
      { headers: authHeader() }
    );
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem("user"));
  }
}

export default new AuthService();
