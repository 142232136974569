import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../assets/stylesheets/FacultyInfo.css";
import UserImage from "../../images/UserImage.png";
import AuthService from "../../services/auth-service";
import authHeader from "../../services/auth-header";
import NavBar from "./NavBar";
import PictureAsPdf from "@mui/icons-material/PictureAsPdf";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";

import { API_URL } from "../../config";

const FacultyInfo = (props) => {
	var currentUser = AuthService.getCurrentUser();
	if (!currentUser) {
		props.history.push("/facultylogin");
		window.location.reload();
	}
	const [loading, setLoading] = useState(false);
	/* Use State */
	const [facultyInfo, setFacultyInfo] = useState({
		firstName: "",
		middleName: "",
		lastName: "",
		dateOfBirth: "",
		phoneNumber: "",
		email: "",
		department: "Applied Sciences",
		post: "Assistant Professor",
		qualifications: "",
		fieldsOfSpecialization: "",
		teachingExperience: "",
		researchExperience: "",
		researchPapers: "",
		booksPublished: "",
		seminarsOrganised: "",
		seminarsAttended: "",
		fellowshipAwarded: "",
		membershipAndAssociations: "",
		phdSupervised: "",
		mtechSupervised: "",
		fdpsAttended: "",
		otherAchievements: "",
		personalPageLink: "",
		resume: "",
	});

	function convertDate(inputFormat) {
		function pad(s) {
			return s < 10 ? "0" + s : s;
		}
		var d = new Date(inputFormat);
		return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join("-");
	}

	const [profilePhoto, setProfilePhoto] = useState(null);
	const [img, setImg] = useState(UserImage);

	useEffect(() => {
		axios
			.get(`${API_URL}faculty/currentfaculty`, { headers: authHeader() })
			.then((res) => {
				let docs = res.data;
				if (docs) {
					localStorage.setItem("newUser", false);
					console.log(localStorage.getItem("newUser"));
					docs.dateOfBirth = convertDate(docs.dateOfBirth);
					console.log(docs);
					return docs;
				} else return facultyInfo;
			})
			.then((facultyInfo) => {
				setFacultyInfo(facultyInfo);
				if (facultyInfo.profilePhoto) {
					setImg(facultyInfo.profilePhoto.file_url);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	console.log(localStorage.getItem("newUser"));

	function submitInfo(e) {
		/* To prevent the default submit behaviour */
		e.preventDefault();
		setLoading(true);
		console.log(facultyInfo);

		const formData = new FormData();
		formData.append("facultyInfo", JSON.stringify(facultyInfo));
		if (profilePhoto) formData.append("profilePhoto", profilePhoto);

		console.log(
			"FORMDATA >> " +
				formData.getAll("facultyInfo") +
				formData.getAll("profilePhoto")
		);

		if (localStorage.getItem("newUser") === null) {
			axios
				.post(`${API_URL}faculty/add`, formData, {
					headers: authHeader(),
				})
				.then((res) => {
					console.log(res);
					setLoading(false);
					window.location.reload();
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			axios
				.post(`${API_URL}faculty/update`, formData, {
					headers: authHeader(),
				})
				.then((res) => {
					console.log(res);
					setLoading(false);
					window.location.reload();
				})
				.catch((err) => {
					console.log(err);
				});
		}
	}

	const handleProfilepic = (file) => {
		setProfilePhoto(file);
		setImg(URL.createObjectURL(file));
	};

	return (
		<>
			<div className="dashboardGrid">
				<NavBar />
				<div className="contentBox">
					<form className="facultyInfoContainer" onSubmit={submitInfo}>
						<div className="basicInfoHeader">
							<div className="facultyLoginHeading">Basic Info</div>
						</div>
						<div className="topContent">
							<div>
								<div className="basicInfo">
									<input
										className="inputTextArea"
										type="text"
										id="firstName"
										placeholder="First Name"
										size="30"
										value={facultyInfo.firstName}
										onChange={(event) => {
											setFacultyInfo({
												...facultyInfo,
												firstName: event.target.value,
											});
										}}
									/>
									<input
										className="inputTextArea"
										type="text"
										id="middleName"
										placeholder="Middle Name"
										size="30"
										value={facultyInfo.middleName}
										onChange={(event) => {
											setFacultyInfo({
												...facultyInfo,
												middleName: event.target.value,
											});
										}}
									/>
									<input
										className="inputTextArea"
										type="text"
										id="lastName"
										placeholder="Last Name"
										size="30"
										value={facultyInfo.lastName}
										onChange={(event) => {
											setFacultyInfo({
												...facultyInfo,
												lastName: event.target.value,
											});
										}}
									/>
									<input
										className="inputTextArea"
										type="date"
										name="DOB"
										id="dateofBirth"
										placeholder="Date of Birth"
										size="30"
										value={facultyInfo.dateOfBirth}
										onChange={(event) => {
											setFacultyInfo({
												...facultyInfo,
												dateOfBirth: event.target.value,
											});
										}}
									/>
									<input
										className="inputTextArea"
										type="number"
										name="phone"
										id="phoneNumber"
										placeholder="Phone"
										size="30"
										value={facultyInfo.phoneNumber}
										onChange={(event) => {
											setFacultyInfo({
												...facultyInfo,
												phoneNumber: event.target.value,
											});
										}}
									/>
									<input
										className="inputTextArea"
										type="email"
										name="email"
										id="email"
										placeholder="E-Mail"
										size="30"
										value={facultyInfo.email}
										onChange={(event) => {
											setFacultyInfo({
												...facultyInfo,
												email: event.target.value,
											});
										}}
									/>
								</div>
							</div>
							<div
								style={{ height: "inherit", borderRight: "1pt solid gray" }}
							/>
							<div className="facultyLoginPhoto">
								<img src={img} alt="" id="profilePhoto" />
								<input
									type="file"
									name="profilePhoto"
									placeholder={profilePhoto ? profilePhoto.name : ""}
									onChange={(event) => handleProfilepic(event.target.files[0])}
									value=""
								/>
							</div>
						</div>
						<div className="additionalInfoHeader">
							<div className="facultyLoginHeading">Additional Info</div>
						</div>
						<div className="bottomContent">
							<div className="inputField">
								<div className="inputCategory">Department</div>
								<div className="inputSlot">
									<select
										className="inputTextArea"
										name="department"
										id="department"
										value={facultyInfo.department}
										onChange={(event) => {
											setFacultyInfo({
												...facultyInfo,
												department: event.target.value,
											});
										}}
									>
										<option value="Applied Sciences">Applied Sciences</option>
										<option value="Computer Science & Engineering">
											Computer Science & Engineering
										</option>
										<option value="Electrical Engineering">
											Electrical Engineering
										</option>
										<option value="Electronics Engineering">
											Electronics Engineering
										</option>
										<option value="Mining Engineering">
											Mining Engineering
										</option>
									</select>
								</div>
							</div>
							<div className="inputField">
								<div className="inputCategory">Post</div>
								<div className="inputSlot">
									<select
										className="inputTextArea"
										name="post"
										id="post"
										value={facultyInfo.post}
										onChange={(event) => {
											setFacultyInfo({
												...facultyInfo,
												post: event.target.value,
											});
										}}
									>
										<option value="Assistant Professor">
											Assistant Professor
										</option>
										<option value="Assistant Professor and Head">
											Assistant Professor and Head
										</option>
										<option value="Associate Professor">
											Associate Professor
										</option>
										<option value="Associate Professor and Head">
											Associate Professor and Head
										</option>
										<option value="Research cum Teaching Fellow">
											Research cum Teaching Fellow
										</option>
										<option value="Guest Faculty">Guest Faculty</option>
									</select>
								</div>
							</div>
							<div className="inputField">
								<div className="inputCategory">Qualifications</div>
								<div className="inputSlot">
									<textarea
										className="spanTextArea"
										id="qualifications"
										placeholder="Your Qualifications here"
										value={facultyInfo.qualifications}
										onChange={(event) => {
											setFacultyInfo({
												...facultyInfo,
												qualifications: event.target.value,
											});
										}}
									/>
								</div>
							</div>
							<div className="inputField">
								<div className="inputCategory">Fields of Specialization</div>
								<div className="inputSlot">
									<textarea
										className="spanTextArea"
										id="fieldOfSpecialization"
										placeholder="Your Specializations here"
										value={facultyInfo.fieldsOfSpecialization}
										onChange={(event) => {
											setFacultyInfo({
												...facultyInfo,
												fieldsOfSpecialization: event.target.value,
											});
										}}
									/>
								</div>
							</div>
							<div className="inputField">
								<div className="inputCategory">Teaching Experience</div>
								<div className="inputSlot">
									<input
										className="inputTextArea"
										type="number"
										name="teachingExperience"
										id="teachingExperience"
										placeholder="(In Years)"
										value={facultyInfo.teachingExperience}
										onChange={(event) => {
											setFacultyInfo({
												...facultyInfo,
												teachingExperience: event.target.value,
											});
										}}
									/>
								</div>
							</div>
							<div className="inputField">
								<div className="inputCategory">Research Experience</div>
								<div className="inputSlot">
									<input
										className="inputTextArea"
										type="number"
										name="researchExperience"
										id="researchExperience"
										placeholder="(In Years)"
										value={facultyInfo.researchExperience}
										onChange={(event) => {
											setFacultyInfo({
												...facultyInfo,
												researchExperience: event.target.value,
											});
										}}
									/>
								</div>
							</div>
							<div className="inputField">
								<div className="inputCategory">Number of Research Papers</div>
								<div className="inputSlot">
									<input
										className="inputTextArea"
										type="number"
										name="researchPapers"
										id="researchPapers"
										placeholder="Research Papers"
										value={facultyInfo.researchPapers}
										onChange={(event) => {
											setFacultyInfo({
												...facultyInfo,
												researchPapers: event.target.value,
											});
										}}
									/>
								</div>
							</div>
							<div className="inputField">
								<div className="inputCategory">Books/Chapters Published</div>
								<div className="inputSlot">
									<input
										className="inputTextArea"
										type="number"
										name="books"
										id="books"
										placeholder="Number Published"
										value={facultyInfo.booksPublished}
										onChange={(event) => {
											setFacultyInfo({
												...facultyInfo,
												booksPublished: event.target.value,
											});
										}}
									/>
								</div>
							</div>
							<div className="inputField">
								<div className="inputCategory">
									Seminars/Workshops/Conferences
								</div>
								<div className="inputSlot">
									<input
										className="inputTextArea"
										type="number"
										name="seminarsOrganised"
										id="seminarsOrganised"
										placeholder="Number Organised"
										value={facultyInfo.seminarsOrganised}
										onChange={(event) => {
											setFacultyInfo({
												...facultyInfo,
												seminarsOrganised: event.target.value,
											});
										}}
									/>
								</div>
							</div>
							<div className="inputField">
								<div className="inputCategory">Seminars/Workshops Attended</div>
								<div className="inputSlot">
									<input
										className="inputTextArea"
										type="number"
										name="seminarsAttended"
										id="seminarsAttended"
										placeholder="Number Attended"
										value={facultyInfo.seminarsAttended}
										onChange={(event) => {
											setFacultyInfo({
												...facultyInfo,
												seminarsAttended: event.target.value,
											});
										}}
									/>
								</div>
							</div>
							<div className="inputField">
								<div className="inputCategory">Fellowship Awarded</div>
								<div className="inputSlot">
									<textarea
										className="spanTextArea"
										placeholder="Fellowship"
										value={facultyInfo.fellowshipAwarded}
										onChange={(event) => {
											setFacultyInfo({
												...facultyInfo,
												fellowshipAwarded: event.target.value,
											});
										}}
									/>
								</div>
							</div>
							<div className="inputField">
								<div className="inputCategory">
									Membership & Association of Academic/Professional Bodies
								</div>
								<div className="inputSlot">
									<textarea
										className="spanTextArea"
										id="membershipAndAssociation"
										placeholder="Memberships and Associations"
										value={facultyInfo.membershipAndAssociations}
										onChange={(event) => {
											setFacultyInfo({
												...facultyInfo,
												membershipAndAssociations: event.target.value,
											});
										}}
									/>
								</div>
							</div>
							<div className="inputField">
								<div className="inputCategory">Ph.D Supervised</div>
								<div className="inputSlot">
									<input
										className="inputTextArea"
										type="number"
										name="phdSupervised"
										id="phdSupervised"
										placeholder="Number Supervised"
										value={facultyInfo.phdSupervised}
										onChange={(event) => {
											setFacultyInfo({
												...facultyInfo,
												phdSupervised: event.target.value,
											});
										}}
									/>
								</div>
							</div>
							<div className="inputField">
								<div className="inputCategory">M.Tech. Supervised</div>
								<div className="inputSlot">
									<input
										className="inputTextArea"
										type="number"
										name="mtechSupervised"
										id="mtechSupervised"
										placeholder="Number Supervised"
										value={facultyInfo.mtechSupervised}
										onChange={(event) => {
											setFacultyInfo({
												...facultyInfo,
												mtechSupervised: event.target.value,
											});
										}}
									/>
								</div>
							</div>
							<div className="inputField">
								<div className="inputCategory">
									FDPs/Refresher Courses/Training Programs Attended/Organised
								</div>
								<div className="inputSlot">
									<textarea
										className="spanTextArea"
										id="fdpsAttended"
										placeholder="FDPs Attended"
										value={facultyInfo.fdpsAttended}
										onChange={(event) => {
											setFacultyInfo({
												...facultyInfo,
												fdpsAttended: event.target.value,
											});
										}}
									/>
								</div>
							</div>
							<div className="inputField">
								<div className="inputCategory">Any Other Achievements</div>
								<div className="inputSlot">
									<textarea
										className="spanTextArea"
										id="otherAchievements"
										placeholder="Achievements other than mentioned above"
										value={facultyInfo.otherAchievements}
										onChange={(event) => {
											setFacultyInfo({
												...facultyInfo,
												otherAchievements: event.target.value,
											});
										}}
									/>
								</div>
							</div>
							<div className="inputField">
								<div className="inputCategory">Personal page link</div>
								<div className="inputSlot">
									<input
										className="inputTextArea"
										type="url"
										name="personalPageLink"
										id="personalPageLink"
										placeholder="http://mypage.com"
										value={facultyInfo.personalPageLink}
										onChange={(event) => {
											setFacultyInfo({
												...facultyInfo,
												personalPageLink: event.target.value,
											});
										}}
									/>
								</div>
							</div>
							<div className="inputField">
								<div className="inputCategory">
									Your Resume
									{facultyInfo.resume && (
										<Link
											style={{ color: "black" }}
											to={{ pathname: facultyInfo.resume }}
											target="_blank"
										>
											<span>
												<PictureAsPdf />
											</span>
										</Link>
									)}
								</div>
								<div className="inputSlot" style={{ width: "fit-content" }}>
									<label className="resumeUploa">
										<input
											type="url"
											className="inputTextArea"
											name="resume"
											onChange={(event) => {
												setFacultyInfo({
													...facultyInfo,
													resume: event.target.value,
												});
											}}
											value={facultyInfo.resume}
											placeholder="Resume Link"
										/>
										{/* <span className="resumeUploadCustom"></span> */}
									</label>
								</div>
							</div>
						</div>
						<input
							type="submit"
							className="updateProfileButton"
							value="Update Profile"
						/>
						{loading && <CircularProgress />}
					</form>
				</div>
			</div>
		</>
	);
};

export default FacultyInfo;
