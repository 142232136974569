import React from "react";
import "../../assets/stylesheets/TrainingnPlacement.css";
import Highlight from "../Highlight";

import CellsBaseLayout from "../../layouts/CellsBaseLayout";

const IncubationAndInnovationCenter = () => {
  const data = [
    {
      tag: "main-heading",
      heading: "Incubation And Innovation Center",
      index: 1,
    },
    {
      tag: "content",
      content: (
        <>
          {" "}
          RECS <b>Incubation and Innovation Centre</b> at{" "}
          <b>Rajkiya Engineering College (REC), Sonbhadra</b> has been
          established, under the guidelines of{" "}
          <b>Dr. A. P. J. Abdul Kalam Technical University, Lucknow</b>. This
          plan aims to establish an Incubation Centre supported by Dr. A. P. J.
          Abdul Kalam Technical University, Lucknow for Opportunity Creation and
          wealth generation while meeting the immediate and future needs of the
          society and industry.
        </>
      ),
      index: 2,
    },
    {
      tag: "content",
      content: (
        <>
          This Incubation Centre will be facilitated by Dr. A. P. J. Abdul Kalam
          Technical University with an Advisory Board of experienced and eminent
          industrialists and an Executive Committee of dedicated directors with
          active participation of Faculty and support staff of the REC,
          Sonbhadra. The college is committed for the recognition and to avail
          the funds from{" "}
          <b>Department of IT & Electronics Government of Uttar Pradesh</b>.
        </>
      ),
      index: 3,
    },
    {
      tag: "sub-heading",
      heading: "Vision",
      index: 4,
    },
    {
      tag: "content",
      content: (
        <b>
          To establish a start-up/self-sustaining business incubation facility
          and innovation ecosystem in the REC, Sonbhadra by developing a robust
          infrastructure and providing conducive policy environment
        </b>
      ),
      index: 5,
    },
    {
      tag: "sub-heading",
      heading: "Mission",
      index: 6,
    },
    {
      tag: "content",
      content: {
        tag: "unordered-list",
        list: [
          "To enhance knowledge and skills of students in generating ideas to solve problems through science and technology",
          "To create an ambience and eco-system in promoting entrepreneurial culture with a high level of ethics communication and leadership qualities",
          "To develop ability and pattern to work creatively and effectively in each member of the college to the betterment of mankind and provide strength to the nation.",
        ],
      },
      index: 7,
    },
    {
      tag: "sub-heading",
      heading: "Needs, Signinficance & Objectives",
      index: 8,
    },
    {
      tag: "content",
      content: {
        tag: "unordered-list",
        list: [
          "To create a culture of generating and promoting innovation and innovating products in REC Sonbhadra along with other institutions in this region.",
          "To facilitate potential entrepreneurship by providing information on entrepreneurial opportunities, programs, policies, and government incentives for setting up entrepreneurial ventures.",
          "To facilitate potential entrepreneurship by providing information on entrepreneurial opportunities, programs, policies, and government incentives for setting up entrepreneurial ventures.",
        ],
      },
      index: 8,
    },
    {
      tag: "content",
      content: <br />,
      index: 9,
    },
    {
      tag: "content",
      content: {
        tag: "unordered-list",
        list: [
          "Invite Idea - Innovation",
          "Incubation - Idea to product Creation",
          "Outcome of Incubation",
          "IPR (patenting)",
          "Company Formation",
          "Turnover",
          "Export Item",
          "Import Substitute Item",
          "Home Consumption Item",
        ],
      },
      index: 10,
    },
    {
      tag: "content",
      content: <br />,
      index: 11,
    },
    {
      tag: "content",
      content: "Thrust Area on new proposed Incubator Center:",
      index: 12,
    },
    {
      tag: "content",
      content: {
        tag: "unordered-list",
        list: [
          "Water resource Manangement",
          "Waste Management",
          "Agriculture and IoT bases Solutions",
          "Mobile and Information Technology",
          "Solutions for challenges of fragrance industries",
          "Robotics & Drone",
          "Cyber Security and IT Services",
          "Energy",
        ],
      },
      index: 13,
    },
  ];
  return (
    <>
      <Highlight />
      <CellsBaseLayout data={data} />
    </>
  );
};

export default IncubationAndInnovationCenter;
