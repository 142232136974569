import React from "react";
import { Link } from "react-router-dom";
import "../assets/stylesheets/AddmissionEnquiryButton.css";

const AdmissionEnquiryButton = () => {
  return (
    <>
      <Link to="/academics/admission">
        <div className="EnquiryButton">Admissions 2024</div>
      </Link>

      {/* <Link to='/academics/admissionmirza'>
        <div className='EnquiryButtonMirza'>REC Mirzapur Admission</div>
      </Link> */}
    </>
  );
};

export default AdmissionEnquiryButton;
