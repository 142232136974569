import React from "react";
import "../../assets/stylesheets/facilities/Hostel.css";
import Highlight from "../Highlight";

export default function Hostel() {
  return (
    <>
      <Highlight />
      <div className="Contents">
        <div className="hstl-banner"></div>
        <div className="hstl-main">
          <div className="hstl-1">
            <div className="line"></div>
            <h3>Hostel</h3>
            <div>
              <div className="boys-hstl">
                <div className="img boys"></div>
                <div className="hstl-content">
                  <h4>Boys-hostel </h4>
                  <p>
                    Two boys hostel with a capacity of 500 students is lodged by
                    the campus. Each room is well furnished with beds, study
                    tables, closets, and laundry service. Each floor has a
                    separate water cooler connected to a water purifier to
                    ensure potable drinking water availability for students and
                    is maintained from time to time. Common rooms are provided
                    with indoor games, television, and kits for outdoor games.
                    Hostel mess runs under student representatives elected by
                    students in a cooperative mode and the food menu follows a
                    strict well-balanced diet and is checked time to time so
                    that every resident gets the required amount of nutrients.
                  </p>
                </div>
              </div>
              <div className="boys-hstl">
                <div className="hstl-content">
                  <h4>Girls-hostel </h4>
                  <p>
                    The campus accommodates a girls hostel with a capacity of
                    250 students. Each room is well furnished with beds, study
                    tables, closets, and laundry service. Each floor has a
                    separate water cooler connected to a water purifier to
                    ensure potable drinking water availability for students and
                    is maintained from time to time. Common rooms are provided
                    with indoor games, televisions, and kits for outdoor games.
                    A badminton court is also present in the middle of the
                    girl's hostel that accommodates all kinds of outdoor games
                    like badminton, volleyball, etc. Mess food is in accordance
                    with a strict well-balanced diet made to ensure the good
                    health of residents and is checked regularly by wardens.{" "}
                  </p>
                </div>
                <div className="img girls"></div>
                {/* <img src={Boys_Hostel_1}/> */}
              </div>
            </div>
          </div>
          <div className="hstl-2">
            <div>
              <h4>Hostel Wardens</h4>
              <ul className="rule">
                <li>
                  <h3>Girls hostel</h3>
                  <ul>
                    <li>
                      <p>
                        {" "}
                        Dr. Bhavana Arora
                        <br />
                        Phone: 8853526208
                        <br />
                        Email: bhavna.arora@recsonbhadra.ac.in
                        <br /> <br />
                      </p>{" "}
                    </li>
                    <li>
                      <p>
                        Er. Kalpana Singh
                        <br />
                        Phone: 9473702575
                        <br />
                        Email: kalpanasingh30@gmail.com
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <h3>Boys hostel-1</h3>
                  <ul>
                    <li>
                      Dr. Arvind Kumar Tiwari <br />
                      Phone: 9540209899
                      <br />
                      Email: arvind.tiwari@recsonbhadra.ac.in
                    </li>
                  </ul>
                </li>
                <li>
                  <h3>Boys hostel-2</h3>
                  <ul>
                    <li>
                      {" "}
                      Dr. Abhinav Gupta
                      <br />
                      Phone: 8081641050
                      <br />
                      Email: abhinavkit87@gmail.com
                      <br /> <br />
                    </li>
                    <li>
                      Mr. Ram Ishwar Vais
                      <br />
                      Phone: 7376265734
                      <br />
                      Email: ramismdhanbad@gmail.com
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
