import React from "react";
import Slider1Croped from "../../images/HomeSliders/Slider1Croped.jpg";
import "../.../../../assets/stylesheets/academics/academicProgram.css";
import Highlight from "../Highlight";
import { headingContainer, contentContainer } from "../../elements";

const AcademicPrograms = () => {
  return (
    <>
      <Highlight />
      {headingContainer({ heading: "Academic Programmes" })}
      <div>
        <img
          // className='academicImg'
          src={Slider1Croped}
          alt='sry'
          style={{
            width: "80vw",
            maxHeight: "300px",
            marginLeft: "10vw",
            marginBottom: "2rem",
          }}
          align='left'
        />
      </div>
      {contentContainer({
        content:
          "Rajkiya Engineering College, Sonbhadra offers 4-year Bachelor's Degree Undergraduate Programme in four disciplines of Engineering viz., Computer Science and Engineering, Electrical Engineering, Electronics Engineering and Mining Engineering. There is a provision for lateral entry in 2nd year of each branch with an additional 10% intake of total seats. B.Tech. Programme in all the three disciplines are offered as per curriculum of Dr. A.P.J. Abdul Kalam Technical University, Lucknow.",
      })}
      {contentContainer({
        content: (
          <>
            <h4 style={{ textAlign: "center" }}>
              Undergraduate B.Tech. Programme (4 years/8 semesters):{" "}
            </h4>
            <br />
            <table className='academic_table'>
              <tbody>
                <tr className='th'>
                  <td className='td'>S.No.</td>

                  <td className='td'>Discipline</td>

                  <td className='td'>Intake</td>
                </tr>

                <tr>
                  <td className='td'>1.</td>
                  <td className='td'>Computer Science and Engineering</td>
                  <td className='td'>60</td>
                </tr>
                <tr>
                  <td className='td'>2.</td>
                  <td className='td'>Electrical Engineering</td>
                  <td className='td'>60</td>
                </tr>
                <tr>
                  <td className='td'>3.</td>
                  <td className='td'>Electronics Engineering</td>
                  <td className='td'>60</td>
                </tr>
                <tr>
                  <td className='td'>4.</td>
                  <td className='td'>Mining Engineering</td>
                  <td className='td'>30</td>
                </tr>
              </tbody>
            </table>
          </>
        ),
      })}
      {contentContainer({
        content:
          "The programmes offered is designed to build the fundamental knowledge and advanced skills among students, thus catering to the industry needs. Exposure to real industry problems is given utmost importance.",
      })}
    </>
  );
};
export default AcademicPrograms;
