import React, { useEffect, useState } from "react";
import axios from "axios";

import authHeader from "../services/auth-header";
import PeopleCard from "./peopleCard";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";

import { API_URL } from "../config";
import { headingContainer } from "../elements";

export default function PeopleLayout({ data }) {
  const { dept, hodText } = data;
  const [peopleCategory, setPeopleCategory] = useState("faculty");

  const [head, setHead] = useState(null);
  const [faculty, setFaculty] = useState([]);
  const [scholars, setScholars] = useState([]);
  const [guests, setGuests] = useState([]);

  // console.log("Head: ", head);
  // console.log("Faculty: ", faculty);
  // console.log("Scholars: ", scholars);
  // console.log("Guests: ", guests);

  useEffect(() => {
    axios
      .get(`${API_URL}faculty?dept=${dept}`, { headers: authHeader() })
      .then((res) => {
        const data = res.data;
        for (let i = 0; i < data.length; i++) {
          if (data[i].post.indexOf("Head") > -1) setHead(data[i]);
          else if (data[i].post.indexOf("Professor") > -1)
            setFaculty((faculty) => [...faculty, data[i]]);
          else if (data[i].post.indexOf("Guest") > -1)
            setGuests((guests) => [...guests, data[i]]);
          else if (data[i].post.indexOf("Research") > -1)
            setScholars((scholars) => [...scholars, data[i]]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [dept]);

  return (
    <>
      {headingContainer({ heading: "People", margin: true })}

      {head && (
        <div className='hodInfoSection'>
          <div className='hodInfoHeading'>HOD's Desk</div>
          <div className='hodInfoTextAndPhoto'>
            <div className='hodInfoText'>{hodText}</div>
            <img
              src={head.profilePhoto.file_url}
              alt=''
              className='hodInfoPhoto'
            />
          </div>
          <div className='hodInfoDesignation'>
            <div className='hodInfoName'>
              {`${head.firstName} ${head.middleName} ${head.lastName}`}
            </div>
            <div className='hodInfoPost'>{head.post}</div>
            <div className='hodInfoPost'>
              <div style={{ display: "flex", alignItems: "center" }}>
                <MailOutlineIcon
                  style={{ color: "#d32f2f", marginRight: "4px" }}
                />
                <a href={`mailto:${head.email}`} style={{ color: "#000000" }}>
                  {head.email}
                </a>
              </div>
            </div>
            <div className='hodInfoPost'>
              <div style={{ display: "flex", alignItems: "center" }}>
                <LocalPhoneRoundedIcon
                  style={{ color: "#f57c00", marginRight: "4px" }}
                />
                +91{head.phoneNumber}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className='departmentPeopleCategorySlider'>
        {peopleCategory === "faculty" ? (
          <div className='peopleCategoryFocused'>Faculty</div>
        ) : (
          <div onClick={() => setPeopleCategory("faculty")}>Faculty</div>
        )}
        {peopleCategory === "scholars" ? (
          <div className='peopleCategoryFocused'>Research Scholars</div>
        ) : (
          <div onClick={() => setPeopleCategory("scholars")}>
            Research Scholars
          </div>
        )}
        {peopleCategory === "guest" ? (
          <div className='peopleCategoryFocused'>Guest Faculty</div>
        ) : (
          <div onClick={() => setPeopleCategory("guest")}>Guest Faculty</div>
        )}
      </div>
      <div
        className='facultyMembersSection'
        style={{
          minHeight: "40vh",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-evenly",
        }}
      >
        {peopleCategory === "faculty" && faculty && faculty.length !== 0 ? (
          faculty.map((data) => <PeopleCard data={data} />)
        ) : peopleCategory === "scholars" &&
          scholars &&
          scholars.length !== 0 ? (
          scholars.map((data) => <PeopleCard data={data} />)
        ) : peopleCategory === "guest" && guests && guests.length !== 0 ? (
          guests.map((data) => <PeopleCard data={data} />)
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
