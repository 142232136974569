import React from "react";
import "../assets/stylesheets/HomePage/Home_about.css";
import Anandi from "../images/Anandi.png";
import Yogi from "../images/Yogi.png";
import Director from "../images/Director.png";
import MinisterTechnicalEducation from "../images/Shri-Ashish-Patel.jpg";

const people = [
  {
    src: Anandi,
    header: "Smt. Anandiben Mafatbhai Patel",
    content: "Hon’ble Chancellor & H.E. Governor of U.P.",
  },
  {
    src: Yogi,
    header: "Shri Yogi Adityanath",
    content: "Hon’ble Chief Minister of U.P.",
  },
  {
    src: MinisterTechnicalEducation,
    header: "Shri Ashish Patel",
    content: "Hon'ble Minister of Technical Education, U.P.",
  },
  {
    src: Director,
    header: "Prof. Geetam Singh Tomar",
    content: "Director Rajkiya Engineering College, Sonbhadra",
  },
];

function peopleCards() {
  return people.map(({ src, header, content }) => (
    <div className="card_div" key={header}>
      <div className="yogi_style">
        <img src={src} alt="" />
        <div>
          <h3>{header}</h3>
          <hr />
          <p>{content}</p>
        </div>
      </div>
    </div>
  ));
}

const aboutContent =
  "Rajkiya Engineering College, Sonbhadra was established by the Government of Uttar Pradesh in the year 2015 offering a 4-year undergraduate B.Tech. programme in Computer Science and Engineering with an annual intake of 60. In the second year of its inception i.e., in 2016 two more branches, Electrical Engineering and Electronics Engineering with an intake of 60 each were started. From the academic session 2023-24, the college has introduced a 4-year B.Tech. programme in Mining Engineering with an intake of 30. The B.Tech. course in all four disciplines is approved by All India Council for Technical Education, New Delhi and affiliated to Dr. A.P.J. Abdul Kalam Technical University, Lucknow.  The fully residential campus of the college consists of two academic buildings, an administrative building, workshop, separate hostels for boys and girls, student activity centre, canteen, health centre, round the clock electricity and water supply and other facilities for recreation.";

const Homeabout = () => {
  return (
    <div className=" home_about_grid_container">
      <div className=" home_about_grid_item_para">
        <h1
          style={{
            backgroundColor: "#1B2945",
            color: "white",
            textAlign: "center",
            marginTop: "0",
            padding: "0.2%",
            boxSizing: "border-box",
          }}
        >
          About College
        </h1>
        <div>
          <center>
            {" "}
            <iframe title="3123" className="videos" src=""></iframe>
          </center>
          <div>
            <p style={{ margin: "0 2%", fontSize: "1rem" }}>{aboutContent}</p>
          </div>
        </div>
      </div>

      <div className="home_about_grid_item_yogi">{peopleCards()}</div>
    </div>
  );
};

export default Homeabout;
