import "./../../assets/stylesheets/life@rec/eca.css";
import React, { useState, useLayoutEffect, useRef } from "react";
import pic5 from "./../../images/galleryimages/pic5.JPG";
import pic6 from "./../../images/galleryimages/pic6.JPG";
import pic8 from "./../../images/galleryimages/pic8.JPG";
import pic11 from "./../../images/galleryimages/pic11.JPG";
import pic12 from "./../../images/galleryimages/pic12.JPG";
import pic13 from "./../../images/galleryimages/pic13.JPG";
import pic14 from "./../../images/galleryimages/pic14.JPG";
import pic21 from "./../../images/galleryimages/pic21.png";
import engineersday from "./../../images/galleryimages/engineersday.jpg";
import republicDay from "./../../images/galleryimages/republicDay.JPG";
import CANTEEN from "./../../images/galleryimages/CANTEEN.png";
import independenceDay from "./../../images/galleryimages/independenceDay.JPG";
import Udbhav_sports from "./../../images/galleryimages/Udbhav_sports.png";
import udbhav_Night from "./../../images/galleryimages/udbhav_Night.png";
import Highlight from "../Highlight";

const Eca = () => {
  let udbhavText =
    "A two-day extravaganza is organized yearly, that touches the lives of all who are involved and visit the fest. A cultural fest is the flagship event of the college and is open to students from all colleges irrespective of their fields/specializations, thus providing a great opportunity to mingle and network with others from various walks of life. It provides the broadest scope to organize a myriad of types of events. Some of them include performing Arts like Group/Solo Singing, Dancing, Dramatics, etc.; Informals like song dedication, musical chairs, treasure hunt, etc.; Gaming Events like volleyball, badminton, relay race, shotput, tug of war, high jump, long jump etc.; Pro-Shows like DJ Nights, Fashion Shows, etc.; and many more. The scope for creativity in organizing events is virtually limitless.";
  let engineersdayText =
    " Every year, the country celebrates September 15 as National Engineer’s Day to appreciate the contributions of Mokshagundam Visvesvaraya. The Bharat Ratna awardee, Visvesvaraya was born on September 15, 1861 in a village called Muddenahalli in Karnataka. He studdied Bachelor of Arts (BA) from the University of Madras and pursued civil engineering at the College of Science in Pune. A Bharat Ratna awardee, Visvesvaraya had studied Bachelor of Arts (BA) from the University of Madras and pursued civil engineering at College of Science in Pune. He later patented and installed an irrigation system with water floodgates at the Khadakvasla reservoir near Pune to raise the food supply level and storage to the highest levels. This was also installed at Gwalior’s Tigra Dam and Mysuru’s Krishnaraja Sagara (KRS) dam, the latter of which created one of the largest reservoirs in Asia at the time";
  let anveshanaText =
    "“Scientists study the world as it is; engineers create the world that has never been.”\
—Theodore von Karman, Hungarian-American mathematician, aerospace engineer, and physicist\
\
REC Sonbhadra celebrated the Engineers’ Day on 15th September, in honour of Bharat Ratna Sir Mokshagundam Visvesvarayya as a tribute to one of the greatest engineers of the country for his outstanding contribution to society. The celebration was attended by a large number of students, faculty and staff members.\
\
The event was initiated with a classical dance performed by the students. It was then followed by various dance performances, skits and mime.";
  let republicDayText =
    "A respectful event is organised on the morning of 26th January every year to celebrate the Republic Day. Decorated by all the students, the building looks appealing to the celebration of the day. The event starts with the flag hoisting by Honourable Director, which is followed by some singing performances and delivery of speech. Patriotic songs are sung by the students to pay tribute to our nation and fighters who fought for our freedom.\
Patriotism binds the diverse people of the country, derived from Latin word which means 'father' refers to the love of one's native country. We people owe everything to our country, and are happiest in serving it.";
  let teachersDayText =
    "Teachers' Day is a special day for the appreciation of teachers, and may include celebrations to honor them for their special contributions in a particular field area, or the community in general. The idea of celebrating Teachers' Day[1] took root in many countries during the 19th century; in most cases, they celebrate a local educator or an important milestone in education. This is the primary reason why countries celebrate this day on different dates, unlike many other International Days. For example, Argentina has commemorated Domingo Faustino Sarmiento's death on 11 September as Teachers' Day since 1915.[2] In India the birthday of the second president Sarvepalli Radhakrishnan (5 September) is celebrated as Teachers' Day since 1962[3] and Guru Purnima traditionally observed as a day to worship teachers by Hindus.According to Hindu scriptures, it is very important to make a spiritual guru (guru), without a spiritual teacher one cannot attain salvation. The identity of a true Spiritual teacher is written in Adhyay 15 Shlok 1 to 4 of Holy Gita.[4] Teacher's Day was celebrated as \"Abhar Diwas\" in 2021. Many countries celebrate their Teachers' Day on 5 October in conjunction with World Teachers' Day, which was established by UNESCO in 1994.[6]";
  let unmeshText =
    "“Unmesh Fresher’s Party” in Rajkiya Engineering College, Sonbhadra is an event which every student eagerly awaits from their time of admission. It is a memorable day in the life of every fresher of B.Tech batch. The fresher’s day is filled with excitement, joy, music, enthusiasm, laughter and happiness. The celebration start at SAC (Auditorium) by our anchors with the warm welcome of all the dignitaries and the jury present in the function. This is follow by the Saraswati pooja and lamp lightening by the Dignitaries. Followed by this the welcome speech is given by students and the fresher are formally welcome into the REC Family, the jury and other guests of honours are also introduce.\
\
Events started with solo singing performances by some of the best singers of first year. After this all the other participants of this great evening come up with their unique ramp walk. They introduce themselves one by one in the allotted time which is 1 min per contestant. All of them are impressive in their own way.\
\
Later on the Fun team took on the stage in their funny way of attracting the audience towards them and adding cherry to the pie. All of them enjoy a lot. Participants come up on the stage with their hidden talent and perform their role. The dance performance is very much exciting to watch.\
\
Finally, the vote of thanks is given by anchors. The entire event is cover by the REC team with their excellent photography, dancing, singing, acting and art skills.";
  let independenceDayText =
    "Independence Day is celebrated annually on 15 August as a national holiday in India commemorating the nation's independence from the United Kingdom on 15 August 1947, the day when the provisions of the 1947 Indian Independence Act, which transferred legislative sovereignty to the Indian Constituent Assembly, came into effect. India retained King George VI as head of state until its transition to a republic, when the nation adopted the Constitution of India on 26 January 1950 (celebrated as Indian Republic Day) and replaced the dominion prefix, Dominion of India, with the enactment of the sovereign law Constitution of India. India attained independence following the Independence Movement noted for largely non-violent resistance and civil disobedience. Independence coincided with the partition of India, in which British India was divided along religious lines into the Dominions of India and Pakistan; the partition was accompanied by violent riots and mass casualties, and the displacement of nearly 15 million people due to religious violence. On 15 August 1947, the first Prime Minister of India, Jawaharlal Nehru raised the Indian national flag above the Lahori Gate of the Red Fort in Delhi. On each subsequent Independence Day, the incumbent Prime Minister customarily raises the flag and gives an address to the nation.[1] The entire event is broadcast by Doordarshan, India's national broadcaster, and usually begins with the shehnai music of Ustad Bismillah Khan. Independence Day is observed throughout India with flag-hoisting ceremonies, parades and cultural events. It is a national holiday.";
  const [textState, setTextState] = useState(udbhavText);
  const [pic, setpic] = useState();

  const modalImageDivRef = useRef();

  const call_me = (p) => {
    // console.log(e)
    setpic(p);
    var doc = document.getElementsByClassName("modalImageContainer");
    doc[0].setAttribute("style", "display:block");
    const modalImageContainer = modalImageDivRef.current;
    modalImageContainer.style.marginTop = `-${height}px`;
  };

  const cross = () => {
    var doc = document.getElementsByClassName("modalImageContainer");
    doc[0].setAttribute("style", "display:none");
  };
  let udbhavHeading = "Udbhav";
  let anveshanaHeading = "Anveshana";
  let engineersDayHeading = "Engineers' Day";
  let teachersDayHeading = "Teachers Day";
  let unmeshHeading = "Unmesh";
  let independenceDayHeading = "Independence Day";
  let republicDayHeading = "Republic Day";

  const [headingState, setHeadingState] = useState(udbhavHeading);

  const [height, setHeight] = useState();

  useLayoutEffect(() => {
    var element = document.getElementById("headerWrapper");
    // var height = window.getComputedStyle(element).getPropertyValue('height');
    setHeight(element.offsetHeight);
    console.log(`height is ${height}`);
  });

  let udbhavBackground = udbhav_Night;
  let teachersDaybackground = Udbhav_sports;
  let unmeshBackground = pic21;
  let independenceDayBackground = independenceDay;
  let anveshanaBackground = CANTEEN;
  let republicDayBackground = republicDay;
  let engineersDayBackground = engineersday;
  const [backgroundState, setBackgroundState] = useState(udbhavBackground);

  return (
    <>
      <Highlight />
      <div className="modalImageContainer" ref={modalImageDivRef}>
        <p class="close" onClick={cross}>
          &times;
        </p>
        <img className="modalImage" src={pic} alt="" />
      </div>

      <div className="gallerytopheader">
        <p className="gallerytopheading">Life@REC</p>
      </div>

      <div className="box">
        <div className="sec1">
          <div
            className="gallerymenuitems"
            onClick={() => {
              setTextState(udbhavText);
              setHeadingState(udbhavHeading);
              setBackgroundState(udbhavBackground);
            }}
          >
            <span>Udbhav</span>
          </div>
          <div
            className="gallerymenuitems"
            onClick={() => {
              setTextState(anveshanaText);
              setHeadingState(anveshanaHeading);
              setBackgroundState(anveshanaBackground);
            }}
          >
            <span>Anveshana</span>
          </div>
          <div
            className="gallerymenuitems"
            onClick={() => {
              setTextState(unmeshText);
              setHeadingState(unmeshHeading);
              setBackgroundState(unmeshBackground);
            }}
          >
            <span>Unmesh</span>
          </div>
          <div
            className="gallerymenuitems"
            onClick={() => {
              setTextState(independenceDayText);
              setHeadingState(independenceDayHeading);
              setBackgroundState(independenceDayBackground);
            }}
          >
            <span>Independance Day</span>
          </div>
          <div
            className="gallerymenuitems"
            onClick={() => {
              setTextState(republicDayText);
              setHeadingState(republicDayHeading);
              setBackgroundState(republicDayBackground);
            }}
          >
            <span>Republic Day </span>
          </div>
          <div
            className="gallerymenuitems"
            onClick={() => {
              setTextState(engineersdayText);
              setHeadingState(engineersDayHeading);
              setBackgroundState(engineersDayBackground);
            }}
          >
            <span>Engineers' Day</span>
          </div>
          <div
            className="gallerymenuitems"
            onClick={() => {
              setTextState(teachersDayText);
              setHeadingState(teachersDayHeading);
              setBackgroundState(teachersDaybackground);
            }}
          >
            <span>Teacher's Day</span>
          </div>
        </div>
        <div
          className="sec2"
          style={{ backgroundImage: `url(${backgroundState})` }}
        >
          <div className="circle">
            <p className="circlehead">Life@REC</p>
            <div className="line"></div>
            <p className="circletxt">
              <span className="tags">"</span>I was born in a University Campus
              and seem to have lived all my life in one campus or another
              <span className="tags">"</span>.
            </p>
            <p className="quotename">- Amartya Sen </p>
          </div>
        </div>
      </div>

      <div className="galleryfestbar">
        <p className="galleryfestname">{headingState}</p>

        <div className="con">
          <p class="txt">{textState}</p>
        </div>
      </div>

      <div className="taron">
        <div className="imageSections">
          <img
            src={pic6}
            style={{ width: "100%" }}
            onClick={() => {
              call_me(pic6);
            }}
          />
        </div>
        <div className="imageSections">
          <img
            src={pic14}
            style={{ width: "100%" }}
            onClick={() => {
              call_me(pic14);
            }}
          />
        </div>
        <div className="imageSections">
          <img
            src={pic11}
            style={{ width: "100%" }}
            onClick={() => {
              call_me(pic11);
            }}
          />
        </div>
        <div className="imageSections">
          <img
            src={pic8}
            style={{ width: "100%" }}
            onClick={() => {
              call_me(pic8);
            }}
          />
        </div>
      </div>
      <div className="taron">
        <div className="imageSections">
          <img
            src={pic12}
            style={{ width: "100%" }}
            onClick={() => {
              call_me(pic12);
            }}
          />
        </div>
        <div className="imageSections">
          <img
            src={pic5}
            style={{ width: "100%" }}
            onClick={() => {
              call_me(pic5);
            }}
          />
        </div>
        <div className="imageSections">
          <img
            src={pic13}
            style={{ width: "100%" }}
            onClick={() => {
              call_me(pic13);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Eca;
