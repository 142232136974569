import React, { useState, useEffect } from "react";
import "../../assets/stylesheets/TrainingnPlacement.css";
import Placements2020 from "../../images/Placements2020.png";
import Highlight from "../Highlight";
import Placements2021Carousel from "./Placements2021Carousel";
import { Link } from "react-router-dom";
import axios from "axios";

import { API_URL } from "../../config";
import CellsBaseLayout from "../../layouts/CellsBaseLayout";

const PlacementRecords = [
  {
    title: "Placement Record Batch of 2024",
    link: "/pdfdownloads/PLACEMENT RECORD BATCH 2024.pdf"
  },
  {
    title: "Placement Record Batch of 2023",
    link: "/pdfdownloads/PLACEMENT RECORD BATCH 2023.pdf",
  },
  {
    title: "Placement Record Batch of 2022",
    link: "/pdfdownloads/PLACEMENT RECORD BATCH 2022 AUGUST 1.pdf",
  },
  {
    title: "Placement Record Batch of 2021",
    link: "/pdfdownloads/PLACEMENT RECORD BATCH 2021.pdf",
  },
];

const TrainingnPlacement = () => {
  const [achiever2021, setAchiever2021] = useState([]);
  const [achiever2022, setAchiever2022] = useState([]);
  const [achiever2023, setAchiever2023] = useState([]);
  const [achiever2024, setAchiever2024] = useState([]);
  useEffect(() => {
    axios.get(`${API_URL}posters/get`).then((res) => {
      let y2021 = res.data.filter((item) => {
        return item.year === 2021;
      });
      let y2022 = res.data.filter((item) => {
        return item.year === 2022;
      });
      let y2023 = res.data.filter((item) => {
        return item.year === 2023;
      });
      let y2024 = res.data.filter((item) => {
        return item.year === 2024;
      });
      setAchiever2021(y2021);
      setAchiever2022(y2022);
      setAchiever2023(y2023);
      setAchiever2024(y2024);
    });
  }, []);
  const data = [
    {
      tag: "main-heading",
      heading: "Training & Placement Cell",
      index: 1,
    },
    {
      tag: "content",
      content:
        "Training and Placement Cell is an integral part of the institute. the present batch of your young budding engineers is working hard to be a part of the large dynamic industry. The Intensive training and the encouraging learning environment of our institute has made the proficient in the basic as well as specialized fields of relevance. The various value-aided cources being imparted at the institute have given adequate exposure to our competent in soft skills too. As T&P Cell Incharge we seek to establish vital link between students and prospective employers to facilitate the training and placement of students as they begin their career. The goal of Taining & Placement Cell is to provide students with a platform for using their potential to gain experience by working in industry, it also acts as the interface between various companies seeking talented young graduates in various disciplines. The Training and Placement Cell plays a crucial role in locating job opportunities for undergraduates passing out from the college by keeping in touch width reputed firms and industrial establishment.",
      index: 2,
    },
    {
      tag: "content",
      content: (
        <>
          <table className='trainingPlacementTable'>
            <tr>
              <td colspan='2' className='studentGrievanceTableHeader'>
                <b>Training &amp; Placement Cell co-ordinators</b>
              </td>
            </tr>
            <tr className='rowborder'>
              <td className='colborder' style={{ textAlign: "center" }}>
                Dr. Bhavna Arora
              </td>
            </tr>
            <tr className='rowborder'>
              <td className='colborder' style={{ textAlign: "center" }}>
                Dr. Arvind Tiwari
              </td>
            </tr>
          </table>
          <br />
        </>
      ),
      index: 3,
    },
    {
      tag: "sub-heading",
      heading: "Our Placement Records",
      index: 4,
    },
    {
      tag: "content",
      content: PlacementRecords.map((record, index) => (
        <div className='downloadsElement' key={index}>
          <div className='downloadsElementTitle'>{record.title}</div>
          <div className='downloadsElementGet'>
            <Link to={record.link} target='_blank'>
              Download
            </Link>
          </div>
        </div>
      )),
      index: 5,
    },
    {
      tag: "sub-heading",
      heading: "Our Achievers of 2024",
      index: 6,
    },  
    {
      tag: "content",
      content: <Placements2021Carousel items={3} posters={achiever2024} />,
      index: 7,
    },
    {
      tag: "sub-heading",
      heading: "Our Achievers of 2023",
      index: 8,
    },  
    {
      tag: "content",
      content: <Placements2021Carousel items={3} posters={achiever2023} />,
      index: 9,
    },
    {
      tag: "sub-heading",
      heading: "Our Achievers of 2022",
      index: 10,
    },  
    {
      tag: "content",
      content: <Placements2021Carousel items={3} posters={achiever2022} />,
      index: 11,
    },
    {
      tag: "sub-heading",
      heading: "Our Achievers of 2021",
      index: 12,
    },
    {
      tag: "content",
      content: <Placements2021Carousel items={3} posters={achiever2021} />,
      index: 13,
    },
    {
      tag: "sub-heading",
      heading: "Our Achievers of 2020",
      index: 14,
    },
    {
      tag: "content",
      content: (
        <img src={Placements2020} alt='' className='tnpPlacementsImage' />
      ),
      index: 15,
    },
  ];

  return (
    <>
      <Highlight />
      <CellsBaseLayout data={data} />
    </>
  );
};
export default TrainingnPlacement;
