import { useEffect, useState } from "react";
import DepartmentIcon from "@material-ui/icons/Apartment";
import PeopleIcon from "@material-ui/icons/People";
import LabIcon from "@material-ui/icons/Search";
import AchievementsIcon from "@material-ui/icons/BarChart";
import ActivitiesIcon from "@material-ui/icons/DirectionsRun";

const DepartmentSideMenu = ({
  about,
  people,
  lab,
  achievements,
  activities,
  deptCode,
}) => {
  const [currentSection, setCurrentSection] = useState("about");

  useEffect(() => {
    setCurrentSection("about");
  }, [deptCode]);

  return (
    <div className='departmentsContainer'>
      <div className=' departmentSideMenu SideMenu sidemenu_for_about'>
        <div
          className='departmentSideMenuItem'
          onClick={() => setCurrentSection("about")}
        >
          <DepartmentIcon />
          <span style={{ margin: "auto .75rem" }}>About The Department</span>
        </div>
        <div
          className='departmentSideMenuItem'
          onClick={() => setCurrentSection("people")}
        >
          <PeopleIcon />
          <span style={{ margin: "auto .75rem" }}>People</span>
        </div>
        <div
          className='departmentSideMenuItem'
          onClick={() => setCurrentSection("lab")}
        >
          <LabIcon />
          <span style={{ margin: "auto .75rem" }}>Laboratory</span>
        </div>
        <div
          className='departmentSideMenuItem'
          onClick={() => setCurrentSection("achievements")}
        >
          <AchievementsIcon />
          <span style={{ margin: "auto .75rem" }}>Achievements</span>
        </div>
        <div
          className='departmentSideMenuItem'
          onClick={() => setCurrentSection("activities")}
        >
          <ActivitiesIcon />
          <span style={{ margin: "auto .75rem" }}>Activities</span>
        </div>
      </div>
      <div className='departmentMainContent'>
        {currentSection === "about" ? (
          about
        ) : currentSection === "people" ? (
          people
        ) : currentSection === "lab" ? (
          lab
        ) : currentSection === "achievements" ? (
          achievements
        ) : currentSection === "activities" ? (
          activities
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default DepartmentSideMenu;
