import "../../assets/stylesheets/ControlPanel.css";

import { useState } from "react";
import NavBar from "./NavBar";
import AuthService from "../../services/auth-service";
import UploadForms from "./controlPanelforms/UploadForms";
import ManagePoster from "./controlPanelforms/ManagePoster";
import ManagePDF from "./controlPanelforms/ManagePDF";

export default function ControlPanel(props) {
  var currentUser = AuthService.getCurrentUser();
  if (!currentUser || currentUser.roles[0] !== "ROLE_ADMIN") {
    alert("You must be logged in as Admin!");
    props.history.push("/facultylogin");
    window.location.reload();
  }
  const [typeA, setTypeA] = useState("");
  const [openA, setOpenA] = useState(false);
  const handleOpenA = (typ) => {
    setTypeA(typ);
    setOpenA(true);
  };
  const handleCloseA = () => setOpenA(false);

  const [openB, setOpenB] = useState(false);
  const handleOpenB = () => setOpenB(true);
  const handleCloseB = () => setOpenB(false);

  const [typeC, setTypeC] = useState("");
  const [openC, setOpenC] = useState(false);
  const handleOpenC = (typ) => {
    setTypeC(typ);
    setOpenC(true);
  };
  const handleCloseC = () => setOpenC(false);

  return (
    <>
      <NavBar />
      <div className="impCellsHeading" id="controlPanelHeading">
        Control Panel
      </div>
      <ManagePDF type={typeC} open={openC} handleClose={handleCloseC} />
      <ManagePoster open={openB} handleClose={handleCloseB} />
      <UploadForms type={typeA} open={openA} handleClose={handleCloseA} />
      <div className="controlPanelContainer">
        <div className="controlPanelUploadSection">
          <div className="impCellsSubheading" style={{ width: "95%" }}>
            Uploads
          </div>
          <div className="controlPanelUploadContents">
            <div
              className="uploadElement"
              onClick={() => {
                handleOpenA("news");
              }}
            >
              News, Events &amp; Highlights
            </div>
            <div
              className="uploadElement"
              onClick={() => {
                handleOpenA("circular");
              }}
            >
              Notices and Circulars
            </div>
            {/* <div className="uploadElement">Downloads</div> */}
            <div
              className="uploadElement"
              onClick={() => {
                handleOpenA("poster");
              }}
            >
              Posters
            </div>
          </div>
        </div>
        <div className="controlPanelManageSection">
          <div className="impCellsSubheading" style={{ width: "95%" }}>
            Manage
          </div>
          <div className="controlPanelManageContents">
            <div
              className="manageElement"
              onClick={() => {
                handleOpenC("news");
              }}
            >
              News, Events &amp; Highlights
            </div>
            <div
              className="manageElement"
              onClick={() => {
                handleOpenC("circular");
              }}
            >
              Notices and Circulars
            </div>
            {/* <div className="manageElement">Downloads</div> */}
            <div
              className="manageElement"
              onClick={() => {
                handleOpenB();
              }}
            >
              Posters
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
