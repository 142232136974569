import React from "react";
import "../../assets/stylesheets/facilities/Workshop.css";
import WorkshopBanner from "../../images/WorkshopBanner.jpg";
import WorkshopBanner1 from "../../images/WorkshopBanner1.jpg";
import WorkshopBanner2 from "../../images/WorkshopBanner2.jpg";
import WorkshopBanner3 from "../../images/WorkshopBanner3.jpg";
import WorkshopBanner4 from "../../images/WorkshopBanner4.jpg";
import WorkshopBanner5 from "../../images/WorkshopBanner5.jpg";
import WorkshopBanner6 from "../../images/WorkshopBanner6.jpg";
import Highlight from "../Highlight";

const Workshop = () => {
  return (
    <>
      <Highlight />
      <div className="Contents">
        <div className="workshopBanner">
          <img src={WorkshopBanner} alt="" />
        </div>

        <div className="workshopMain">
          <div className="work-part2">
            <div className="line"></div>
            <h3>
              <b>Workshop</b>
            </h3>
            <p>
              Our college workshop has been set up in 1000 square meter. The
              main purpose of this lab is to get hands-on knowledge of several
              workshop practices like carpentry, fitting, welding, machining,
              black smithy, sheet metal etc and learn safety regulations to be
              maintained in a shop floor. This laboratory is utilised by 1st and
              2nd semester for all engineering students. Apart from curriculum,
              some additional experimental setups are there which helps the
              students to enhance their knowledge. Students also get opportunity
              to implement their ideas through various application oriented
              micro projects.
              <br />
              The facilities available in Workshop are:
            </p>
            <ul className="workshop-list">
              <li>Carpentry Shop </li>
              <li>Fitting/Bench working Shop</li>
              <li>Welding Shop</li>
              <li>Foundry Shop</li>
              <li>Black smithy Shop</li>
              <li>Machine Shop</li>
              <li>Sheet Metal Shop</li>
            </ul>
          </div>
        </div>

        <div className="work-part4">
          <div className="line"></div>

          <h3>
            <b>Photo Gallery</b>
          </h3>

          <div className="container property">
            <img src={WorkshopBanner3} alt="" />

            <img src={WorkshopBanner2} alt="" />

            <img src={WorkshopBanner6} alt="" />

            <img src={WorkshopBanner4} alt="" />

            <img src={WorkshopBanner5} alt="" />

            <img src={WorkshopBanner1} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};
export default Workshop;
