import React from "react";
import Highlight from "../Highlight";
import { unorderedList } from "../../elements";
import CellsBaseLayout from "../../layouts/CellsBaseLayout";

const AntiRaggingCell = () => {
  const importantAntiRaggingContacts = [
    {
      srno: "1.",
      name: "Prof. G. S. Tomar",
      designation: "Director",
      contact: "7839454220",
    },
    {
      srno: "2.",
      name: "Dr. Devendra Kumar Tripathi",
      designation: "Dean, Student Welfare",
      contact: "9839557481",
    },
    {
      srno: "3.",
      name: "Dr. Himanshu Katiyar",
      designation: "Dean, Academics",
      contact: "9956956574",
    },
    {
      srno: "4.",
      name: "Dr. Harish Chandra Upadhyay",
      designation: "Chairman, Anti Ragging Squad",
      contact: "9415164409",
    },
    {
      srno: "5.",
      name: "Dr. Raj Kumar Patel",
      designation: "Deputy Dean, Student Welfare",
      contact: "8953789305",
    },
  ];
  const data = [
    {
      tag: "main-heading",
      heading: "Anti-Ragging Cell",
      index: 1,
    },
    {
      tag: "content",
      content:
        "In pursuance to UGC Regulations on “Curbing the Menace of Ragging in Higher Educational Institutions, 2009” communicated by MHRD, the following bodies have been constituted by REC Sonbhadra.",
      index: 2,
    },
    {
      tag: "content",
      content: {
        tag: "unordered-list",
        list: ["Anti-Ragging Committee", "Anti-Ragging Squad"],
      },
      index: 3,
    },
    {
      tag: "sub-heading",
      heading: "Functions of Anti-Ragging Committee",
      index: 4,
    },
    {
      tag: "content",
      content:
        "As per Clause 6.3(b) of the UGC Regulations on Curbing the Menace of Ragging in Higher Educational Institutions, 2009, it shall be the duty of the Anti-Ragging Committee to ensure compliance with the provisions of these:",
      index: 5,
    },
    {
      tag: "content",
      content: {
        tag: "unordered-list",
        list: [
          "Regulations as well as the provisions of any law for the time being in force concerning ragging; and also to monitor and oversee the performance of the Anti-Ragging Squad in prevention of ragging in the institution.",
          "To take administrative action in the event of ragging as per Clause 9.1(a) and (b) of the UGC Regulations on Curbing the Menace of Ragging in Higher Educational Institutions,2009.",
        ],
      },
      index: 6,
    },
    {
      tag: "sub-heading",
      heading: "Functions of Anti-Ragging Squad",
      index: 7,
    },
    {
      tag: "content",
      content:
        "As per the UGC Regulations on Curbing the Menace of Ragging in Higher Educational Institutions, 2009 the following shall be the functions of Anti - Ragging Squad:",
      index: 8,
    },
    {
      tag: "content",
      content: {
        tag: "unordered-list",
        list: [
          "To maintain vigil, oversight, undertake patrolling functions and to remain mobile, alert and active always in the Institute.",
          "To make surprise raids in the concerned hostels and other vulnerable places where students generally visit and where either the incidents of ragging have occurred or which are potentially prone to ragging.",
          "To conduct an on-the-spot enquiry into any incident of ragging referred to by the Director or any member of the faculty or any-member of the staff or any student or any parent or guardian or any employee of a service provider or by any other person, as the case may be",
          "To submit enquiry report along with recommendations to the Anti-Ragging Committee for action under Clause 9.1(a) of the UGC Regulations.",
        ],
      },
      index: 9,
    },
    {
      tag: "content",
      content:
        "The Anti-Ragging Squad shall conduct such enquiry observing a fair and transparent procedure and the principles of natural justice and after giving adequate opportunity to the student or students accused of ragging and other witnesses to place before it the facts, documents and views concerning the incident of ragging, and considering other relevant information as may be required.",
      index: 10,
    },
    {
      tag: "content",
      content:
        "The procedure for handling issues of ragging will be as follows:",
      index: 11,
    },
    {
      tag: "content",
      content: {
        tag: "unordered-list",
        list: [
          <>
            The information on ragging can be received in the following manner-
            {unorderedList([
              "Through the notified contact details of Chief Proctor, Student Advisor and Chairman of Anti-Ragging Squad, on ragging for necessary relief in terms of the provisions of the UGC Regulations.",
              "Through any other member of the Institute/ From any external source.",
              "Through the Anti-Ragging Squad of REC Sonbhadra.",
            ])}
          </>,
          "In the event of receipt of information of ragging by any of the officers mentioned at (i) above, he/she will promptly alert/inform the Chairman of the Anti-Ragging Squad of the Institute or any of its members. The activity shall be completed, at the most, within one hour of receipt of this information.",
          "The Anti-Ragging Squad of the Institute shall promptly conduct a preliminary on the spot enquiry and collect details of the incident as available prima facie. The preliminary investigation of the incident shall be immediately brought to the notice of the Director by Anti-Ragging Squad of the Institute. The activity shall be completed, at the most, within twelve hours of receipt of information.",
          "The Director on receipt of preliminary report on ragging, shall, in terms of provisions contained at Clause 7.0 of the UGC Regulations, determine as to whether, prima facie, FIR needs to be lodged and shall order accordingly.",
          "The Anti-Ragging Squad of the Institute shall promptly conduct enquiry into the incident as per provisions laid down in Clause 6.3(e) of the UGC Regulations.",
          "The Anti-Ragging Squad of the Institute shall complete the enquiry and submit its report along with recommendations to the Director and Chairman of the Anti-Ragging Committee of the Institute within six days of the incident.",
          "Thereafter, the said report and recommendations shall be considered by the Anti-Ragging committee for deciding the punishment on the erring students in terms of provisions contained at Clause 9.1 of the UGC Regulations.",
        ],
      },
      index: 12,
    },
    {
      tag: "content",
      content:
        "In terms of provisions contained in the UGC Regulations, the Anti-Ragging Committee of the Institute, headed by the Director is the only body empowered to impose punishment on the erring students found indulged in ragging.",
      index: 13,
    },
    {
      tag: "sub-heading",
      heading: "Important Contact Numbers",
      index: 14,
    },
    {
      tag: "content",
      content: (
        <>
          <table className="trainingPlacementTable" style={{ minWidth: "70%" }}>
            <tr>
              <td className="studentGrievanceTableHeader">
                <b>Sr.No.</b>
              </td>
              <td className="studentGrievanceTableHeader">
                <b>Name</b>
              </td>
              <td className="studentGrievanceTableHeader">
                <b>Designation</b>
              </td>
              <td className="studentGrievanceTableHeader">
                <b>Contact No.</b>
              </td>
            </tr>
            {importantAntiRaggingContacts.map((row, index) => (
              <tr key={index}>
                <td>{row.srno}</td>
                <td>{row.name}</td>
                <td>{row.designation}</td>
                <td>{row.contact}</td>
              </tr>
            ))}
          </table>
        </>
      ),
      index: 15,
    },
  ];
  return (
    <>
      <Highlight />
      <CellsBaseLayout data={data} />
    </>
  );
};
export default AntiRaggingCell;
