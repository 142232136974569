import React from "react";
import "../../../assets/stylesheets/Career.css";
import Sidemenuforabout from "../../../components/Sidemenuforabout.js";
import Highlight from "../../Highlight";
import BOGTable from "./BOGTable";

const TableData = {
  Member: [
    "Principal Secretary, Technical Education Department, Govt. of Uttar Pradesh",
    "Director of the College",
    "A representative of Finance Department of the State Government not below the rank of Joint Secretary",
    "Director of Technical Education, Uttar Pradesh",
    "One other member of the board to be nominated by the Chairman, Board of Governors",
    "Registrar of the College",
  ],
  Role: [
    "Chairman",
    "Vice-Chairman",
    "Member",
    "Member",
    "Member",
    "Member-Secretary",
  ],
};

const AdministrativeCommittee = () => {
  return (
    <>
      <Highlight />
      <div>
        <div className="career_container blow563pxToResp resprespresp601to700px resPonsive-at-610-699px">
          <Sidemenuforabout />
          <div className="mginTop_bogs mgintop_bogs2 againmarginTop1234">
            <h2>Administrative Committee</h2>
            <hr />
            <div>
              <p>
                The Administrative Committee of the college consists of the
                following members:
              </p>
              <div
                style={{ overflow: "auto" }}
                className="AdMiNiStRaTiOn_TaBlE"
              >
                <BOGTable header1="Governing Body Member" header2="Position/Role" data={TableData} size={TableData.Member.length} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdministrativeCommittee;
