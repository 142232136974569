import { useState } from "react";
import axios from "axios";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import Card from "@mui/material/Card";
import { CardActionArea } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";

import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import CircularProgress from "@mui/material/CircularProgress";

import { Document, Page } from "react-pdf/dist/umd/entry.webpack";

import authHeader from "../../../services/auth-header";
import { API_URL } from "../../../config";

import { NotificationManager } from "react-notifications";

function UploadForms({ type, ...props }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const [pdf, setPDF] = useState(null);
  const [file, setFile] = useState(null);

  const [title, setTitle] = useState("");
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const [year, setYear] = useState("");
  const [isPopUp, setisPopUp] = useState(false);
  const [featured, setFeatured] = useState(false);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }
  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }
  function previousPage() {
    changePage(-1);
  }
  function nextPage() {
    changePage(1);
  }

  const clearStates = () => {
    setPDF(null);
    setFile(null);
    setTitle("");
    setMsg("");
    setisPopUp(false);
    setFeatured(false);
    setYear("");
    setLoading(false);
    setNumPages(null);
    setPageNumber(1);
  };
  const handleSubmit = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("title", title);
    formData.append("type", type);
    formData.append("file", file);

    if (type === "poster") {
      formData.append("year", year);
      formData.append("isPopUp", isPopUp);
      formData.append("featured", featured);
    }

    console.log(formData);

    if (type === "news" || type === "circular") {
      axios
        .post(`${API_URL}newscirc/upload`, formData, {
          headers: authHeader(),
        })
        .then((res) => {
          console.log(res);
          setLoading(false);
          if (res.status === 200) {
            setMsg("File Uploaded Successfully");
            NotificationManager.success("File Uploaded Successfully");
          } else {
            setMsg("File Upload Failed!");
            NotificationManager.error("File Upload Failed!");
          }
          setTimeout(clearStates, 3000);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setMsg("Upload Failed!");
          NotificationManager.error("Upload Failed!");
          setTimeout(clearStates, 3000);
        });
    } else if (type === "poster") {
      axios
        .post(`${API_URL}posters/add`, formData, {
          headers: authHeader(),
        })
        .then((res) => {
          console.log(res);
          setLoading(false);
          if (res.status === 200) {
            setMsg("File Uploaded Successfully");
            NotificationManager.success("File Uploaded Successfully");
          } else {
            setMsg("File Upload Failed!");
            NotificationManager.error("File Upload Failed!");
          }
          setTimeout(clearStates, 3000);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setMsg("Upload Failed!");
          NotificationManager.error("Upload Failed!");
          setTimeout(clearStates, 3000);
        });
    }
  };

  const handleChange = (e) => {
    if (e.target.name === "title") setTitle(e.target.value);
    else if (e.target.name === "upload-file") {
      setFile(e.target.files[0]);
      setPDF(URL.createObjectURL(e.target.files[0]));
    }
    if (type === "poster" && e.target.name === "isPopUp")
      setisPopUp(e.target.checked);
    else if (type === "poster" && e.target.name === "featured")
      setFeatured(e.target.checked);
    else if (type === "poster" && e.target.name === "year")
      setYear(e.target.value);
    console.log(e.target);
  };

  return (
    <div>
      <Dialog open={props.open} onClose={props.handleClose}>
        {type === "news" ? (
          <DialogTitle>News & Event Form</DialogTitle>
        ) : type === "circular" ? (
          <DialogTitle>Notices & Circulars Form</DialogTitle>
        ) : (
          <DialogTitle>Poster Upload Form</DialogTitle>
        )}
        <DialogContent style={{ padding: "25px" }}>
          <DialogContentText></DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs container direction="column" spacing={2}>
                  <TextField
                    id="outlined-helperText"
                    name="title"
                    value={title}
                    label="Title"
                    margin="dense"
                    helperText="Enter File Title"
                    onChange={handleChange}
                  />
                  <TextField
                    name="upload-file"
                    type="file"
                    margin="dense"
                    onChange={handleChange}
                  />
                  {type === "poster" && (
                    <>
                      <TextField
                        id="outlined-helperText"
                        name="year"
                        value={year}
                        type="text"
                        margin="dense"
                        label="Pass Batch Year"
                        onChange={handleChange}
                        helperText=""
                      />
                      <div>
                        Show this Poster on Achievers Section of Home Page
                        <Switch
                          checked={featured}
                          onChange={handleChange}
                          name="featured"
                          size="small"
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </div>
                      <div>
                        Show this Poster on Pop Up of Home Page
                        <Switch
                          checked={isPopUp}
                          onChange={handleChange}
                          name="isPopUp"
                          size="small"
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </div>
                    </>
                  )}
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    {loading ? <CircularProgress /> : "Submit"}
                  </Button>
                  <p>{msg}</p>
                </Grid>
              </Grid>
            </Grid>
            {type === "poster"
              ? file !== null && (
                  <Grid item>
                    <Card sx={{ maxWidth: 100 }}>
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          height="140"
                          image={pdf}
                          alt=""
                        />
                        <CardContent>
                          <Typography gutterBottom variant="h6" component="div">
                            {file.name}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                )
              : file !== null && (
                  <Grid item>
                    <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
                      <Page scale={0.3} pageNumber={pageNumber} />
                    </Document>
                    <div>
                      <p>
                        Page {pageNumber || (numPages ? 1 : "--")} of{" "}
                        {numPages || "--"}
                      </p>
                      <IconButton
                        aria-label="prev"
                        size="small"
                        disabled={pageNumber <= 1}
                        onClick={previousPage}
                      >
                        <ArrowCircleLeftOutlinedIcon fontSize="small" />
                      </IconButton>
                      ;
                      <IconButton
                        aria-label="next"
                        size="small"
                        disabled={pageNumber >= numPages}
                        onClick={nextPage}
                      >
                        <ArrowCircleRightOutlinedIcon fontSize="small" />
                      </IconButton>
                      ;
                    </div>
                  </Grid>
                )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default UploadForms;
