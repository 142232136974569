import Placements2021Carousel from "./important_cells/Placements2021Carousel";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../config";
import { headingContainer } from "../elements";

import "../assets/stylesheets/HomePlacementsCarousel.css";

const HomePlacementsCarousel = () => {
  const [student, setStudent] = useState([]);
  useEffect(() => {
    axios.get(`${API_URL}posters/get?featured=true`).then((res) => {
      setStudent(res.data);
    });
  }, []);
  return (
    <div className='homePlacementsCarousel'>
      {headingContainer({ heading: "Our Achievers" })}
      <Placements2021Carousel items={4} posters={student} />
    </div>
  );
};

export default HomePlacementsCarousel;
