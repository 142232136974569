import React from "react";
import "../.../../../assets/stylesheets/academics/result.css";
import uptu_logo from "../../images/uptu_logo.png";
import Highlight from "../Highlight";
import { contentContainer, headingContainer } from "../../elements";

const Results = () => {
  return (
    <>
      <Highlight />
      {headingContainer({ heading: "Results" })}
      {contentContainer({
        content: (
          <>
            <div id='container'>
              <div>
                <img className='result_img' src={uptu_logo} alt='sry' />
              </div>
              <a
                id='result_link'
                href='https://erp.aktu.ac.in/webpages/oneview/oneview.aspx'
              >
                Click here to see your result
              </a>
            </div>
          </>
        ),
      })}
    </>
  );
};
export default Results;
