import React from "react";
import "../../assets/stylesheets/facilities/LANinternet.css";
import Wifibanner from "../../images/Wifibanner.jpg";
import Wifi_Logo from "../../images/Wifi_Logo.png";
import Highlight from "../Highlight";

export default function LANinternet() {
  return (
    <>
      <Highlight />
      <div className="Contents">
        <div className="inbanner">
          <div>
            <div>
              <img className="wifi-icon" src={Wifi_Logo} alt="" />
              <h2>24X7 Internet</h2>
            </div>
            <div className="ine"></div>
          </div>
          <img src={Wifibanner} alt="" />
        </div>
        <div className="LANmain">
          {/* <div className="LAN-part1">
                        <ul className="xyz">
                            <li className="LAN-link">General info</li>
                            <li className="LAN-link">Internet facilities</li>
                            <li className="LAN-link">Contact</li>
                        </ul>

                    </div> */}
          <div className="LAN-part2">
            <div className="ine"></div>
            <h2>
              <b>Internet & Wi-Fi</b>
            </h2>
            <p>
              There are two equalizers in life: the Internet and education. We
              are all now connected by the Internet like neurons in a giant
              brain. Broadband connection is changing the way students learn and
              educators teach. Students and faculty at our campus now enjoy the
              flexibility to access web and exchange resourceful information
              from anywhere within campus. Apart from the computer laboratories
              setup for syllabus programs the Institutes have provided computer
              centers for general purposes and learning during extra hours. This
              includes Internet Facilities available through leased line and
              Wi-Fi for providing continuous and uninterrupted Internet
              connectivity to students and faculty members. These facilities are
              also available to students beyond college hours for preparation of
              seminars & projects and research-orientated work. The Internet
              connectivity is available to faculty and students in the general
              library too. The entire network is behind powerful firewall and
              the network traffic is scanned at the gateway level for threats
              and viruses. The entire campus including the hostels is Wi-Fi
              enabled and students can access the Internet on their laptops
              round the clock.
              <br />
              • 100 Mbps Broadband
              <br />
              • Wi-Fi facility 24 hours in campus
              <br />
              • Internet facilities in all the Computer Labs.
              <br />
            </p>
          </div>
        </div>
        <div>
          {/* 
                    <div className="LAN-part4">
                        <div className="quotes"><h3>"Internet service available"</h3>
                        </div>
                        <div className="ine"></div>
                    </div> */}
        </div>
      </div>
    </>
  );
}
