import React from "react";
import "../assets/stylesheets/Contact.css";
import CallIcon from "@material-ui/icons/Call";
import EmailIcon from "@material-ui/icons/Email";
import LanguageIcon from "@material-ui/icons/Language";
import MessageIcon from "@material-ui/icons/Message";
import NotesIcon from "@material-ui/icons/Notes";
import Highlight from "./Highlight";

const Contact = () => {
  return (
    <>
      <Highlight />
      <div className="contact_wrapper">
        <div>
          <h1 className="contact_us_heading" style={{ padding: "1%" }}>
            Contact us
          </h1>
        </div>

        <div className=" contact_grid_container ">
          <div className="contact_grid_item">
            <h5
              style={{
                margin: "0",
                fontFamily: "'Klee One', cursive",
                fontSize: "larger",
              }}
            >
              Rajkiya Engineering College, Churk, Sonbhadra, Uttar Pradesh -
              231206.
            </h5>
            <div className="basic_contact">
              <span style={{ display: "flex" }}>
                <CallIcon />
                <span>Phone: 05444-252002</span>
              </span>
              <span style={{ display: "flex" }}>
                <MessageIcon />
                <span>Fax: 05444-252002</span>
              </span>
              <span style={{ display: "flex" }}>
                <EmailIcon />
                <span>E-mail: contact@recsonbhadra.ac.in</span>
              </span>
              <span style={{ display: "flex" }}>
                <LanguageIcon />
                <span>College Website: http://recsonbhadra.ac.in</span>
              </span>
            </div>
            <h5
              className="heading_contact"
              style={{ margin: "0", borderBottom: "1px solid black" }}
            >
              For Admission/Registration related queries:
            </h5>
            <div className="basic_contact">
              <span style={{ display: "flex" }}>
                <CallIcon />
                <span>
                  Contact Nos.: +91-9415164409, +91-7379070858, +91-9792925104
                </span>
              </span>
            </div>
            <h5
              className="heading_contact"
              style={{ margin: "0", borderBottom: "1px solid black" }}
            >
              Dean Academics Office:
            </h5>
            <div className="basic_contact">
              <span style={{ display: "flex" }}>
                <CallIcon />
                <span>Contact No.: +91-9956956574</span>
              </span>
              <span style={{ display: "flex" }}>
                <EmailIcon />
                <span>Email: deanac@recsonbhadra.ac.in</span>
              </span>
            </div>
            <h5
              className="heading_contact"
              style={{ margin: "0", borderBottom: "1px solid black" }}
            >
              Dean Student Welfare Office:
            </h5>
            <div className="basic_contact">
              <span style={{ display: "flex" }}>
                <CallIcon />
                <span>Contact Nos.: +91-8953789305, +91-9839557481</span>
              </span>
              <span style={{ display: "flex" }}>
                <EmailIcon />
                <span>Email: deansw@recsonbhadra.ac.in</span>
              </span>
            </div>
            <h5
              className="heading_contact"
              style={{ margin: "0", borderBottom: "1px solid black" }}
            >
              Registrar Office:
            </h5>
            <div className="basic_contact">
              <span style={{ display: "flex" }}>
                <CallIcon />
                <span>Contact Nos.: +91-9415539025</span>
              </span>
              <span style={{ display: "flex" }}>
                <EmailIcon />
                <span>Email: registrar@recsonbhadra.ac.in</span>
              </span>
            </div>
            <h5
              className="heading_contact"
              style={{ margin: "0", borderBottom: "1px solid black" }}
            >
              Director Office:
            </h5>
            <div className="basic_contact">
              <span style={{ display: "flex" }}>
                <CallIcon />
                <span>
                  Contact Nos.: 05444-252003, +91-7618907441, +91-7839454220
                </span>
              </span>
              <span style={{ display: "flex" }}>
                <EmailIcon />
                <span>Email: director@recsonbhadra.ac.in</span>
              </span>
            </div>
          </div>

          <div className="contact_us_input_container">
            <h1 style={{ fontFamily: "'Klee One', cursive" }}>
              Write To Us <NotesIcon />
            </h1>
            <form style={{ display: "flex", flexDirection: "column" }}>
              <label style={{ display: "Flex", flexDirection: "column" }}>
                Name:
                <input className="contact_input" type="text" />
              </label>
              <label style={{ display: "Flex", flexDirection: "column" }}>
                Email:
                <input className="contact_input" type="email" />
              </label>
              <label style={{ display: "Flex", flexDirection: "column" }}>
                Message:
                <textarea className="contact_textarea" rows="7" />
              </label>
              <div>
                <button className="send_contact_btn"> Send</button>
              </div>
            </form>
            {/* <SimpleMap/> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
