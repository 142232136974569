import React from "react";
import "../../assets/stylesheets/facilities/Canteen.css";
import CanTeen from "../../images/CANTEEN.png";
import Highlight from "../Highlight";

export default function Canteen() {
  return (
    <>
      <Highlight />
      <div className="Contents">
        <div className="canteen-mess">
          <div className="c-part1">
            <img src={CanTeen} alt="" className="canteen-img img-1" />

            <img src={CanTeen} alt="" className="canteen-img img-2" />
          </div>
          <div className="c-part2">
            {/* <div className="line"></div> */}
            <div className="canteen">
              <div>
                <h3>Canteen</h3>
                <p>
                  {/* Food and Friends- both initiate with 'F' and so is the word Fun. The canteen is that place where the union for the former two results in the latter. College canteens build up an ambiance of rendezvous where you'd like to spend all day with pals keeping away all hassles of mundane living. You would like to take a bite of some handsome snacks. Tits and bits and something that your mamma will scold is stuff you really like to have within the canteens. Chips and soft drinks you start with. */}
                  The canteen at Rajkiya Engineering College Sonbhadra may be a
                  canteen cum outlet where you'll be able to find all daily
                  usable items from detergent to toothbrush, from bathing bar to
                  toothpaste. The canteen sounds so good once you hear it from
                  the surface world but the truth is way over what's expected.
                  People here share a bunch of emotions and show their hidden
                  talent. It's an area of loads and tonnes of laughter, brewing
                  emotions. Therefore, canteen is some things that bring you
                  shut. You'll shed off your depressions and frustrations once
                  you are around the college canteen because it is a land of
                  charm, rejoice, and always in motion. The drabbest may sound
                  the brightest within the canteen premises.
                </p>
              </div>
            </div>
            <div className="canteen">
              <div>
                <h3>Mess</h3>
                <p>
                  {" "}
                  Hostel mess runs under student representative elected by
                  students in a cooperative mode. The food is in accordance to a
                  strict well-balanced diet made to ensure the good health of
                  residents. Food quality is checked on a regular basis by mess
                  committee followed by the warden and the chief warden.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
