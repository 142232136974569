import React from "react";
import "../assets/stylesheets/Career.css";
import Sidemenuforabout from "../components/Sidemenuforabout.js";
import Highlight from "./Highlight";
import guest from "../pdfs/AdvertisementGuestFaculty_01March2024.pdf";
import guest2 from "../pdfs/AdvertisementGuestFaculty8Aug2024.pdf";
import direct from "../pdfs/FSREC-2024.pdf";
const Career = () => {
  return (
    <>
      <Highlight />
      <div>
        {/* <h1 style={{marginBottom:'18%', textAlign:'center'}}>Under Construction</h1> */}

        <div className="career_container">
          <Sidemenuforabout />

          <div className="career_part">
            <h2>Career Openings @ REC Sonbhadra</h2>
            <hr />
            {/* <ul className="career_list">
              <li style={{ listStyleType: "circle" }}>
                <a
                  style={{ textDecoration: "underline" }}
                  href="https://erp.aktu.ac.in/WebPages/Public/AKTURecruitments/frmInstructions.aspx?enc=33"
                >
                  Advertisement for recruitment of Regular Faculty
                </a>
              </li>
              <li style={{ listStyleType: "circle" }}>
                <a
                  style={{ textDecoration: "underline" }}
                  href="http://recsonbhadra.ac.in/News_content/33403news_08162021.pdf"
                >
                  Advertisement for Guest Faculty (Advertisement No.
                  43/Advt./REC/SONBH/2021, Dated 13/08/2021, Last date to apply
                  - 26/08/2021)
                </a>
              </li>
            </ul> */}
            <ul className="career_list">
              <li style={{ listStyleType: "circle" }}>
                <a style={{ textDecoration: "underline" }} href={direct}>
                  Direct Recruitment for Regular Faculty Positions (AKTU,
                  Lucknow){" "}
                </a>
              </li>
              <li style={{ listStyleType: "circle" }}>
                <a style={{ textDecoration: "underline" }} href={guest}>
                  Advertisement for Guest Faculty in Academic Session 2023-24
                  (Dated 01 March 2024)
                </a>
              </li>
              <li style={{ listStyleType: "circle" }}>
                <a style={{ textDecoration: "underline" }} href={guest2} target="_blank">
                  Advertisement for Recruitment of Guest Faculty in Academic Session 2024-25 (Advt. No. 49/Advt./REC/SONBH/2024, dated 08.08.2024)
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Career;
