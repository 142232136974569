import React from "react";
import "../../../assets/stylesheets/Thecollege/BoardOfGoverner/BOG.css";
import "../../../assets/stylesheets/Career.css";
import Sidemenuforabout from "../../../components/Sidemenuforabout.js";
import Highlight from "../../Highlight";
import BOGTable from "./BOGTable";

const TableData = {
  Member: [
    "Director of the College",
    "Superintending Engineer, PWD of the circle in which the college is located",
    "Head of Civil Engineering Dept. of the College",
    "An Electrical Engineer and one architect to be nominated by Chairman, Board of Governors",
    "Registrar of the College",
    "Finance &amp; Accounts Officer of the College",
    "Assistant Engineer / Executive Engineer of the College",
  ],
  Role: [
    "Chairman",
    "Member",
    "Member",
    "Member",
    "Member",
    "Member",
    "Member-Secretary",
  ],
};

const BuildingAndWorksCommittee = () => {
  return (
    <>
      <Highlight />
      <div>
        <div className="career_container blow563pxToResp resprespresp601to700px resPonsive-at-610-699px">
          <Sidemenuforabout />
          <div className="mginTop_bogs mgintop_bogs2 againmarginTop1234">
            <h2>Building and Works Committee</h2>
            <hr />
            <div>
              <p>
                The Building and Works Committee of the college consists of the
                following members:
              </p>
              <div
                style={{ overflow: "auto" }}
                className="AdMiNiStRaTiOn_TaBlE"
              >
                <BOGTable header1="Governing Body Member" header2="Position/Role" data={TableData} size={TableData.Member.length} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BuildingAndWorksCommittee;
