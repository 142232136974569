import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import NavBar from "./NavBar";

import "../../assets/stylesheets/Facultylogin.css";

import AuthService from "../../services/auth-service";

import CircularProgress from "@mui/material/CircularProgress";

import { NotificationManager } from "react-notifications";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);

    this.state = {
      username: "",
      password: "",
      loading: false,
      message: "",
    };

    var currentUser = AuthService.getCurrentUser();
    if (!currentUser || currentUser.roles[0] !== "ROLE_ADMIN") {
      alert("You must be logged in as Admin!");
      props.history.push("/facultylogin");
    }
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value,
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  handleLogin(e) {
    e.preventDefault();

    this.setState({
      message: "",
      loading: true,
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      AuthService.changeAdmin(this.state.username, this.state.password).then(
        (data) => {
          console.log(data);
          this.setState({
            loading: false,
            message: "Success",
          });
          AuthService.logout();
          this.props.history.push("/facultylogin");
          NotificationManager.success(this.state.message);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            loading: false,
            message: resMessage,
          });
          NotificationManager.error(this.state.message);
        }
      );
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  render() {
    return (
      <>
        <div className="dashboardGrid">
          <NavBar />
          <div className="contentBox">
            <Form
              className="loginContent"
              onSubmit={this.handleLogin}
              ref={(c) => {
                this.form = c;
              }}
            >
              <div className="loginHeading">Change Admin</div>
              <div className="center">
                Enter the E-mail Id to which you want your admin privalages to
                be given.
              </div>
              <div className="center">
                Enter your password for confirmation.
              </div>
              <div className="center">
                <Input
                  type="text"
                  className="loginFields"
                  name="username"
                  placeholder="E-mail"
                  value={this.state.username}
                  onChange={this.onChangeUsername}
                  validations={[required]}
                />

                <Input
                  type="password"
                  className="loginFields"
                  name="password"
                  placeholder="Password"
                  value={this.state.password}
                  onChange={this.onChangePassword}
                  validations={[required]}
                />
              </div>
              <button
                className="loginButton loginFields"
                disabled={this.state.loading}
              >
                <span>
                  {this.state.loading ? <CircularProgress /> : "Change Admin"}
                </span>
              </button>

              {this.state.message && (
                <div className="form-group">
                  <div className="alert alert-danger" role="alert">
                    {this.state.message}
                  </div>
                </div>
              )}
              <CheckButton
                style={{ display: "none" }}
                ref={(c) => {
                  this.checkBtn = c;
                }}
              />
            </Form>
          </div>
        </div>
      </>
    );
  }
}
