import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import {
  Card,
  CardContent,
  Typography,
  CardMedia,
  CardActions,
  Button,
} from "@material-ui/core";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";
import SchoolRoundedIcon from "@mui/icons-material/SchoolRounded";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import FindInPageOutlinedIcon from "@mui/icons-material/FindInPageOutlined";

const PeopleCard = ({ data }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Update the windowWidth state when the window is resized
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Card
      style={{
        display: "flex",
        marginBottom: "10px",
        width:
          windowWidth > 500
            ? windowWidth > 1300
              ? "450px"
              : "520px"
            : "280px",
        flexDirection: windowWidth > 500 ? "row" : "column",
        minHeight: "220px",
        padding: "0.5rem 1rem",
        backgroundColor: "rgba(0,0,0,0)",
        boxShadow: "0px 2px 10px rgba(0,0,0,0.5)",
        borderRadius: "10px",
      }}
    >
      {/* For smaller screens, the image will be stacked on top */}
      <CardMedia
        component="img"
        style={{
          width: 150,
          height: 200,
          alignSelf: "center",
          borderRadius: "10px",
          marginBottom: "1rem",
        }}
        image={data.profilePhoto ? data.profilePhoto.file_url : null}
        alt="Profile Photo"
      />

      <CardContent
        style={{ flex: "1 0 auto", display: "flex", flexDirection: "column" }}
      >
        <Typography
          variant="h5"
          style={{
            fontWeight: "bold",
            marginBottom: "2px",
            width: 270,
            textAlign: "left",
          }}
        >
          {`${data.firstName} ${data.middleName} ${data.lastName}`}
        </Typography>

        <Typography
          variant="subtitle1"
          color="text.secondary"
          style={{
            color: "#5a5a5a",
            fontWeight: "bold",
            marginTop: "-5px",
            marginBottom: "5px",
          }}
        >
          {data.post}
        </Typography>

        <Typography variant="body1" color="text.secondary">
          <div style={{ display: "flex", alignItems: "center" }}>
            <SchoolRoundedIcon
              style={{ color: "#1976d2", marginRight: "4px" }}
            />
            {data.qualifications}
          </div>
        </Typography>

        <Typography variant="body1" color="text.secondary">
          <div style={{ display: "flex", alignItems: "center" }}>
            <MenuBookOutlinedIcon
              style={{ color: "#388e3c", marginRight: "4px" }}
            />
            {data.areaOfInterest}
          </div>
        </Typography>

        <Typography variant="body1" color="text.secondary">
          <div style={{ display: "flex", alignItems: "center" }}>
            <LocalPhoneRoundedIcon
              style={{ color: "#f57c00", marginRight: "4px" }}
            />
            +91{data.phoneNumber}
          </div>
        </Typography>

        <Typography
          variant="body1"
          color="text.secondary"
          style={{ wordBreak: "break-all" }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <MailOutlineIcon style={{ color: "#d32f2f", marginRight: "4px" }} />
            <a href={`mailto:${data.email}`} style={{ color: "#000000" }}>
              {data.email}
            </a>
          </div>
        </Typography>
        <CardActions>
          <Button
            size="small"
            backgroundColor=""
            variant="h6"
            style={{ alignSelf: "flex-start" }}
          >
            <FindInPageOutlinedIcon />
            <Link
              style={{ color: "black" }}
              to={`/departments/${window.location.pathname.split("/")[2]}/${
                data.phoneNumber
              }`}
            >
              Know more
            </Link>
          </Button>
        </CardActions>
      </CardContent>
    </Card>
  );
};

export default PeopleCard;
