import React from "react";
import { Link } from "react-router-dom";
import "../../../assets/stylesheets/Career.css";
import "../../../assets/stylesheets/Thecollege/BoardOfGoverner/BOG.css";
import Sidemenuforabout from "../../../components/Sidemenuforabout.js";
import Highlight from "../../Highlight";

const CollegeByLaws = () => {
  return (
    <>
      <Highlight />
      <div>
        {/* <h1 style={{marginBottom:'18%', textAlign:'center'}}>Under Construction</h1> */}

        <div className="career_container">
          <Sidemenuforabout />

          <div className="career_part">
            <h2>College By-Laws</h2>
            <hr />
            <div style={{ marginTop: "5%" }}>
              The website is new and content will be coming soon ..
              <br></br>
              <span style={{ fontSize: "1.9em" }}>Y</span>ou Can surf other
              pages like{" "}
              <Link
                to="/"
                style={{
                  color: "blue",
                  fontWeight: "bold",
                  backgroundColor: "white",
                }}
              >
                Home
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CollegeByLaws;
