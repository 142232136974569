import CellsBaseLayout from "../../layouts/CellsBaseLayout";
import Highlight from "../Highlight";

const StudentCounsellingCell = () => {
  const studentGuidanceandCareerCounsellingMembers = [
    // {
    //   srno: "1.",
    //   name: "Dr. Udaya Pratap Singh",
    //   position: "Chairman",
    //   contact: "9919171900",
    // },
    // {
    //   srno: "2.",
    //   name: "Dr. Dharmendra Dixit",
    //   position: "Member",
    //   contact: "9588913744",
    // },
    {
      srno: "1.",
      name: "Mr. Umesh Kumar Gupta",
      position: "Member",
      contact: "8081985480",
    },
    {
      srno: "2.",
      name: "Mr. Prashant Pandey",
      position: "Member",
      contact: "9532986987",
    },
  ];
  const data = [
    {
      tag: "main-heading",
      heading: "Student Counselling and Career Guidance Cell",
      index: 1,
    },
    {
      tag: "content",
      content:
        "In order to resolve day to day academic problems of the students, mentors are appointed for a batch of 20 students, and they will counsel the respective students once in a week, to solve the problems come across during their course of study. This is a continuous process till the end of academic career of the student. During the last semester of study students are advised for higher studies along with proper career guidance. Reasonable numbers of students have secured admissions for their higher studies and they in turn guide their juniors for their prospective admissions.",
      index: 2,
    },
    {
      tag: "sub-heading",
      heading: "Goal",
      index: 3,
    },
    {
      tag: "content",
      content: "The objectives of the practice followed by the college are:",
      index: 4,
    },
    {
      tag: "content",
      content: {
        tag: "unordered-list",
        list: [
          "To monitor the students regularity & discipline.",
          "To enable the parents to know about the performance & regularity of their wards.",
          "Improvement of teacher-student relationship.",
          "Cases of indiscipline or indecent behaviour of any student, cases of individual/group harassment, threats, manhandling etc. are dealt strictly by the Proctorial Board.",
          "Counselling students for solving their problems and provide confidence to improve their quality of life.",
          "Guiding students to choose right career path for job, higher studies, Entrepreneurship, etc.",
        ],
      },
      index: 5,
    },
    {
      tag: "sub-heading",
      heading: "The Context",
      index: 6,
    },
    {
      tag: "content",
      content:
        "The following are the issues which motivated the college to implement the mentoring system:",
      index: 7,
    },
    {
      tag: "content",
      content: {
        tag: "unordered-list",
        list: [
          "Inculcating discipline, punctuality and motivation among the students is the main objective in career building of a student. The college has adopted a well established system, Counselling and Mentoring Record (CMR) for monitoring & mentoring the students’ activities.",
          "The scheme aims to address conflicts in attitudes, habits, and knowledge of the students towards learning practices.",
        ],
      },
      index: 8,
    },
    {
      tag: "sub-heading",
      heading: "The Practice",
      index: 9,
    },
    {
      tag: "content",
      content: {
        tag: "unordered-list",
        list: [
          "Teachers take students' attendance in every class during the first five minutes and after the class enters the list of absentees in the Academic Activity Register (AAR) and absentees' information will be posted to Head of the department as well as to the parents every day.",
          "If a student is absent for more than ten days continuously then HOD calls the parents of a student, enquires the reason and advises them to take care of their ward.",
          "Even after informing student’s parents, HOD forwards the details of a student to the Principal for further action.",
          "Every section has two mentors and each mentor will get 30 students and they maintain CMR.",
          "The mentors prepare the monthly attendance of every student for each section and send the information to the parents of defaulters through proper channel.",
          "The mentors meet the students associated with them once in a week. A separate mentoring and counselling hour is maintained for each class as part of their time table and the respective faculty meets the students in the said hour.",
          "The parents/guardians of poor attendee/performance students are called to meet the mentors and corrective and preventive measures are implemented for further improvement.",
          "Each mentor maintains the entire student Information, which is examined by the HOD and others concerned when necessary.",
          "Periodic meetings are conducted by the Principal with HODs to review the punctuality and regularity of the students.",
          "The mentor meetings are conducted every week. As the students of II year to IV year (6 members from each class) are allotted with a mentor. Senior students will interact with junior students sharing the experiences in co-curricular and exposure due to project and seminars are shared among them. The fresh ideas will float in during the meeting.",
          "The class interaction committee meetings are being conducted twice in a semester for every class to know and to solve their problems.",
          "Every student participates in the events conducted in the college or other colleges with the prior permission of the concerned HOD.",
          "Disciplinary Committee of the college promptly curbs the indiscipline in the campus.",
          "Anti-Ragging Committee monitors the freshers by frequently visiting the sensitive areas within the campus and outside the campus.",
          "Additional duty is assigned to the faculty members to monitor the fresher’s from being affected by any sort of ragging in and around the campus.",
        ],
      },
      index: 10,
    },
    {
      tag: "sub-heading",
      heading: "Outcomes of the System",
      index: 11,
    },
    {
      tag: "content",
      content: {
        tag: "unordered-list",
        list: [
          "The attendance percentage of the students has increased to a greater extent.",
          "The number of detainment of students has decreased consistently.",
          "In course of direct communication between mentor and the student there was a good improvement in the teacher-student relationship.",
          "The above effected in attaining a better academic performance.",
        ],
      },
      index: 12,
    },
    {
      tag: "sub-heading",
      heading: "Career Guidance",
      index: 13,
    },
    {
      tag: "content",
      content:
        "Career Guidance Cell functions in our institution with the objectives to provide learning/training opportunities in the areas of academic, career and personal/ Social development and to preparing students to meet future challenges. To support the students in identifying their interests, strengths and planning appropriately to justify their professional needs.",
      index: 14,
    },
    {
      tag: "content",
      content: "Following are the objectives of the career guidance cell:",
      index: 15,
    },
    {
      tag: "content",
      content: {
        tag: "unordered-list",
        list: [
          "The Career Guidance Cell provides services in areas of Campus Interviews; Job Placements and training programmes for our students that enable them to develop applicable skills in the competitive job market.",
          "The Cell organizes Workshops and Seminars on Personality Development, Interpersonal Relationship, Communication Skills, Interview Skills and Presentation Skills to enable the All Round development of individuals.",
          "The Cell organizes the programmes to create awareness about the importance of higher studies in India and Abroad.",
          "The Cell organizes diagnostic tests for the competitive exams such as GTAE,CAT,CMAT,GRE,GMAT and to counsel them for higher studies.",
          "Counselling students for solving their problems and provide confidence to improve their quality of life.",
          "The Cell conducts Orientation Programmes for freshers.",
        ],
      },
      index: 16,
    },
    {
      tag: "sub-heading",
      heading: "Student Counselling and Career Guidance Cell Members",
      index: 17,
    },
    {
      tag: "content",
      content:
        "The Student Counselling and Career Guidance Cell at REC Sonbhadra presently comprises of the following members -",
      index: 18,
    },
    {
      tag: "content",
      content: (
        <>
          <table className="trainingPlacementTable" style={{ minWidth: "70%" }}>
            <tr>
              <td className="studentGrievanceTableHeader">
                <b>Sr.No.</b>
              </td>
              <td className="studentGrievanceTableHeader">
                <b>Name</b>
              </td>
              <td className="studentGrievanceTableHeader">
                <b>Position</b>
              </td>
              <td className="studentGrievanceTableHeader">
                <b>Contact No.</b>
              </td>
            </tr>
            {studentGuidanceandCareerCounsellingMembers.map((row, index) => (
              <tr key={index}>
                <td>{row.srno}</td>
                <td>{row.name}</td>
                <td>{row.position}</td>
                <td>{row.contact}</td>
              </tr>
            ))}
          </table>
        </>
      ),
      index: 19,
    },
  ];
  return (
    <>
      <Highlight />
      <CellsBaseLayout data={data} />
    </>
  );
};
export default StudentCounsellingCell;
