import { headingContainer, contentContainer, unorderedList } from "../elements";

export default function AchievementsLayout({ data }) {
  const { studentList, facultyList } = data;
  return (
    <>
      {headingContainer({ heading: "Achievements", margin: true })}
      {contentContainer({
        content:
          studentList || facultyList ? (
            <>
              <div className='hodInfoHeading'>Students</div>
              {unorderedList(studentList)}
              <div className='hodInfoHeading'>Faculty</div>
              {unorderedList(facultyList)}
            </>
          ) : (
            <>Content Available Soon!</>
          ),
      })}
    </>
  );
}
