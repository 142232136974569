const downloadTab = ({ title, link }) => (
  <div className='downloadsElement' key={`${title}-key-element`}>
    <div className='downloadsElementTitle'>{title}</div>
    <div className='downloadsElementGet'>
      <a
        href={link}
        style={{ textAlign: "center" }}
        rel='noreferrer'
        target='_blank'
        download
      >
        Download
      </a>
    </div>
  </div>
);

export default downloadTab;
