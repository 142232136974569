import React from "react";
// import FiberNewIcon from '@material-ui/icons/FiberNew';
import "../../assets/stylesheets/Thecollege/Vision.css";
import Sidemenuforabout from "../Sidemenuforabout";
import Highlight from "../Highlight";

const Vision = () => {
  return (
    <>
      <Highlight />

      <div className="aboutus_container">
        <Sidemenuforabout />

        <div>
          <h1>Our Vision</h1>
          <hr />
          {/* <img  className="back_img_vision "src={back} alt="sry" /> */}

          <p>
            To be an esteemed educational institute in the country, and thereby
            establish unique identity for the development of high quality
            knowledge and human resources in diverse area of the technology.
          </p>
          <h1>Our Mission</h1>
          <hr />
          {/* <img  className="back_img_vision "src={back} alt="sry" /> */}

          <ul>
            <li style={{ listStyleType: "circle" }}>
              To take leadership role by providing advanced knowledge in applied
              sciences, humanities, engineering and technology.
            </li>
            <li style={{ listStyleType: "circle" }}>
              To serve the society by providing superior knowledge, developing
              innovation skills and functioning as an organized high learning
              centre.
            </li>

            <li style={{ listStyleType: "circle" }}>
              To nurture excellence in teaching, learning, creativity and
              translate the knowledge into practice for the welfare of the
              society.
            </li>
            <li style={{ listStyleType: "circle" }}>
              To establish ethical values, creating networks with other leading
              institutes of high learning and research in order to cater
              significant contribution for global development.
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Vision;
